<template>
  <div class="forgot-password-page">
    <b-overlay :show="isLoading" rounded="sm" class="content">
      <div class="sub-page-header" style="
                position: sticky;
                top: 99px;
                background: #6c757e;
                z-index: 99999999;
                padding-top: 15px;
                padding-bottom: 16px;
            ">

        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="top-header">
                <h4 class="text-white">Forgot My Password</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content">
        <div class="container">
        <div class="row">
          <div class="col-md-12">
            <b-alert variant="danger" show v-if="errorMessage">{{errorMessage}}</b-alert>
            <b-alert variant="success" show v-if="successMessage">{{successMessage}}</b-alert>
            <form id="login-form">
              <b-form-group
                  id="username-input-group"
                  label="Email Address"
                  label-for="emailAddress"
                  description=""
              >
                <b-form-input
                    :class="{
                              'is-invalid': $v.userEmail.$dirty && $v.userEmail.$error,
                              'is-valid': $v.userEmail.$dirty && !$v.userEmail.$error
                          }"
                    id="emailAddress"
                    v-model.trim="$v.userEmail.$model"
                    type="text"
                    placeholder=""
                    required
                ></b-form-input>
              </b-form-group>

              <b-row class="mb-3">
                <b-col class="pb-2">
                  <b-button class="btn-block" @click="resetPassword()" :disabled="$v.userEmail.$invalid">
                    Reset My Password
                  </b-button>
                </b-col>
              </b-row>

              <div>
                <router-link :to="{name: 'RegisterLogin'}">Login in my account</router-link>
              </div>
            </form>

          </div>
        </div>
      </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {email, minLength, required} from "vuelidate/lib/validators";
import buyissolutionsApiService from "@/services/buyissolutions.api.service";

export default {
  name: "ForgotPasswordPage",
  created () {
    this.$mixpanel.track_pageview({"page": "Forgot Password Page"});
  },
  data() {
    return {
      isLoading: false,
      errorMessage: null,
      successMessage: null,
      userEmail: null
    }
  },
  methods: {
    resetPassword() {
      if (!this.userEmail) {
        return;
      }

      this.isLoading = true;
      this.errorMessage = null;
      this.successMessage = null;
      buyissolutionsApiService.postResetPassword(this.userEmail).then((result) => {
        this.$mixpanel.track('Reset Password Init', {
          user_email: this.userEmail,
        });


        if (result.status === 200) {
          this.successMessage = result.data.message;
        }
      }).catch(error => {
        console.log(error)
        this.errorMessage = error.response.data.message;
      }).finally(() => {
        this.isLoading = false;
      });

    }
  },
  validations: {
    userEmail: {
      required,
      email,
      minLength: minLength(3)
    },
  }
}
</script>

<style scoped>

</style>