import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedUser: null,
    intervalID: null,
    cart: {
      products: []
    },
    signup: {
      stepper: 1,
      type: 'new_user',
    },
    loginCred: {},
    isSignUpModalOpen: false,
    selectedProduct: null,

    mobileSignUpData: {
      msisdn: null,
      product: null
    },
    selectProduct: null,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
