<template>
    <section class="sub-page-header">
        <div class="container">
            <div class="row">
                <div class="col-12 stats-heading-area text-center">
                    <h4 class="heading">{{ title }}</h4>
                    <span class="sub-heading">{{subtitle}}</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "SubPageHeader",
    props: ['title', 'subtitle']
}
</script>

<style scoped lang="scss">
@import "@/styles/partials/variables";

.sub-page-header {
    padding: 4rem 0;
    background: $color-yellow;
}
</style>