<template>
    <div class="buyisa-solutions-privacy">
        <SubPageHeader title="Buyisa Solutions Privacy Policy"/>
        <b-overlay :show="isLoading" rounded="sm" class="content">
            <section class="section">
                <div class="container">
                    <div class="row">

                        <div class="col-sm-12">
                            <h3><strong>OBJECTIVE OF THIS PRIVACY POLICY</strong></h3>
                            <p>Buyisa Solutions offers Internet services to the public. The data services that are provided are defined on the Buyisa Solutions website, in the Buyisa Solutions standard terms/conditions and associated product pages. The service is accessed by the use of routers, personal computers, tablets, mobile phones, smart TV’s, gaming consoles and other devices (IoT). To become a Buyisa Solutions user (data subject) you will be required to provide certain personal information when creating an account on the Buyisa Solutions customer portal (CP) and then further using the data services or otherwise transacting with Buyisa Solutions.</p>
                            <p>We appreciate that users are concerned about their personal privacy and we are committed to respect and to protect your personal privacy concerns and rights. By agreeing to this privacy policy, the Buyisa Solutions terms &amp; conditions, creating an account on the Buyisa Solutions customer portal (CP) and further using the Buyisa Solutions service you consent and agree that we may collect, receive, record, organise, collate, store, update, change retrieve, read process, use and share your personal information in accordance with this policy.</p>
                            <p><strong>Should you not agree with this privacy policy please do not continue to use any of Buyisa Solutions platforms and services</strong>. This policy relates to how we collect, use, process and store your personal information when you use/access Buyisa Solutions services, websites, platforms, applications and products. The objective of this privacy policy is to ensure that the processing of your personal information will be lawful, reasonable and is legitimate for our business activities.</p>

                            <h3><strong>APPLICATION OF THIS PRIVACY POLICY</strong></h3>

                            <p>This policy only applies to Buyisa Solutions services, our website and platform and not to any third-party sites and technology you may use to access our products and services.</p>

                            <h3><strong>HOW AND WHEN WE COLLECT PERSONAL INFORMATION</strong></h3>

                            <p>Information provided by you:</p>
                            <p>We collect information about you as a user as provided by you to us when you submit your personal information to us in any format, when you send e-mail communications to us,&nbsp; when you interact with our customer service portals, participate in surveys and promotion. Use our services and related account settings. Such information may include your name, surname, age, SA ID/ Passport number, date of birth, physical address/location, postal address, e-mail address, telephone numbers, social media account details, profile pictures, payment methods, loyalty points and vouchers. When Buyisa Solutions user engage with user forums on social media or engage with support agents via email to the buyisasolutions.com domain, Buyisa Solutions will store the full text of any conversations or support tickets to which you contribute. This text may at times contain additional personal information that you provide.</p>
                            <p>Information automatically collected by us:</p>
                            <p>We use technology to collect and maintain information about you and further manage your user experience when using any of the Buyisa Solutions data services. This information relates to your computer, router and/or any other device used to access the Buyisa Solutions service or when you browse any of the Buyisa Solutions platforms, when you register, purchase or subscribe to use Buyisa Solutions services on/via our website. When you log into a router and insert user credentials or a data SIM card with the intention to connect to the Buyisa Solutions network.</p>
                            <p>We may collect information about your devices e.g. the nature of the access device used to access the Buyisa Solutions platform, the unique device identifier and IP address used, hardware/device type, model and settings/configuration, operating system type and version, browser type and version, browser language, system activity, referring sources/URL’s, exit pages navigated to when exiting the Buyisa Solutions platform and the physical location from which the Buyisa Solutions platform is accessed, established by means of technology such as GPS or IP location. We may also collect log-in information such as your activity and interaction with the platform (dates, times and duration of site access and site use), the history of your search enquiries and page views and program selection and download, your interaction with our messaging systems (e-mail, SMS and otherwise) and our Customer Service (such as date, time, reason for contacting us, recording and transcripts of conversations and chats and your phone number).</p>
                            <p>Technology such as cookies, identifiers, web beacons, browser storage and plugins may be used to collect information and recognise you when you access and exit Buyisa Solutions platforms to distinguish you from other users, to build a profile of your use and contribute to a personalised and efficient service.</p>
                            <p>Information obtained from third parties:</p>
                            <p>We also collect personal information from our affiliates and third-party service providers which are used to supplement your user profile on our platforms. These include service providers providing us with updates on your IP address, payment service providers providing us with updates on your payment method information, selected partners who make our services available on their device or with whom we offer co-branded service/joint marketing activities, online and offline data providers of demographic advertising related data and publicly available sources such as government databases.</p>
                            <p>Information obtained from social networking sites:</p>
                            <p>We may allow you to log onto the Buyisa Solutions platforms by using social media account authentication. We may access and collect personal information from your social media account when and while you log on to your social media account from any Buyisa Solutions platform using your username and password. We only receive information from social media accounts and do not give them access to your personal information. We will only process personal information available on your social media account to the extent that you have decided to share the information publicly (in line with your privacy setting on the relevant social media account), and other information obtainable from the social media account only if and to the extent that you may give permission to do so.</p>
                            <h3><strong>COMPULSORY VS OPTIONAL PERSONAL INFORMATION</strong></h3>
                            <p>Compulsory information required for access to Buyisa Solutions platform and data services:</p>
                            <p>Your full name and surname, identity number/document and date of birth,</p>
                            <ul>
                                <li>your e-mail address,</li>
                                <li>your physical address/location,</li>
                                <li>your postal address,</li>
                                <li>your home, work and mobile telephone numbers,</li>
                                <li>payment method,</li>
                            </ul>
                            <p>If you are not prepared to share the compulsory information with us, Buyisa Solutions can regretfully not offer you any of our data services. All other information that may be requested will be optional but may be relevant if you wish to exploit the full potential and benefit of the Buyisa Solutions services. If you are not prepared to share the optional information with us, you may not be able to enjoy all the features and full functionality of Buyisa Solutions platforms.</p>
                            <h3><strong>OUR USE OF INFORMATION</strong></h3>
                            <p>We collect personal information for various purposes:</p>
                            <ul>
                                <li>Personal information is collected to comply with legal requirements such as FICA and RICA legislation and is used to protect us, our services and our users.</li>
                                <li>Certain compulsory information is required for registration and account set-up as user to enjoy access to Buyisa Solutions platforms, to process your orders and payment for our services.</li>
                                <li>Personal information is used to authenticate your identity when accessing Buyisa Solutions and to ensure safe and authorised use of our services, to detect, investigate and prevent misuse, fraud and prohibited/illegal activities.</li>
                                <li>Personal information is collected to maintain and update our customer database and to secure accurate, complete and up to date data.</li>
                                <li>Personal information is used to provide customer support technical and other user enquiries.</li>
                                <li>Personal information is collected to enable us to communicate news and promotional announcements.</li>
                                <li>Personal information is used to conduct consumer surveys and research.</li>
                                <li>Personal information is used to enable us to analyse and understand our user base, to determine your geographic location so as to enable us to confirm coverage/availability of network coverage, maintain and develop our service and personalise and localise our services to you and ultimately secure a constantly improving consumer experience of our services.</li><li>Personal information is used to compile, use, disclose and trade with non-personal statistical information about users and their access and use of Buyisa Solutions platform, browsing habits, click- patterns, preferences and demographics which we may use to develop, provide and improve the Buyisa Solutions service, including advertising to user groups. Information anticipated in this paragraph will be de-identified and will not be traceable back to you. Any identifiable information will not be disclosed without your express permission.</li><li>Personal information is generally used for other lawful activities relevant to and reasonably necessary for the conduct of our business and provision of Buyisa Solutions services.</li>
                            </ul>
                            <h3><strong>CHILDREN</strong></h3>
                            <p>Protecting the privacy of children is especially very important. We will not knowingly collect or maintain any information from persons under the legal age of majority without permission to do so. You must be 18 years or older to legally subscribe to Buyisa Solutions service.</p>
                            <p>Any person under the legal age of majority may only access and utilise Buyisa Solutions services with the involvement, supervision and approval of a parent or legal guardian.</p>
                            <h3><strong>SHARING/ DISCLOSING INFORMATION</strong></h3>
                            <p>We will not intentionally disclose your personal information other than for purposes and in accordance with this policy or with your permission.</p>
                            <p>In the conduct of our business, we will share your personal information:</p>
                            <ul>
                                <li>to the extent required to comply with any applicable law, regulation, legal process, court order or legal governmental request,</li>
                                <li>to enforce compliance with our terms of service,</li>
                                <li>to detect, investigate, prevent and otherwise address suspected illegal activities, security/technical issues and contravention of this policy and/or the general Buyisa Solutions terms and conditions of service,</li>
                                <li>to protect and defend the rights, property and safety of Buyisa Solutions, our users, our employees, service providers, suppliers, agents, any other third party and the interests of the general public as required or permitted by law.</li>
                            </ul>
                            <p>We engage other companies, agents, other service providers, suppliers and our employees to render, personalise and optimise our services (e.g. website hosting, site and product maintenance and development, administration, technical and IT support, processing and collecting payments, marketing and advertising, analysing and enhancing data, conducting consumer surveys and other support services.) These third parties may have access to your personal information if and to the extent necessary to process the information and/or for us to provide services to you. They will not be allowed to use or disclose your personal information other than for providing of their services.</p>
                            <p>From time to time, we may engage in joint promotions with third parties. We may be required to share your personal information with such third parties to facilitate your participation in and exploitation of the promotions. We wish to point out that these third parties are responsible for their own privacy policies and practices. Information compiled and profiled for statistical purposes will be freely traded in such a manner that the profiles and data cannot be traced back or linked to a specific user or subscriber.</p>
                            <p>In the event of a business transfer whereby a third-party will acquire ownership or control of our assets, management, shares, platforms or creates an affiliation between ourselves and such third-party, whether by virtue of reorganisation, restructuring, merger, acquisition, sale or otherwise, your personal information will be shared and/or transferred as part of the business arrangement on condition that the receiving party agrees to uphold your privacy rights in a manner consistent with this privacy policy. Confidentiality, non-disclosure and/or penalty provisions in our contractual relationships with these thirds parties will serve as deterrents against infringement of your privacy rights contrary to the scope of this policy.</p>

                            <h3><strong>WARNINGS</strong></h3>
                            <p>Your own publications:</p>
                            <p>Please be aware that whenever you use Buyisa Solutions services and elects to post reviews publicly, third parties will have access to the information you disclose yourself.</p>
                            <p>Third party sites and technology:</p>
                            <p>This policy applies to the website, platform, service, product and applications provided by Buyisa Solutions and its successors in title to users who use Buyisa Solutions services. Buyisa Solutions is not responsible and has no control over the privacy practices of any third-party site to which there may be a link on Buyisa Solutions platforms through which you may navigate to while using the Buyisa Solutions service. Please acquaint yourself with the content of such sites and adjust your privacy settings according to your preferences. If you decide to share personal information from Buyisa Solutions platforms/services (eg. by e-mail, text or other social media) using any device and social technology or applications thereon, please be aware that the social applications are operated by independent social media networks and that sharing of information via such networks will be subject to and governed by the terms of use and privacy policy of the relevant social media operator.</p>
                            <p>Third-party access:</p>
                            <p>You can remove your Buyisa Solutions account from your devices by signing out on the Buyisa Solutions customer portal/website or by deleting the settings for our platforms on your device (steps vary by device and the option may not be available on all devices). It is recommended that, when you dispose of a device set up to make use of the Buyisa Solutions service, that you maintain security of your password and device, that you logout and deactivate the device to ensure that others who subsequently use the device may not access your account and personal information. Users of public and shared devices are advised to logoff after each visit.</p>

                            <h3><strong>STORING AND RETENTION OF PERSONAL INFORMATION</strong></h3>
                            <p>Your personal information is stored on our servers or secure clouds. We reserve the right to transfer to and store your personal information on servers in a jurisdiction other than where it was collected. Where your personal information is required to be transferred across borders to other countries, we will do so in accordance with this privacy policy, will take into account any more stringent provisions of privacy protection laws applicable in such other jurisdictions and will take reasonably adequate steps to ensure that your personal information is protected in the other country. Your personal information will be retained by us to the extent and for so long as you continue to access any of our platforms and do not request us to destroy retained information; and thereafter for so long as:</p>
                            <ul>
                                <li>We are required by law or code of conduct to do so</li>
                                <li>We are bound by contract to do so</li>
                                <li>We reasonably need it for any lawful purposes related to our business activities</li>
                                <li>We reasonably need it for evidentiary purposes</li>
                            </ul>
                            <p>We reserve the right to delete, destroy or de-identify personal information that may become obsolete in our discretion for any reason including by virtue you stopping to access our services and your account or activity profile becoming and remaining dormant for a prolonged period of time.</p>

                            <h3><strong>UPDATING YOUR PERSONAL INFORMATION</strong></h3>

                            <p>It is in our mutual interest that your personal information is complete, accurate and up to date. It is also required by law that we update your personal information on our databases from time to time. You are kindly requested to regularly verify the accuracy of your personal information and advise us of any changes or discrepancies by either contacting our contact desk and/or by amending your personal profile online on our customer portal to the extent allowed for in the functionality of our web design. We reserve the right to verify the accuracy of your personal information and to restrict the processing of contested or suspect information for a reasonable period while we verify the accuracy.</p>

                            <h3><strong>DIRECT MARKETING TO YOU</strong></h3>
                            <p>When you access Buyisa Solutions platforms and register as user you will receive marketing communications from us. You may opt-out of receiving direct marketing communications from us at any time by notifying us accordingly. Please be aware that Section 13 of the Buyisa Solutions terms and conditions of service already make provision for the use of direct marketing).</p>
                            <p>Cookies, identifiers, web beacons, browser storage, plugins and similar technology may be used to automatically collect information about you and your use of our services. These technologies are similar in nature, often operate on interactive basis and have a variety of purposes. Amongst others it is used to recognise and authenticate you when you access the platform/service.</p>
                            <p>Cookies have performance and functionality functions to enhance your experience of our services.</p>
                            <p>(Cookies are small data files which are transferred to your access device when you visit the Buyisa Solutions platform though your browser. It is stored in your web browser and will recognise you when you revisit the platform again. It stores a variety of information and user preferences. Advertising identifiers are like cookies and are found in many mobile devices, tablets and streaming media devices).</p>
                            <p>You may disable cookies and other similar technologies by configuring your browser to refuse same or by following the prompts on the help function, but by doing so you may not be able to enjoy all the features and full functionality of Buyisa Solutions service.</p>

                            <h3><strong>SECURITY OF YOUR PERSONAL INFORMATION</strong></h3>
                            <p>We take administrative, physical, technical and managerial precautions and we use accepted technology reasonably appropriate for managing the risks of processing your information to ensure the integrity of personal information and to safeguard information against loss, theft, alteration and unauthorised access, use and disclosure thereof. While we make the integrity of your personal information a priority, we cannot guarantee that your personal information will be 100% secure.</p>
                            <p><strong>CHANGES AND UPDATES IN THIS SECURITY POLICY</strong></p>
                            <p>Kindly note that Buyisa Solutions reserves the right to change and update this policy from time to time to accommodate changing legal regulatory and operational requirements. Notice of change or update and when it will take effect will be given in accordance with prevailing law. Further use and access of Buyisa Solutions services after notification of such changes will constitute your acceptance of the amended terms of the policy. If you do not acknowledge or accept amendments to the policy, you must cease to use the Buyisa Solutions service.</p>

                            <h3><strong>CONTACTS</strong></h3>
                            <p>Should you require any assistance or further information regarding this specific policy please contact the Buyisa Solutions privacy centre:</p>
                            <p>Email: <a href="mailto:legal@buyisasolutions.com">legal@buyisasolutions.com</a></p>
                            <p>Information officer: <a href="mailto:io@buyisasolutions.com">io@buyisasolutions.com</a></p>
                            <p>You may also contact Buyisa Solutions for any general queries via email at <a href="mailto:info@buyisasolutions.com">info@buyisasolutions.com</a></p>
                            <!-- /wp:paragraph -->

                        </div>
                    </div>
                </div>
            </section>
        </b-overlay>
    </div>
</template>

<script>
import SubPageHeader from "@/components/General/SubPageHeader.vue";

export default {
    name: "PrivacyPolicy",
    components: {SubPageHeader},
    data() {
        return {
            isLoading: false
        }
    }
}
</script>

<style lang="scss" scoped>
h3 {
    margin-top: 1.8rem;
    margin-bottom: .8rem;
}
</style>