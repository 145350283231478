<template>
  <div id="pay-transaction-page" class="page">

    <b-overlay :show="isLoading" rounded="sm" class="content">
      <div class="sub-page-header" style="
                position: sticky;
                top: 99px;
                background: #6c757e;
                z-index: 99999999;
                padding-top: 15px;
                padding-bottom: 16px;
            ">

        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="top-header">
                <b-button pill :to="{'name': 'SingleClientProductPage', params: {'client_product_id': clientProduct.id}}" v-if="clientProduct" variant="link">
                  <b-icon icon="arrow-left-circle" class="text-white" scale="2"></b-icon>
                </b-button>

                <h4 class="text-white">Pay Now</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                  <b-form-group id="input-group-3" label="Transaction To Pay:" label-for="input-3">
                    <b-form-select
                        id="input-3"
                        :class="{
                              'is-invalid': $v.selectedTransactionRef.$dirty && $v.selectedTransactionRef.$error,
                              'is-valid': $v.selectedTransactionRef.$dirty && !$v.selectedTransactionRef.$error
                        }"
                        v-model.trim="$v.selectedTransactionRef.$model"
                        :options="transactions"
                        required
                    ></b-form-select>
                  </b-form-group>

                  <form name="paynowForm" id="paynowForm" method="POST" action="https://paynow.netcash.co.za/site/paynow.aspx" class="d-none" target="_top"> <!---Form POST **--->
                    <input type="text" name="M1" value="8e3da9e9-2e92-44d7-8ba9-4c1dd33c5fd9"> <!--- YOUR PAY NOW SERVICE KEY GOES IN HERE --->
                    <input type="text" name="M2" value="c9b2dd2d-b0ed-40be-a92d-e4e60783a397"> <!--- SOFTWARE VENDOR KEY GOES IN HERE --->
                    <input type="text" name="p2" v-model="paymentInformation.reference"> <!---Unique ID for this / each transaction--->
                    <input type="text" name="p3" v-model="paymentInformation.description"> <!---Description of goods being purchased--->
                    <input type="text" name="p4" v-model="paymentInformation.sell_price"> <!---Amount to be settled / paid--->
                    <input type="text" name="m9" v-model="paymentInformation.client_email"> <!---Card holders email address--->
                    <input type="text" name="m11" value=""> <!---Card holders mobile number--->
                    <input type="text" name="Budget" value="N">
                    <input type="text" name="m11" value=""><!---Card holders mobile number--->
                    <input type="text" name="m4" v-model="paymentInformation.transaction_id"> <!---This is an extra field ***--->
                    <input type="text" name="m5" value=""> <!---This is an extra field ***--->
                    <input type="text" name="m6" v-model="paymentInformation.slug"> <!---This is an extra field ***--->
                    <input type="text" value="PAY">
                  </form>

                  <div class="d-flex gap-1">

                    <b-button variant="success" block :disabled="$v.selectedTransactionRef.$invalid" @click="payNow(clientProduct)">
                      Pay Now
                    </b-button>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import buyissolutionsApiService from "@/services/buyissolutions.api.service";
import currencyHelper from "@/helpers/currencyHelper";
import {required} from "vuelidate/lib/validators";

export default {
  name: "PayTransactionPage",
  components: {},
  created () {
    this.$mixpanel.track_pageview({"page": "Pay Transaction Page"});
  },
  mounted() {
    this.isLoading = true;
    buyissolutionsApiService.getClientProduct(this.$route.params.client_product_id).then(response => {
      this.isLoading = false;
      this.clientProduct = response.data.client_product;
      buyissolutionsApiService.getUnprocessedTransactionsByClientProduct(this.clientProduct.id).then(response => {
        this.isLoading = true;
        const transactions = response.data.transactions;

        this.transactions.push({ value: null, text: 'Please select a transaction to pay' })
        transactions.forEach((value) => {
          this.transactions.push({
            'value': value.reference,
            'text': value.description + ' - R' + value.amount
          })
        })
      }).finally(() => {
        this.isLoading = false;
      })
    }).finally(() => {
      this.isLoading = false;
    });
  },
  computed: {

  },
  data() {
    return {
      isLoading: false,
      selectedTransactionRef: null,
      transactions: [],
      paymentInformation: {
        reference: null,
        description: null,
        sell_price: null,
        client_email: null,
        slug: null,
        transaction_id: null,
      },
      clientProduct: null,
    }
  },
  methods: {
    payNow(clientProduct) {
      this.isLoading = true;
      this.$mixpanel.track("Settle Transaction", {"page": this.selectedTransactionRef});
      buyissolutionsApiService.createClientProductPayNowAction(clientProduct.id, this.selectedTransactionRef).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          this.paymentInformation.reference = response.data.reference;
          this.paymentInformation.description = response.data.client_product.product.description;
          this.paymentInformation.sell_price = currencyHelper.formatPrice(response.data.transaction.amount);
          this.paymentInformation.client_email = response.data.client_product.client.email;
          this.paymentInformation.transaction_id = response.data.transaction.id;
          this.paymentInformation.slug = response.data.slug;

          this.isLoading = true;
          setTimeout(function() {
            document.getElementById("paynowForm").submit();
          }, 30);
        }
      }).catch(({response}) => {
        if (response.status === 409) {
          this.$bvModal.msgBoxOk(response.data.message, {
            title: 'Oops',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
        }
      }).finally(() => {
        this.isLoading = false;
      });
    }
  },
  validations: {
    selectedTransactionRef: {
      required
    }
  }
}
</script>

<style scoped>

</style>