<template>
<div class="status-badge">
    <b-badge variant="primary" v-if="status_id === 1">Active</b-badge>
<!--    <b-badge variant="secondary">Secondary</b-badge>-->
    <b-badge variant="success" v-if="status_id === 4">Paid</b-badge>
<!--    <b-badge variant="danger">Danger</b-badge>-->
    <b-badge variant="info" v-if="status_id === 3">Awaiting Payment</b-badge>
    <b-badge variant="warning" v-if="status_id === 5">Suspend</b-badge>
<!--    <b-badge variant="light">Light</b-badge>-->
<!--    <b-badge variant="dark">Dark</b-badge>-->
</div>
</template>

<script>
export default {
    name: "StatusBadge",
    props: ['status_id']
}
</script>

<style scoped>

</style>