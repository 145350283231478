<template>
  <div class="payment-successful">
      <SubPageHeader title="Payment Failed"/>
      <section class="py-5">
          <div class="container">
              <div class="row">
                  <div class="col-sm-12">
                      <p class="text-center">We regret to inform you that your recent payment has failed. We understand that this can be frustrating, but please don't worry as there may be a simple solution. We kindly suggest that you double-check your payment details and ensure that your payment method is up-to-date and has sufficient funds.</p>

<!--                      <p>If you have any further concerns or require assistance, please don't hesitate to reach out to our customer support team who will be happy to help you resolve the issue. We apologize for any inconvenience caused and thank you for your understanding.</p>-->

                      <b-alert show variant="danger" class="d-flex align-items-center gap-1">
                          <b-icon icon="exclamation-triangle-fill"/>
                          Danger Alert
                      </b-alert>
                      <SocialMediaBanner/>
                  </div>
              </div>
          </div>
      </section>
  </div>
</template>

<script>
import SubPageHeader from "@/components/General/SubPageHeader.vue";
import SocialMediaBanner from "@/components/General/SocialMediaBanner.vue";

export default {
    name: "PaymentFailed",
    components: {SocialMediaBanner, SubPageHeader},
    created () {
      this.$mixpanel.track_pageview({"page": "Payment Failed"});
    },
}
</script>

<style scoped lang="scss">
.contact-paths {
    padding: 3em 0;
    gap: 1em;

    .contact-path {
        border-right: #c2c3c4 1px solid;
        padding: 0 3em;
    }
    .contact-path:last-of-type  {
        border: none;
    }
}
</style>