<template>
  <div class="product-item">
<!--      <div class="pricing-item wow fadeInUp sale animated" data-wow-delay="300ms" data-sale="30" style="visibility: visible; animation-delay: 300ms; animation-name: fadeInUp;">-->
      <div class="pricing-item wow fadeInUp animated" data-wow-delay="300ms" data-sale="30" style="visibility: visible; animation-delay: 300ms; animation-name: fadeInUp;">

<!--          <b-icon icon="bookmark-fill"></b-icon>-->

          <h3 class="font-light darkcolor">{{ product.sell_price | currency('R') }}/<small class="pricing-duration">{{ product.bill_occurrence.name }}</small></h3>
          <p class="bottom30">{{ product.description }}</p>
          <div class="pricing-price darkcolor">
              <span class="pricing-currency">{{ product.name }} Data </span>
          </div>
<!--          <ul class="pricing-list">-->
<!--              <li>{{ product.product_type.name }}</li>-->
<!--              <li>Free hosting</li>-->
<!--              <li class="price-not">40MB of storage space</li>-->
<!--              <li class="price-not">Social media integration</li>-->
<!--              <li class="price-not">10GB of storage space</li>-->
<!--          </ul>-->
          <router-link class="btn button button-black rounded-pill" :to="{name: 'SingleProduct', params: {'product_id': product.id}}">
              Buy Now!<span></span>
          </router-link>
      </div>
  </div>


</template>

<script>

import BuyissolutionsApiService from "@/services/buyissolutions.api.service";

export default {
    name: "ProductItem",
    components: {},
    props: [
        'product'
    ],
    data() {
        return {
            isLoading: false,
            showPurchaseModal: false,
            selectedProduct: null,
        }
    },
    mounted() {
    },
    methods: {
        viewProduct(product) {
            this.isLoading = true;
            // this.$store.state.mobileSignUpData.product = null;
            // this.showHideModal = true;

            // this.$store.state.isSignUpModalOpen = true;
            this.$bvModal.show('purchase-mobile-data-package');
            BuyissolutionsApiService.getProductById(product.id).then(response => {
                this.$store.state.selectedProduct = response.data.product;

                this.isLoading = false;
            })
        }
    }
}
</script>

<style scoped>

.pricing-item {
    position: relative;
    padding: 2.5rem 1rem;
    margin-bottom: 10px;
    margin-top: 10px;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .12);
    box-shadow: 0 0 10px rgba(0, 0, 0, .12);
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    -webkit-border-radius: 0;
    text-align: center;
    overflow: hidden;
    background: #fff;

    .pricing-price {
        padding: 15px 0 14px 0;
        margin: 2em auto;
        border-top: 1px solid rgba(139, 144, 157, 0.18);
        border-bottom: 1px solid rgba(139, 144, 157, 0.18);
    }

    .pricing-currency {
        font-size: 3rem;
        font-weight: 300;
    }

    .pricing-item.gray.sale::before {
        color: #4A5158;
    }

    .btn {
        display: table;

        margin: 0 auto;
    }
}


.pricing-list li::before,
.price-included::before {
    content: url('data:image/svg+xml; utf8, <svg data-v-41be6633="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="check lg" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-check-lg mx-auto b-icon bi"><g data-v-41be6633=""><path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"></path></g></svg>');
    font-weight: 900;
    position: absolute;
    top: 2px;
    left: -25px;
    color: #002E5B;
}
.price-included::before{
    color: #fde428;
}

.pricing-list li.price-not::before {
    content: url('data:image/svg+xml; utf8, <svg data-v-41be6633="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="x lg" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-x-lg mx-auto b-icon bi"><g data-v-41be6633=""><path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"></path></g></svg>');
    font-weight: 900;
    position: absolute;
    top: 2px;
    left: -23px;
    color: #002E5B;
}

.pricing-price {
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    transform: scale(1);
}

.pricing-list {
    font-size: 14px;
    padding: 25px 0;
    display: inline-block;
    list-style: none;
}

.pricing-list li {
    margin: 14px 0 14px 25px;
    text-align: left;
    font-size: 1rem;
    position: relative;
}

.price-included {
    position: relative;
    display: table;
    margin: 14px 0 14px 27px;
    padding: 2px 0 10px 5px;
    text-align: left;
    font-size: 1.1rem;
}

.pricing-item.sale::before,
.shopping-box .sale::before {
    content: url('data:image/svg+xml; utf8, <svg data-v-41be6633="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="bookmark fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-bookmark-fill mx-auto b-icon bi"><g data-v-41be6633=""><path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z"></path></g></svg>');
    font-weight: 900;
    position: absolute;
    top: -28px;
    right: 15px;
    font-size: 60px;
    color: #fde428;
    z-index: 1;
}

.shopping-box .sale::before {
    z-index: 100;
}

.pricing-item.sale::after,
.shopping-box .sale::after {
    content: "- " attr(data-sale) "%";
    position: absolute;
    top: 6px;
    right: 19px;
    font-size: 14px;
    color: #002e5b;
    z-index: 2;
}

.shopping-box .sale::after {
    z-index: 101;
}

.pricing-item.sale:hover::after,
.shopping-box .sale:hover::after {
    content: 'Sale';
    right: 23px;
    color: #002E5B;
}
.pricing-item.sale:hover::before,
.shopping-box .sale:hover::before {
    color: #fff;
}

li {
    margin: 14px 0 14px 25px;
    text-align: left;
    font-size: 1rem;
    position: relative;
}

    .pricing-item.active {
        background: #002E5B;
    }

.pricing-item.active .pricing-price {
    border-top: 1px solid rgba(255, 255, 255, 0.18);
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
}
</style>