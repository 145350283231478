<template>
    <div class="about">
        <div class="contact-us">
            <SubPageHeader title="Contact Us"/>
        </div>
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <iframe id="contact-us-map" class="position-relative"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14332.984863590096!2d28.107775788631912!3d-26.090880235813245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e956d523b91b10b%3A0x583814eb28065f0!2sLinbro%20Park%2C%20Sandton%2C%202090!5e0!3m2!1sen!2sza!4v1668460025237!5m2!1sen!2sza"
                                frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                                tabindex="0"></iframe>
                    </div>
                </div>
            </div>
        </section>

        <ContactFormSection/>
    </div>
</template>

<script>
import SubPageHeader from "@/components/General/SubPageHeader.vue";
import ContactFormSection from "@/components/General/ContactFormSection.vue";

export default {
    name: "ContactView",
    components: {ContactFormSection, SubPageHeader}
}
</script>

<style lang="scss" scoped>
#contact-us-map {
    background: #b6b6b6;
    height: 340px;
    width: 100%;
}
</style>