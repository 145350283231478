<template>
  <div id="shopping-cart" class="page">

    <b-overlay :show="isLoading" rounded="sm" class="content">
      <div class="sub-page-header">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="top-header">
                <h4 class="text-white">Shopping Cart</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <b-list-group>
                <!-- Products -->
                <b-list-group-item href="#" class="flex-column align-items-start"
                                   v-for="(cartItem, index) in cartProducts"
                                   :key="index">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{cartItem.product.name}}</h5>
                    <small>{{cartItem.product.sell_price | currency('R')}} {{cartItem.product.bill_occurrence.name}}</small>
                  </div>

                  <small v-for="cart_item_requirement in cartItem.cart_item_requirements"
                         :key="cart_item_requirement.value">
                    {{cart_item_requirement.detail_type.name}}: {{cart_item_requirement.value}}
                  </small>
                  <div class="d-flex w-100 justify-content-between">
                    <div></div>
                    <b-button size="sm" variant="warning" @click="removeCartItem(cartItem)">Remove Item</b-button>
                  </div>
                </b-list-group-item>

                <b-alert variant="info" show v-if="cartProducts.length === 0">
                  No products in your cart
                  <a href="https://www.buyisakonnect.co.za/promotions/our-products/prepaid-mobile-data/">see our products</a>
                </b-alert>

                <!-- coupons -->
                <b-list-group-item href="#" class="flex-column align-items-start" v-for="coupon in coupons" :key="coupon.id">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{coupon.code}}</h5>
<!--                    <small>{{coupon.end_date }}</small>-->
                  </div>

                  <small>{{coupon.percentage}}% off coupon / {{coupon.description}}</small>

                  <div class="d-flex w-100 justify-content-between">
                    <div></div>
                    <b-button size="sm" variant="warning" @click="removeCouponItem(coupon)">Remove Item</b-button>
                  </div>
                </b-list-group-item>
              </b-list-group>

              <b-card bg-variant="light" class="text-left flex-row my-2" v-if="cartProducts.length > 0 && $store.state.loggedUser && coupons.length === 0">
                <form class="form">
                  <div class="row">
                    <div class="col-sm-12">
                      <b-form-group
                          id="referral-group"
                          label="Coupon"
                          label-for="referral"
                      >
                        <b-form-input
                            id="coupon"
                            type="text"
                            v-model="coupon"
                            placeholder="Coupon"
                            required
                            :disabled="isLoadingCoupon"
                        ></b-form-input>
                      </b-form-group>

                      <b-button variant="link" :disabled="isLoadingCoupon" @click="applyCoupon()">Apply Coupon</b-button>
                      <p v-if="isLoadingCoupon">Applying coupon...</p>
                      <p class="text-danger">{{couponErrorMessage}}</p>
                      <p class="text-success">{{couponSuccessMessage}}</p>
                    </div>
                  </div>
                </form>
              </b-card>

              <b-card class="bg-white text-left flex-row my-2" v-if="cartProducts.length > 0">
                <form class="form">
                  <div class="row">
                    <div class="col-sm-12">
                      <b-form-group
                          id="referral-group"
                          label="Referral Code"
                          label-for="referral"
                      >
                        <b-form-input
                            id="referral"
                            type="text"
                            v-model="referral_code"
                            placeholder="BK123495"
                            required
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <!--                                      <div class="col">-->
                    <!--                                          <b-form-group-->
                    <!--                                              id="referral-group"-->
                    <!--                                              label="Coupon"-->
                    <!--                                              label-for="referral"-->
                    <!--                                          >-->
                    <!--                                              <b-form-input-->
                    <!--                                                  id="coupon"-->
                    <!--                                                  type="text"-->
                    <!--                                                  v-model="coupon"-->
                    <!--                                                  placeholder="Coupon"-->
                    <!--                                                  required-->
                    <!--                                              ></b-form-input>-->
                    <!--                                          </b-form-group>-->
                    <!--                                      </div>-->
                  </div>
                </form>
              </b-card>

              <div class="d-flex justify-content-between align-items-center">
                <div><h3 class="text-muted">Total {{total | currency('R') }}</h3></div>
                <b-button class="btn my-5" :disabled="!cartProducts.length" @click="makePayment" v-if="total > 0">
                  Make Payment
                </b-button>

                <b-button class="btn my-5" :disabled="!cartProducts.length" @click="makePayment" v-if="total == 0">
                  Continue
                </b-button>
              </div>

              <form name="paynowForm" id="paynowForm" method="POST" action="https://paynow.netcash.co.za/site/paynow.aspx" class="d-none" target="_top"> <!---Form POST **--->
                <input type="text" name="M1" value="8e3da9e9-2e92-44d7-8ba9-4c1dd33c5fd9"> <!--- YOUR PAY NOW SERVICE KEY GOES IN HERE --->
                <input type="text" name="M2" value="c9b2dd2d-b0ed-40be-a92d-e4e60783a397"> <!--- SOFTWARE VENDOR KEY GOES IN HERE --->
                <input type="text" name="p2" v-model="paymentInfo.reference"> <!---Unique ID for this / each transaction--->
                <input type="text" name="p3" v-model="paymentInfo.email"> <!---Description of goods being purchased--->
                <input type="text" name="p4" v-model="total"> <!---Amount to be settled / paid--->
                <input type="text" name="m9" v-model="paymentInfo.email"> <!---Card holders email address--->
                <input type="text" name="m11" value=""> <!---Card holders mobile number--->
                <input type="text" name="Budget" value="N">
                <input type="text" name="m11" value=""><!---Card holders mobile number--->
                <input type="text" name="m4" value=""> <!---This is an extra field ***--->
                <input type="text" name="m5" value=""> <!---This is an extra field ***--->
                <input type="text" name="m6" value="mobile_prepaid_initial_cart_payment"> <!---This is an extra field ***--->
                <input type="text" value="PAY">
              </form>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import buyissolutionsApiService from "@/services/buyissolutions.api.service";
import cartHelper from "@/helpers/cart.helper";
import currencyHelper from "@/helpers/currencyHelper";

export default {
    name: "ShoppingCart",
    computed: {
        currencyHelper() {
            return currencyHelper
        }
    },
    components: {},
    mounted() {
        this.isLoading = true;
        // cartHelper.getCart().then(cart => {
        //     buyissolutionsApiService.cartResync(cart).then(response => {
        //         if (!response.data || response.data.length === 0) {
        //             return;
        //         }
        //         this.cartProducts = response.data.cart_items;
        //         this.cartProducts.forEach((value) => {
        //             console.log(value)
        //             this.total += value.product.sell_price;
        //         });
        //
        //         this.total = currencyHelper.formatPrice(this.total);
        //     })
        //     .finally(() => {
        //         this.isLoading = false;
        //     });
        // })
      this.cartResync();
    },
    created() {
      this.$mixpanel.track_pageview({"page": "Shopping Cart"});
    },
    data() {
        return {
            isLoading: false,
            isLoadingCoupon: false,
            couponErrorMessage: null,
            couponSuccessMessage: null,
            total: 0.00,
            cartProducts: [],
            coupons: [],
            referral_code: null,
            coupon: null,
            paymentInfo: {
                reference: null,
                email: '',
            }
        }
    },
    methods: {
        removeCartItem(cartItem) {
          this.$mixpanel.track("Remove Product From Cart", {"product": cartItem});

            this.isLoading = true;
            this.$bvModal.msgBoxConfirm('Are you sure you want remove this ' + cartItem.product.name + ' package from your cart? ', {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                console.log(value);

                if (value) {
                    cartHelper.removeCartItem(cartItem).then(() => {
                        cartHelper.getCart().then(cart => {
                            buyissolutionsApiService.cartResync(cart).then(response => {
                                // console.log(response);
                                this.cartProducts = response.data.cart.cart_items;
                                location.reload();
                            });
                        });
                    })
                        .finally(() => {
                            this.isLoading = false;
                        });
                }
            })
            .catch(err => {
                console.log(err)
            }).finally(() => {
                this.isLoading = false;
            });
        },
        removeCouponItem(coupon) {
          this.$mixpanel.track("Remove Product From Coupon", {"product": coupon});

            this.isLoading = true;
            this.$bvModal.msgBoxConfirm('Are you sure you want remove this ' + coupon.code + ' package from your cart? ', {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                console.log(value);

                if (value) {
                    cartHelper.removeCoupon(coupon).then(() => {
                        cartHelper.getCart().then(cart => {
                            buyissolutionsApiService.cartResync(cart).then(response => {
                                // console.log(response);
                                this.coupons = response.data.cart.coupons;
                                location.reload();
                            });
                        });
                    })
                        .finally(() => {
                            this.isLoading = false;
                        });
                }
            })
            .catch(err => {
                console.log(err)
            }).finally(() => {
                this.isLoading = false;
            });
        },
        makePayment() {
            this.isLoading = true;
            buyissolutionsApiService.getLoggedInUser().then((response) => {
                if (response.status === 200) {
                    const LoggedInUser = response.data;
                    cartHelper.setReferralCode(this.referral_code).then(() => {
                        cartHelper.getCart().then(cart => {
                            buyissolutionsApiService.cartResync(cart).then(() => {
                                if (cart.userId === LoggedInUser.id) {
                                    buyissolutionsApiService.checkout(cart).then(response => {
                                      this.$mixpanel.track("Checkout Cart", {"cart": cart});

                                        this.isLoading = true;
                                        if(response.status === 200) {
                                            const cart = response.data.cart;
                                            this.paymentInfo.reference = cart.reference;
                                            this.paymentInfo.email = cart.client.email;
                                            this.total = response.data.total;

                                            cartHelper.clearCart().then(() => {
                                              if (response.data.is_free === true) {
                                                console.log('this product is free :)');

                                                this.$router.push({
                                                  'name': 'SignUpComplete',
                                                  params: {'order_reference': cart.reference}
                                                });
                                              } else {
                                                this.$mixpanel.track("Redirecting To NetCash");
                                                setTimeout(function() {
                                                  document.getElementById("paynowForm").submit();
                                                }, '1 second');
                                              }
                                            });
                                        }
                                    })
                                    .catch(({response}) => {
                                      this.$mixpanel.track("Checkout Failed");
                                        this.$bvModal.msgBoxOk(response.data.message, {
                                            title: 'Oops something is not right',
                                            size: 'sm',
                                            buttonSize: 'sm',
                                            okVariant: 'danger',
                                            headerClass: 'p-2 border-bottom-0',
                                            footerClass: 'p-2 border-top-0',
                                            centered: true
                                        });
                                        this.isLoading = false;
                                    }).finally(() => {
                                        // this.isLoading = false;
                                    });
                                } else {
                                    cartHelper.setUserId(LoggedInUser.id).then(() => {
                                        buyissolutionsApiService.checkout(cart).then(response => {
                                            console.log(response);
                                        })
                                        .catch(({response}) => {
                                            this.$bvModal.msgBoxOk(response.data.message, {
                                                title: 'Oops something is not right',
                                                size: 'sm',
                                                buttonSize: 'sm',
                                                okVariant: 'danger',
                                                headerClass: 'p-2 border-bottom-0',
                                                footerClass: 'p-2 border-top-0',
                                                centered: true
                                            });
                                        });
                                    });

                                    this.isLoading = false;
                                    alert('Seems like you sign up for someone else cart mate.')
                                }
                            });
                        })
                    })
                }
            }).catch(({response}) => {
                if (response.status === 401) {
                    this.$store.state.signup.type = 'new_user';
                    this.$bvModal.show('register-login-forms');
                }

                this.isLoading = false;
          });
        },
        applyCoupon() {
          this.isLoadingCoupon = true;
          this.couponErrorMessage = null;

          cartHelper.getCart().then(cart => {
            buyissolutionsApiService.validateCoupon(this.coupon, cart).then((response) => {
              if (response.status === 200) {
                if (!response.data.coupon.id) {
                  this.couponErrorMessage = 'Something is wrong with your coupon, please contact support';
                }

                cartHelper.applyCoupon(response.data).then(() => {
                  this.$mixpanel.track("Applied Coupon", {'coupon': response.data});
                  this.coupon = null;
                  this.cartResync();
                })
              } else {
                console.warn(response.data)
                this.couponErrorMessage = 'Could not apply coupon, please contact support';
              }
            }).catch(({response}) => {
              this.couponErrorMessage = response.data.message;
            }).finally(() => {
              this.isLoadingCoupon = false;
            })
          });
        },
        cartResync() {
          cartHelper.getCart().then(cart => {
            buyissolutionsApiService.cartResync(cart).then(response => {
              if (!response.data || response.data.length === 0) {
                return;
              }
              this.cartProducts = response.data.cart.cart_items;

              if (this.cartProducts.length === 0) {
                cartHelper.clearCoupons()
              }

              this.coupons = response.data.coupons;
              // this.cartProducts.forEach((value) => {
              //   // console.log(value)
              //   this.total += value.product.sell_price;
              // });

              this.total = currencyHelper.formatPrice(response.data.total);
            })
            .finally(() => {
              console.log(this.cartProducts)
              this.isLoading = false;
            });
          })
        }
    }
}
</script>

<style scoped>

</style>