import localforage from "localforage";

function getToken() {
    return localforage.getItem("token");
}

function setToken(value) {
    return localforage.setItem('token', value);
}

function clearToken() {
    return localforage.removeItem('token');
}

// localStorage.clear();  //The syntax for removing all the localStorage items is as follows:
export default {
    getToken:getToken,
    setToken:setToken,
    clearToken:clearToken
}