<template>
  <div id="cancel-client-product-page" class="page">

    <b-overlay :show="isLoading" rounded="sm" class="content">

    <div class="sub-page-header" style="
                position: sticky;
                top: 99px;
                background: #6c757e;
                z-index: 99999999;
                padding-top: 15px;
                padding-bottom: 16px;
            ">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="top-header">
              <b-button pill :to="{'name': 'SingleClientProductPage', params: {'client_product_id': client_product.id}}" v-if="client_product" variant="link">
                <b-icon icon="arrow-left-circle" class="text-white" scale="2"></b-icon>
              </b-button>

              <h4 class="text-white">Cancel Product</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h5>{{ client_product.friendly_name }}</h5>
          <data-usage-information-card class="mb-5"
                                       v-if="data_usage_information"
                                       :allocated="data_usage_information.allocated"
                                       :used="data_usage_information.used"
                                       :used_percentage="data_usage_information.used_percentage"
          />
        </div>
      </div>

        <div class="row">
          <div class="col-md-12">
            <p>We are really sad to see you leave, but wish you all the best for the future and hope to see you again</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">

            <form id="bulk-signup-product-selection-form">
              <b-form-group
                  id="product-select-input-group"
                  label="Reason"
                  label-for="reason"
                  description=""
              >
                <b-form-select
                    id="reason"
                    type="text"
                    placeholder=""
                    required
                    v-model="selectedReason"
                    :options="cancellationReasonsOptions"
                    value-field="id"
                    text-field="name"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                  id="product-select-input-group"
                  label="How can we improve?"
                  label-for="improvement"
                  description=""
              >
                <b-form-textarea
                    id="improvement"
                    type="text"
                    placeholder=""
                    required
                    v-model="improvement"
                    value-field="id"
                    text-field="name"
                ></b-form-textarea>
              </b-form-group>


              <b-button variant="danger" block>
                Cancel My Product
              </b-button>
            </form>
          </div>
        </div>
    </div>
    </div>

  </b-overlay>
  </div>
</template>

<script>
import buyissolutionsApiService from "@/services/buyissolutions.api.service";
import DataUsageInformationCard from "@/v4/components/DataUsageInformationCard.vue";

export default {
  name: "CancelClientProductPage",
  components: {DataUsageInformationCard},
  computed: {
    getClientProductId() {
      return this.$route.params.client_product_id;
    }
  },
  created () {
    this.$mixpanel.track_pageview({"page": "Cancel Client Product Page"});
  },
  mounted() {
    console.log('clientProductId', this.getClientProductId)
    this.getClientProduct();
  },
  data() {
    return {
      isLoading: false,
      client_product: null,
      selectedReason: null,
      cancellationReasonsOptions: [

      ],
      improvement: null
    }
  },
  methods: {
    getClientProduct() {
      this.isLoading = true;
      buyissolutionsApiService.getClientProduct(this.getClientProductId).then(result => {
        this.client_product = result.data.client_product
        this.data_usage_information = result.data.data_usage_information
      }).finally(() => {
        this.isLoading = false
      })
    },
  }
}
</script>

<style scoped>

</style>