<template>
  <div class="payment-successful">
      <SubPageHeader title="Payment Successful"/>
      <b-overlay :show="isLoading" rounded="sm" class="content">
          <section class="section">
          <div class="container">
              <div class="row">
                  <div class="col-sm-12 text-center">
                      <h4>Thank you for your purchase.</h4>
                      <p>
                          Your payment has been successfully processed and your order is now being prepared. <br>
                          You will receive an email with the details of your order and tracking information once your package has been shipped.
                          If you have any questions or concerns, please don't hesitate to contact our customer support team.
                          <br>
                          Thank you for shopping with us!
                      </p>

                      <router-link class="btn button button-black rounded-pill" :to="{name: 'MyProfile'}">
                          Got To My Profile
                          <span></span>
                          <span></span>
                          <span></span>
                      </router-link>
                      <SocialMediaBanner/>
                  </div>
              </div>
          </div>
      </section>
      </b-overlay>
  </div>
</template>

<script>
import SubPageHeader from "@/components/General/SubPageHeader.vue";
import SocialMediaBanner from "@/components/General/SocialMediaBanner.vue";
import cartHelper from "@/helpers/cart.helper";

export default {
    name: "PaymentSuccessful",
    components: {SocialMediaBanner, SubPageHeader},
    created() {
      this.$mixpanel.track_pageview({"page": "Payment Successful"});
        this.isLoading = true;
        cartHelper.clearCart().then(() => {
            this.isLoading = false;
        });
    },
    data() {
        return {
            isLoading: false
        }
    },
}
</script>

<style scoped lang="scss">
.contact-paths {
    padding: 3em 0;
    gap: 1em;

    .contact-path {
        border-right: #c2c3c4 1px solid;
        padding: 0 3em;
    }
    .contact-path:last-of-type  {
        border: none;
    }
}
</style>