import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import Vue2Filters from 'vue2-filters'
import VueMoment from 'vue-moment'

import VueMixpanelBrowser from 'vue-mixpanel-browser';



Vue.config.productionTip = false

import './styles/app.scss'
import './v4/styles/scss/main.scss'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Vuelidate)
Vue.use(VueMoment);
Vue.use(Vue2Filters)
Vue.use(VueMixpanelBrowser, {
  token: '2be2f5969ed666b45423ff734f84a7a4',
  config: {
    debug: false,
    track_pageview: "full-url"
  }
});


new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
