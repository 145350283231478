<template>
    <div class="contact-paths d-flex justify-content-between my-5">
        <div class="contact-path">
            <h5><a target="_blank" href="https://www.facebook.com/profile.php?id=100086595606567"><b-icon icon="facebook" size="lg"></b-icon> Facebook</a></h5>
        </div>
        <div class="contact-path">
            <h5><a target="_blank" href="https://twitter.com/BuyisaSolutions"><b-icon icon="twitter" size="lg"></b-icon> Twitter</a></h5>
        </div>
        <div class="contact-path">
            <h5><a target="_blank" href="https://www.instagram.com/buyisa_solutions/"><b-icon icon="instagram" size="lg"></b-icon> Instagram</a></h5>
        </div>
        <div class="contact-path">
            <h5><a target="_blank" href="https://www.linkedin.com/in/buyisa-solutions-555610252/"><b-icon icon="linkedin" size="lg"></b-icon> LinkedIn</a></h5>
        </div>
        <div class="contact-path">
            <h5><router-link target="_blank" to="/contact"><b-icon icon="envelope" size="lg"></b-icon> Email</router-link></h5>
        </div>
        <div class="contact-path">
            <h5><router-link target="_blank" to="/contact"><b-icon icon="whatsapp" size="lg"></b-icon> WhatsApp</router-link></h5>
        </div>
    </div>
</template>

<script>
export default {
    name: "SocialMediaBanner"
}
</script>

<style scoped>

</style>