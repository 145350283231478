<template>
  <div class="products prepaid-mobile-data">
      <SubPageHeader title="Prepaid Mobile Data"/>
<!--      <section class="sub-page-header">-->
<!--          <div class="container">-->
<!--              <div class="row">-->
<!--                  <div class="col-12 stats-heading-area">-->
<!--                      <h4 class="heading">Prepaid Mobile Data</h4>-->
<!--&lt;!&ndash;                      <span class="sub-heading">Products > Mobile > Prepaid</span>&ndash;&gt;-->
<!--                  </div>-->
<!--              </div>-->
<!--          </div>-->
<!--      </section>-->

      <section class="py-5">
          <div class="container">
              <div class="row">
                <div class="col-md-12" v-if="user">
                  <div class="d-flex gap-1">
                    <router-link class="btn btn-outline-dark" :to="{name: 'MyAccount'}">My Account</router-link>
                    <router-link class="btn btn-outline-success disabled" to="#">Bulk Signup</router-link>
                  </div>
                </div>
                  <div class="col-12 stats-heading-area text-center">
                      <b-overlay :show="isLoading" rounded="sm">
                          <ProductList :products="products"/>
                      </b-overlay>
                  </div>
              </div>
          </div>
      </section>
  </div>
</template>

<script>
import ProductList from "@/components/Products/ProductList.vue";
import buyissolutionsApiService from "@/services/buyissolutions.api.service";
import SubPageHeader from "@/components/General/SubPageHeader.vue";

export default {
    name: "PrepaidMobileData",
    components: {SubPageHeader, ProductList},
    data() {
        return {
            isLoading: false,
            user: null,
            products: []
        }
    },
    mounted() {
        this.getProducts();
        this.getLoggedInUser();
    },
    methods: {
        getProducts() {
            this.isLoading = true;
            return buyissolutionsApiService.getProducts('mobile_prepaid_data').then(response => {

                this.products = response.data.products;

                this.isLoading = false;
            });
        },
        getLoggedInUser() {
          buyissolutionsApiService.getLoggedInUser().then(response => {
            this.user = response.data.user;
          }).catch(response => {
            console.log(response.data.message);
          }).finally(() => {
          });
        }
    }
}
</script>

<style scoped lang="scss">
</style>