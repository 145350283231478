<template>
    <div class="reset-password">
        <b-overlay :show="isLoading" rounded="sm">
            <form id="login-form-forgot-password">
            <b-form-group
                    id="username-input-group"
                    label="Username"
                    label-for="username"
                    description=""
            >
                <b-form-input
                        :class="{
                              'is-invalid': $v.resetPassword.username.$dirty && $v.resetPassword.username.$error,
                              'is-valid': $v.resetPassword.username.$dirty && !$v.resetPassword.username.$error
                        }"
                        id="username"
                        v-model.trim="$v.resetPassword.username.$model"
                        type="text"
                        placeholder=""
                        required
                ></b-form-input>
            </b-form-group>

            <b-alert show variant="info">
                Please enter your username or email address. You will receive an email message with instructions on how to reset your password.
            </b-alert>

            <b-button @click.prevent="resetMyPassword()" :disabled="$v.resetPassword.$invalid">
                Reset My Password
            </b-button>
            <b-button variant="link" href="#"  @click.prevent="$store.state.signup.type='existing_user'">Try Logging In Again</b-button>
        </form>
        </b-overlay>
    </div>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import buyissolutionsApiService from "@/services/buyissolutions.api.service";

export default {
    name: "ResetPassword",
    data() {
        return {
            isLoading: false,
            resetPassword: {
                username: null
            }
        }
    },
    created () {
      this.$mixpanel.track_pageview({"page": "Reset Password Page"});
    },
    methods: {
        resetMyPassword() {
            this.isLoading = true;
            buyissolutionsApiService.postResetPassword(this.resetPassword.username).then((response) => {
              this.$mixpanel.track("Initiate Password Reset",{"username": this.resetPassword.username});
              console.log(response)
            }).catch(() => {
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    validations: {
        resetPassword: {
            username: {
                required,
                email
            },
        }
    }
}
</script>

<style scoped>

</style>