var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-content personal-details"},[_c('b-overlay',{staticClass:"content",attrs:{"show":_vm.isLoading,"rounded":"sm"}},[(_vm.errorMessage)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),(!_vm.$v.profileForm.contact_number.$model)?_c('b-alert',{attrs:{"variant":"warning","show":""}},[_vm._v("Please provide us mobile number that we can send you notifications via WhatsApp and SMS")]):_vm._e(),_c('form',{attrs:{"id":"personal-details-form"}},[_c('b-form-group',{attrs:{"id":"first_name-input-group","label":"First Name","label-for":"first_name","description":""}},[_c('b-form-input',{class:{
                        'is-invalid': _vm.$v.profileForm.first_name.$dirty && _vm.$v.profileForm.first_name.$error,
                        'is-valid': _vm.$v.profileForm.first_name.$dirty && !_vm.$v.profileForm.first_name.$error
                    },attrs:{"id":"first_name","type":"text","placeholder":"","required":""},model:{value:(_vm.$v.profileForm.first_name.$model),callback:function ($$v) {_vm.$set(_vm.$v.profileForm.first_name, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.profileForm.first_name.$model"}})],1),_c('b-form-group',{attrs:{"id":"first_name-input-group","label":"Last Name","label-for":"last_name","description":""}},[_c('b-form-input',{class:{
                        'is-invalid': _vm.$v.profileForm.last_name.$dirty && _vm.$v.profileForm.last_name.$error,
                        'is-valid': _vm.$v.profileForm.last_name.$dirty && !_vm.$v.profileForm.last_name.$error
                    },attrs:{"id":"last_name","type":"text","placeholder":"","required":""},model:{value:(_vm.$v.profileForm.last_name.$model),callback:function ($$v) {_vm.$set(_vm.$v.profileForm.last_name, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.profileForm.last_name.$model"}})],1),_c('b-form-group',{attrs:{"id":"email-input-group","label":"Email Address","label-for":"email","description":""}},[_c('b-form-input',{class:{
                        'is-invalid': _vm.$v.profileForm.email.$dirty && _vm.$v.profileForm.email.$error,
                        'is-valid': _vm.$v.profileForm.email.$dirty && !_vm.$v.profileForm.email.$error
                    },attrs:{"id":"email","type":"text","placeholder":"","required":""},model:{value:(_vm.$v.profileForm.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.profileForm.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.profileForm.email.$model"}})],1),_c('b-form-group',{attrs:{"id":"contact-input-group","label":"Contact Number","label-for":"contact","description":"We use this number to send you notifications via WhatsApp and email."}},[_c('b-form-input',{class:{
                        'is-invalid': _vm.$v.profileForm.contact_number.$dirty && _vm.$v.profileForm.contact_number.$error,
                        'is-valid': _vm.$v.profileForm.contact_number.$dirty && !_vm.$v.profileForm.contact_number.$error
                    },attrs:{"id":"contact","type":"text","placeholder":"","required":""},model:{value:(_vm.$v.profileForm.contact_number.$model),callback:function ($$v) {_vm.$set(_vm.$v.profileForm.contact_number, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.profileForm.contact_number.$model"}})],1),_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"switch":""},model:{value:(_vm.passwordChange),callback:function ($$v) {_vm.passwordChange=$$v},expression:"passwordChange"}},[_vm._v("Update Password")]),(_vm.passwordChange)?_c('div',[_c('b-form-group',{attrs:{"id":"password-input-group","label":"Password","label-for":"password","description":""}},[_c('b-form-input',{class:{
                        'is-invalid': _vm.$v.profileForm.password.$dirty && _vm.$v.profileForm.password.$error,
                        'is-valid': _vm.$v.profileForm.password.$dirty && !_vm.$v.profileForm.password.$error
                    },attrs:{"id":"password","type":"password","placeholder":"","required":""},model:{value:(_vm.$v.profileForm.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.profileForm.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.profileForm.password.$model"}})],1),_c('b-form-group',{attrs:{"id":"retype-password-input-group","label":"Retype Password","label-for":"retype_password","description":""}},[_c('b-form-input',{class:{
                        'is-invalid': _vm.$v.profileForm.retype_password.$dirty && _vm.$v.profileForm.retype_password.$error,
                        'is-valid': _vm.$v.profileForm.retype_password.$dirty && !_vm.$v.profileForm.retype_password.$error
                    },attrs:{"id":"retype_password","type":"password","placeholder":"","required":""},model:{value:(_vm.$v.profileForm.retype_password.$model),callback:function ($$v) {_vm.$set(_vm.$v.profileForm.retype_password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.profileForm.retype_password.$model"}})],1)],1):_vm._e(),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"6"}},[_c('b-button',{attrs:{"block":"","variant":"secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Back")])],1),_c('b-col',{staticClass:"pb-2",attrs:{"lg":"6"}},[_c('b-button',{attrs:{"block":"","variant":"success","disabled":_vm.$v.profileForm.$invalid},on:{"click":function($event){return _vm.updateProfile()}}},[_vm._v(" Save Profile ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }