var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3",attrs:{"id":"login"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"sm"}},[_c('h2',{staticClass:"mb-3"},[_vm._v("Login To My Account")]),(_vm.errorMessage)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),_c('form',{attrs:{"id":"login-form"}},[_c('b-form-group',{attrs:{"id":"username-input-group","label":"Username","label-for":"username","description":""}},[_c('b-form-input',{class:{
                              'is-invalid': _vm.$v.loginCred.username.$dirty && _vm.$v.loginCred.username.$error,
                              'is-valid': _vm.$v.loginCred.username.$dirty && !_vm.$v.loginCred.username.$error
                          },attrs:{"id":"username","type":"text","placeholder":"","required":""},model:{value:(_vm.$v.loginCred.username.$model),callback:function ($$v) {_vm.$set(_vm.$v.loginCred.username, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.loginCred.username.$model"}})],1),_c('b-form-group',{attrs:{"id":"password-input-group","label":"Password","label-for":"password","description":""}},[_c('b-form-input',{class:{
                              'is-invalid': _vm.$v.loginCred.password.$dirty && _vm.$v.loginCred.password.$error,
                              'is-valid': _vm.$v.loginCred.password.$dirty && !_vm.$v.loginCred.password.$error
                          },attrs:{"id":"password","type":"password","placeholder":"","required":""},model:{value:(_vm.$v.loginCred.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.loginCred.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.loginCred.password.$model"}})],1),_c('b-alert',{attrs:{"show":""}},[_vm._v(" By logging in you accept our latest "),_c('a',{attrs:{"href":"/terms-and-conditions"}},[_vm._v("Terms and Conditions")])]),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"6"}},[_c('b-button',{staticClass:"btn-block",attrs:{"variant":"success","disabled":_vm.$v.loginCred.$invalid},on:{"click":_vm.loginToMyAccount}},[_vm._v(" Login ")])],1),_c('b-col',{staticClass:"pb-2",attrs:{"lg":"6"}},[_c('b-button',{staticClass:"btn-block",attrs:{"href":"","variant":"light"},on:{"click":function($event){$event.preventDefault();_vm.$store.state.signup.type='new_user'}}},[_vm._v(" Register ")])],1)],1),_c('div',[_c('router-link',{attrs:{"to":{name: 'ForgotPasswordPage'}},on:{"click":function($event){$event.preventDefault();_vm.$store.state.signup.type='new_user'}}},[_vm._v("I Forgot My Password")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }