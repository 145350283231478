<template>
  <div class="sign-up-complete">
    <b-overlay :show="isLoading" rounded="sm" class="content">
      <div class="sub-page-header" style="
                position: sticky;
                top: 99px;
                background: #6c757e;
                z-index: 99999999;
                padding-top: 15px;
                padding-bottom: 16px;
            ">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="top-header">
                <h4 class="text-white">Sign Up Complete</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content">
        <div class="container">
          <div class="row">
            <div class="col-sm-12" v-if="total">

              <b-alert variant="success" show v-if="order.status.id === 0">Thank you for your order! We're currently processing it and will notify you via email once it's ready.</b-alert>

              <h3 class="mb-2">Order Number: {{order.reference}}</h3>


              <h5>Products</h5>
              <ul>
                <li v-for="(cart_item, key) in order.cart_items" :key="key">
                  {{ cart_item.product.description }} - {{ cart_item.product.sell_price }}
                </li>
              </ul>

              <hr>

              <h5>Coupons</h5>
              <ul>
                <li v-for="(cart_coupon, key) in order.cart_coupons" :key="key">
                  {{ cart_coupon.coupon.code }} - {{ cart_coupon.coupon.percentage }} % off
                </li>
              </ul>

              <hr>

              <h5>Status: {{ order.status.name }}</h5>

              <br>

              <h5>Discounts:  R{{ discount_value }}</h5>
              <h5>Total: R{{ total }}</h5>

            </div>
          </div>
        </div>
      </div>

    </b-overlay>
  </div>
</template>

<script>
import buyissolutionsApiService from "@/services/buyissolutions.api.service";

export default {
  name: "SignUpComplete",
  created () {
    this.$mixpanel.track_pageview({"page": "Sign Up Complete"});
  },
  mounted() {
    this.isLoading = true;
    if (this.$route.params.order_reference) {
      this.getOrderByOrderReference(this.$route.params.order_reference);
    }
  },
  data() {
    return {
      isLoading: false,
      order: null,
      discount_value: 0.00,
      total: 0.00,
    }
  },
  methods: {
    getOrderByOrderReference(orderReference) {
      if (!orderReference) {
        return;
      }

      this.isLoading = false;

      buyissolutionsApiService.getOrderByReference(this.$route.params.order_reference).then((response) => {
        console.log(response.data.order);
        this.order = response.data.order;
        this.discount_value = response.data.discount_value;
        this.total = response.data.total;
      }).catch(({response}) => {
        console.log(response)
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>