<template>
    <div class="upload-proof-of-payment">
        <SubPageHeader title="Upload My Proof Of Payment"/>
        <b-overlay :show="isLoading" rounded="sm">

            <section class="py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-12 stats-heading-area">
                            <b-form-group id="transaction_group" label="Transaction To Pay:" label-for="transaction"

                                          description="This is the transaction charge that you are uploading the proof of payment">
                                <b-form-select
                                        id="transaction"
                                        :class="{
                                          'is-invalid': $v.selectedTransactionId.$dirty && $v.selectedTransactionId.$error,
                                          'is-valid': $v.selectedTransactionId.$dirty && !$v.selectedTransactionId.$error
                                    }"
                                        v-model.trim="$v.selectedTransactionId.$model"
                                        :options="transactions"
                                        required
                                ></b-form-select>
                            </b-form-group>


                            <b-form-group class="d-lg-none d-md-none" label="Upload Options">
                                <b-form-radio-group
                                    id="btn-upload-options"
                                    button-variant="primary"
                                    v-model="selectedUploadOption"
                                    :options="uploadOptions"
                                    name="radios-btn-default"
                                    buttons
                                ></b-form-radio-group>
                            </b-form-group>

<!--                            <pre>{{ selectedUploadOption }}</pre>-->

                            <b-form-group
                                v-if="selectedUploadOption === 1"
                                id="pop_upload_group"
                                label="Upload Proof Of Payment"
                                label-for="pop_upload"
                                description="Upload your proof of payment below. Supported files are (jpg, jpeg, png and gif)"
                            >
                                <b-form-file
                                    id="pop_upload"
                                    accept="image/*"
                                    v-model="selectedFile"
                                    @change="onFileSelected"
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Please make sure your name container alphanumeric characters only"
                                    capture
                                ></b-form-file>
                            </b-form-group>

                            <b-form-group
                                v-if="selectedUploadOption === 2"
                                id="pop_upload_group"
                                label="Proof Of Payment"
                                label-for="pop_upload"
                                description="Upload your proof of payment below. Supported files are (jpg, jpeg, png and gif)"
                            >
                                <b-form-file
                                    id="pop_upload"
                                    accept="image/*"
                                    v-model="selectedFile"
                                    @change="onFileSelected"
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Please make sure your name container alphanumeric characters only"
                                ></b-form-file>
                            </b-form-group>


<!--                            <input type="file" @change="onFileSelected">-->

                            <br>

                            <b-button class="btn button rounded-pill button-black" v-if="selectedFile && $v.selectedTransactionId.$invalid === false" @click="uploadFile()">Upload
                                <span></span>
                                <span></span>
                                <span></span>
                            </b-button>
                        </div>
                    </div>
                </div>
            </section>
        </b-overlay>
    </div>
</template>

<script>
import SubPageHeader from "@/components/General/SubPageHeader.vue";
import buyissolutionsApiService from "@/services/buyissolutions.api.service";
import {required} from "vuelidate/lib/validators";

export default {
    name: "UploadProofOfPayment",
    components: {SubPageHeader},
    mounted() {
        buyissolutionsApiService.getUnprocessedTransactionsByUser().then(response => {
            this.isLoading = true;
            const transactions = response.data.transactions;

            this.transactions.push({ value: null, text: 'Please select a transaction to pay' })
            transactions.forEach((value) => {
                this.transactions.push({
                    'value': value.id,
                    'text': value.description + ' - R' + value.amount
                })
            })
        }).finally(() => {
            this.isLoading = false;
        })
    },
    data() {
        return {
            isLoading: false,
            selectedFile: null,
            selectedUploadOption: 1,
            uploadOptions: [
                { text: 'Camera', value: 1 },
                { text: 'File', value: 2 }
            ],
            transactions: [],
            selectedTransactionId: null,
        }
    },
    methods: {
        onFileSelected() {
            // this.selectedFile = event.target.files[0]

        },
        uploadFile() {
            this.isLoading = true;

            const fd = new FormData();
            fd.append('proof_of_payment', this.selectedFile, this.selectedFile.name);
            fd.append('transaction_id', this.selectedTransactionId);
            buyissolutionsApiService.uploadProofOfPayment(fd).then(response => {
                let errorString = '';

                if (response.data.errors !== undefined && response.data.errors.length > 0) {
                    response.data.errors.forEach((item) => {
                        errorString += '\r\n' + item;
                    })
                    this.$bvModal.msgBoxOk( errorString, {
                        title: 'Oops ' + response.data.message,
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'secondary',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    })
                        .then(() => {
                            this.isLoading = false
                        })
                } else {
                    this.$bvModal.msgBoxOk( response.data.message, {
                        title: 'Success',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'secondary',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    })
                    .then(() => {
                        this.isLoading = false;
                        this.$router.push({name: 'MyProfile'});
                    });

                }
            })
            .catch(({response}) => {
                console.log(response);
                this.$bvModal.msgBoxOk(response.data.message, {
                    title: 'Oops',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'secondary',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                })
                .then(() => {
                    this.isLoading = false;
                });

            });
        }
    },
    validations: {
        selectedTransactionId: {
            required
        }
    }
}
</script>

<style scoped>

</style>