import localforage from "localforage";
import store from "@/store";

function getReferencePrefix() {
    return 'BSUID' + new Date().toISOString()
        .replace('-', '')
        .split('T')[0]
        .replace('-', '');
}

function createCart() {
    const shoppingCart = {
        "cart_id": null,
        "user_id": null,
        "referral_code": null,
        "products": [],
        "coupons": [],
    }
    return localforage.setItem('cart', shoppingCart);
}

function setCartId(cartId) {
    return localforage.getItem('cart').then(cart => {
        cart.cart_id = cartId;

        localforage.setItem('cart', cart).then( () => {
            return true;
        });
    });
}

function setUserId(userId) {
    return localforage.getItem('cart').then(cart => {
        cart.user_id = userId;

        return localforage.setItem('cart', cart);
    });
}

function setReferralCode(referralCode) {
    return localforage.getItem('cart').then(cart => {
        cart.referral_code = referralCode;

        return localforage.setItem('cart', cart);
    });
}

function addProductToCart(product, requirements = []) {
    return localforage.getItem('cart').then(cart => {
        if (!cart) {
            this.createCart().then(() => {
                return this.addProductToCart(product, requirements);
            });
        } else {
            const cartItem = {
                'product_id': product.id,
                'cart_requirements': requirements
            };

            const myCart = {
                cart_id: cart.cart_id,
                user_id: cart.user_id,
                products: cart.products,
                coupons: cart.coupons
            };

            myCart.products.push(cartItem);

            store.state.cart = myCart

            return localforage.setItem('cart', myCart);
        }
    });
}

function getCart() {
    return localforage.getItem('cart');
}

function clearCart() {
    return localforage.removeItem('cart');
}

function removeCartItem(cartItem) {
    return localforage.getItem('cart').then( cart => {
        const index = cart.products.findIndex( cartProduct => {
            return cartProduct.product_id === cartItem.product.id
        });

        cart.products.splice(index, 1);

        store.state.cart.products.splice(index, 1);

        return localforage.setItem('cart', cart);
    });
}

function removeCoupon(coupon) {
    return localforage.getItem('cart').then( cart => {
        console.log(coupon)
        console.log(cart)

        const index = cart.coupons.findIndex( couponProduct => {
            console.log('couponProduct', couponProduct)
            console.log('coupon', coupon)
            return couponProduct.id === coupon.id
        });

        console.log(index)

        cart.coupons.splice(index, 1);

        // store.state.cart.products.splice(index, 1);

        return localforage.setItem('cart', cart);
    });
}

function clearCoupons() {
    return localforage.getItem('cart').then( cart => {


        cart.coupons = [];

        return localforage.setItem('cart', cart);
    });
}

function applyCoupon(coupon) {

    return localforage.getItem('cart').then(cart => {
        console.log(cart);
        if (!cart) {
            alert('Something is not right, contact support');
        }

        if (!cart.coupons) {
            cart.coupons = [];
        }

        cart.coupons.push(coupon.coupon);

        return localforage.setItem('cart', cart);
    });
}

export default {
    createCart:createCart,
    clearCart:clearCart,
    getCart:getCart,
    setCartId:setCartId,
    setUserId:setUserId,
    setReferralCode:setReferralCode,
    addProductToCart:addProductToCart,
    applyCoupon: applyCoupon,
    removeCartItem:removeCartItem,
    removeCoupon:removeCoupon,
    clearCoupons:clearCoupons,
    getReferencePrefix:getReferencePrefix
}