var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reset-forgot-password-page"},[_c('b-overlay',{staticClass:"content",attrs:{"show":_vm.isLoading,"rounded":"sm"}},[_c('div',{staticClass:"sub-page-header",staticStyle:{"position":"sticky","top":"99px","background":"#6c757e","z-index":"99999999","padding-top":"15px","padding-bottom":"16px"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"top-header"},[_c('h4',{staticClass:"text-white"},[_vm._v("Change My Password")])])])])])]),_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(_vm.errorMessage)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),(_vm.successMessage)?_c('b-alert',{attrs:{"variant":"success","show":""}},[_vm._v(_vm._s(_vm.successMessage))]):_vm._e(),(!_vm.resetComplete)?_c('form',{attrs:{"id":"login-form"}},[_c('b-form-group',{attrs:{"id":"password-input-group","label":"New Password","label-for":"newPassword","description":""}},[_c('b-form-input',{class:{
                            'is-invalid': _vm.$v.newPassword.$dirty && _vm.$v.newPassword.$error,
                            'is-valid': _vm.$v.newPassword.$dirty && !_vm.$v.newPassword.$error
                        },attrs:{"id":"emailAddress","type":"password","placeholder":"","required":""},model:{value:(_vm.$v.newPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.newPassword, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.newPassword.$model"}})],1),_c('b-form-group',{attrs:{"id":"new-password-input-group","label":"Retype New Password","label-for":"retypeNewPassword","description":""}},[_c('b-form-input',{class:{
                            'is-invalid':_vm.$v.retypeNewPassword.$dirty && _vm.$v.retypeNewPassword.$error && _vm.retypeNewPassword !== _vm.newPassword,
                            'is-valid': _vm.$v.retypeNewPassword.$dirty && !_vm.$v.retypeNewPassword.$error && _vm.retypeNewPassword === _vm.newPassword
                        },attrs:{"id":"retypeNewPassword","type":"password","placeholder":"","required":""},model:{value:(_vm.$v.retypeNewPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.retypeNewPassword, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.retypeNewPassword.$model"}})],1),_c('div',{staticClass:"mb-3 d-flex gap-1"},[_c('b-button',{attrs:{"variant":"success","disabled":_vm.$v.newPassword.$invalid || _vm.$v.retypeNewPassword.$invalid || _vm.retypeNewPassword !== _vm.newPassword},on:{"click":function($event){return _vm.resetPassword()}}},[_vm._v(" Change Password ")]),_c('b-button',{attrs:{"to":{name: 'ForgotPasswordPage'},"variant":"link"}},[_vm._v(" Reset My Password ")])],1)],1):_vm._e(),(_vm.resetComplete)?_c('b-button',{staticClass:"btn-block",attrs:{"variant":"success","to":{name: 'RegisterLogin'}}},[_vm._v("Login")]):_vm._e()],1)])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }