<template>
    <div id="awaiting-cart-payment">
        <SubPageHeader title="Awaiting Payment"/>
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center">
                        <p>Thank you choosing us. This type of payment can take up to 24 hours to reflect. If you would like to speed things up please consider uploading your proof of payment below and we'll automatically process your order.</p>

                        <br>

                        <router-link :to="{name: 'UploadProofOfPayment'}" class="btn button rounded-pill button-black">Upload My Proof Of Payment
                            <span></span>
                            <span></span>
                            <span></span>
                        </router-link>
                        <SocialMediaBanner/>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import SubPageHeader from "@/components/General/SubPageHeader.vue";
import SocialMediaBanner from "@/components/General/SocialMediaBanner.vue";
import cartHelper from "@/helpers/cart.helper";

export default {
    name: "AwaitingPayment",
    components: {SocialMediaBanner, SubPageHeader},
    created() {
      this.$mixpanel.track_pageview({"page": "Awaiting Payment"});

        this.isLoading = true;
        cartHelper.clearCart().then(() => {
            this.isLoading = false;
        });
    },
    data() {
      return {
          selectedFile: null
      }
    },
    methods: {

    }
}
</script>

<style scoped>

</style>