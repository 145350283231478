<template>
<div class="my-account">
    <SubPageHeader title="My Account"/>

  <div class="page-content" v-if="user">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mb-5">
          <h1>Hello There {{user.first_name}}</h1>
          <em class="text-muted">What do you want to do today?</em>
        </div>
      </div>

        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-wrap gap-1">
              <router-link class="btn btn-outline-dark" :to="{name: 'ClientProducts', 'params': {'client_product_id': 1}}">My Products</router-link>
              <a href="mailto:support@buyisakonnect.co.za" class="btn btn-info">Chat To Someone</a>
              <router-link class="btn btn-outline-dark" :to="{name: 'MyProfile'}">My Profile</router-link>
<!--              <router-link class="btn btn-dark" :to="{name: 'BulkSignUpProductSelection'}">Bulk Sign Up</router-link>-->
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
import SubPageHeader from "@/components/General/SubPageHeader.vue";
import MyProfile from "@/views/Client/MyProfile.vue";
import buyissolutionsApiService from "@/services/buyissolutions.api.service";

export default {
  name: "MyAccount",
  mounted() {
    this.getLoggedInUser()
  },
  computed: {
    MyProfile() {
      return MyProfile
    }
  },
  components: {SubPageHeader},
  data() {
    return {
      user: null
    }
  },
  methods: {
    getLoggedInUser() {
      return buyissolutionsApiService.getLoggedInUser().then(response => {
        this.user = response.data.user;
      }).catch((result) => {
        console.log(result);
        // this.$router.push('/');
      });
    }
  }
}
</script>

<style scoped>

</style>