<template>
    <b-modal id="purchase-mobile-data-package"
             size="xl"
             title="Mobile Data For All"
             centered>
            <div class="row">
                <div class="col-md-8">
                    <b-overlay :show="isLoading" rounded="sm">
                        <b-tabs :v-model="$store.state.signup.stepper" pills>
                            <b-tab title="Mobile Number Check" @click="setTab(1)" :active="$store.state.signup.stepper===1">
                                <div class="content py-3">
                                    <b-alert show>We need your MTN prepaid mobile number in order for us to be able to add data.</b-alert>
                                    <form>
                                        <b-form>
                                            <b-form-group
                                                id="input-group-1"
                                                label="MTN Mobile Number"
                                                label-for="input-1"
                                                description=""
                                            >
                                                <b-form-input
                                                    :class="{
                                                        'is-invalid': $v.mobileSignUpData.msisdn.$dirty && $v.mobileSignUpData.msisdn.$error,
                                                        'is-valid': $v.mobileSignUpData.msisdn.$dirty && !$v.mobileSignUpData.msisdn.$error
                                                    }"
                                                    id="input-1"
                                                    v-model.trim="$v.mobileSignUpData.msisdn.$model"
                                                    type="text"
                                                    placeholder="27731234567"
                                                    required
                                                    :disabled="!selectedProduct"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-form>
                                    </form>
<!--                                    <pre>{{$v.mobileSignUpData.$invalid}}</pre>-->
                                </div>
                            </b-tab>

                            <b-tab title="Login" @click="setTab(2)" :active="$store.state.signup.stepper===2" :disabled="2 !== $store.state.signup.stepper">
                                <div class="content py-3">
                                    <LoginRegistrationForms/>
                                </div>
                            </b-tab>

                            <b-tab title="Checkout Summary" @click="setTab(3)"  :active="$store.state.signup.stepper===3" :disabled="3 !== $store.state.signup.stepper">
                                <div class="content py-3">
                                    <b-alert show variant="info">
                                        <h4 class="alert-heading">Almost There!</h4>
                                        <p>You about to enter the world of pure awesomeness😎, ☝🏾 here are a few things to note.</p>
                                        <ul>
                                            <li>After payment it will take a few minutes for us to set up your sim with our data, we will let you know once everything is ready.</li>
                                            <li>There a small setting on your phone that you need to change in order for the data to work but dont worry we'll guide you through this😊</li>
                                            <li>Cash payments take up to 24 hour to reflect on our system so to speed things up please follow these instructions on how to upload your proof of payment.</li>
                                        </ul>
                                        <hr>
                                        <p class="mb-0">
                                            Please let us know how we can improve <a href="">feedback@buyisasolutions.com</a>
                                        </p>
                                    </b-alert>

                                    <img src="@/assets/payment_options.png" class="img-fluid">
                                </div>
                            </b-tab>

                            <b-tab title="Make Payment"  @click="setTab(4)" :active="$store.state.signup.stepper===4" :disabled="4 !== $store.state.signup.stepper">
                                <div class="content py-3">
                                    <form name="form" id="x1" method="POST" action="https://paynow.netcash.co.za/site/paynow.aspx" target="_top"> <!---Form POST **--->
                                        <input type="text" name="M1" value="7XXX34c4-XXX-40X8-9f1d-7fbXXXX830d3"> <!--- YOUR PAY NOW SERVICE KEY GOES IN HERE --->
                                        <input type="text" name="M2" value="24ade73c-98cf-47b3-99be-cc7b867b3080"> <!--- SOFTWARE VENDOR KEY GOES IN HERE --->
                                        <input type="text" name="p2" value="ID:123"> <!---Unique ID for this / each transaction--->
                                        <input type="text" name="p3" value="Test / Demo goods"> <!---Description of goods being purchased--->
                                        <input type="text" name="p4" value="5.00"> <!---Amount to be settled / paid--->
                                        <input type="text" name="Budget" value="Y"> <!---Compulsory should be Y--->
                                        <input name="submit" type="submit" value="PAY"> <!---Submit button--->
                                    </form>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </b-overlay>
                </div>
                <div class="col-md-4">
                    <ProductItemInformationDisplayOnly :selected-product="selectedProduct"/>
                </div>
            </div>

        <template #modal-footer>
            <div class="w-100">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div class="sign-up-modal-buttons">
                        <b-button
                            variant="primary"
                            @click="$bvModal.hide('purchase-mobile-data-package')"
                        >
                            Nevermind
                        </b-button>
                        <b-button
                            :disabled="$v.mobileSignUpData.$invalid"
                            :class="{'disabled': $v.mobileSignUpData.$invalid}"
                            variant="success"
                            @click="processNextStep()"
                        >
                            Next Step
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
<!--        <pre>{{mobileSignUpData}}</pre>-->
<!--        {{selectedProduct}}-->
<!--        {{$v.mobileSignUpData.$invalid}}-->
<!--        {{selectedProduct}}-->
    </b-modal>
</template>

<script>
import ProductItemInformationDisplayOnly from "@/components/Products/ProductItemInformationDisplayOnly.vue";
import {minLength, required} from "vuelidate/lib/validators";
import LoginRegistrationForms from "@/components/Auth/LoginRegistrationForms.vue";
import BuyissolutionsApiService from "@/services/buyissolutions.api.service";

export default {
    name: "SignUpModal",
    components: {LoginRegistrationForms, ProductItemInformationDisplayOnly},
    props: [
        'product'
    ],
    mounted() {
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if (modalId === 'purchase-mobile-data-package') {
                this.$store.state.selectedProduct = null;
                this.$store.state.signup.stepper = 1;
            }
        });
    },
    created() {
    },
    data() {
        return {
            isLoading: false,
            tabIndex: 0,
            errorMessage: '',
            mobileSignUpData: {
                product: null,
                msisdn: null
            }
        }
    },
    watch: {
        // whenever question changes, this function will run
        selectedProduct(newSelectProduct, oldSelectProduct) {
            if (newSelectProduct !== oldSelectProduct) {
                this.mobileSignUpData.product = newSelectProduct;
            }
        }
    },
    computed: {
        selectedProduct() {
            return this.product
        }
    },
    methods: {
        setTab(index) {
            this.$store.state.signup.stepper = index
        },
        processNextStep() {
            switch (this.$store.state.signup.stepper) {
                case 1:
                    if (this.checkMsisdnAvailability()) {
                        this.$getItem('token').then(response => {
                            if (response) {

                                this.$store.state.signup.stepper = 3;
                                return;
                            }


                            this.$store.state.signup.stepper = 2;
                        })
                    }
                    break;
                case 2:
                    if (this.$store.state.signup.type === 'new_user') {
                        // register user in
                    }

                    if (this.$store.state.signup.type === 'existing_user') {
                        // log user in
                        this.isLoading = true;
                        BuyissolutionsApiService.loginToAccount(
                            this.$store.state.loginCred.username,
                            this.$store.state.loginCred.password
                        ).then(response => {
                            if (response.status === 200) {
                                this.$setItem('token', response.data.token).then(() => {
                                });
                            }

                            this.isLoading = false;
                        }).catch(error => {
                            if (error.response) {
                                // The request was made and the server responded with a status code
                                // that falls out of the range of 2xx

                                this.$bvModal.msgBoxOk(error.response.data.message, {
                                    title: 'Oops something is not right',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'danger',
                                    headerClass: 'p-2 border-bottom-0',
                                    footerClass: 'p-2 border-top-0',
                                    centered: true
                                });

                                this.isLoading = false;
                            } else if (error.request) {
                                // The request was made but no response was received
                                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                                // http.ClientRequest in node.js
                                this.isLoading = false;
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                this.isLoading = false;
                            }
                            this.isLoading = false;
                        })
                    }

                // update stepper
                    break;

                case 3:
                    // add to cart (api)
                    // show checkout summary
                    this.$store.state.signup.stepper = 4;
                    break;
                case 4:
                    // make payment
                    break;

                default:
                    // log to error
                    this.$bvModal.msgBoxOk('Something is went wrong on our side please try again later.', {
                        title: 'Oops Something is not right',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    });
                    break
            }

        },
        checkMsisdnAvailability() {
            this.$store.state.mobileSignUpData.product = this.mobileSignUpData.product;
            this.$store.state.mobileSignUpData.msisdn = this.mobileSignUpData.msisdn;

            this.validateSignUpData();

            return true;
        },
        validateSignUpData() {
            if (!this.mobileSignUpData.msisdn) {
                this.$bvModal.msgBoxOk('Mobile number is required', {
                    title: 'Oops Something Is Not Right',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                return;
            }
            if (!this.mobileSignUpData.product) {
                this.$bvModal.msgBoxOk('Mobile product is required', {
                    title: 'Oops Something Is Not Right',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
            }
        }
    },
    validations: {
        mobileSignUpData: {
            msisdn: {
                required,
                minLength: minLength(10)
            },
            product: {
                msisdn:required
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.sign-up-modal-buttons {
    display: flex;
    gap: .8em;
}
</style>