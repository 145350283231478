<template>
    <footer class="footer-style-1">
<!--        <section id="footer-wrap" class="footer_wrap">-->
<!--            <div class="container">-->
<!--                <div class="row row">-->
<!--                    <div class="mb-4 col-md-3">-->
<!--                        <div class=" o-hidden text-left">-->
<!--                            <div>-->
<!--                                <h4 class="card-title font-weight-bold">Buyisa Solutions</h4>-->
<!--                                <p>-->
<!--                                    We offer a range of affordable, high quality managed solutions and Internet connectivity to clients all over South Africa.-->
<!--                                </p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="mb-4 col-md-3">-->
<!--                        <div class=" o-hidden text-left">-->
<!--                            <div class=" ">-->
<!--                                <h4 class="card-title font-weight-bold">Our Services</h4>-->
<!--                                <div class="mb-5 lead">-->
<!--                                    <ul class="general list-inline">-->
<!--                                        <li class=""><a href="#">Prepaid Mobile Data</a></li>-->
<!--                                        <li class=""><a href="#">Mobile Monthly Data</a></li>-->
<!--                                        <li class=""><a href="#">Monthly to Monthly Airtime + Data</a></li>-->
<!--                                    </ul>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="mb-4 mb-lg-0 mb-md-0 col-md-3">-->
<!--                        <div class=" o-hidden text-left">-->
<!--                            <div>-->
<!--                                <h4 class="card-title font-weight-bold">Latest</h4>-->
<!--                                <div class="mb-5 lead">-->
<!--                                    <ul class="general">-->
<!--                                        <li><a href="#">Stepping into the world of the wise</a></li>-->
<!--                                        <li><a href="#">Setting up you app</a></li>-->
<!--                                    </ul>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="mb-lg-0 mb-md-0 col-md-3">-->
<!--                        <div class=" o-hidden text-left">-->
<!--                            <div>-->
<!--                                <h4 class="card-title font-weight-bold">Contact Info</h4>-->
<!--                                <div class="address-wrap mb-3">-->
<!--                                    <ul class="list-group list-unstyled">-->
<!--                                        <li>-->
<!--                                            <b-icon icon="pin-map-fill"/>-->
<!--                                            Linbro Park, Johannesburg-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </div>-->
<!--                                <div class="address-wrap mb-3">-->
<!--                                    <ul class="list-group list-unstyled">-->
<!--                                        <li>-->
<!--                                            <b-icon icon="envelope-fill"/>-->
<!--                                            <a href="mailto:support@buyisasolutions.com"> info@buyisasolutions.com</a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </div>-->
<!--                                <div class="address-wrap mb-3">-->
<!--                                    <ul class="list-group list-unstyled">-->
<!--                                        <li>-->
<!--                                            <b-icon icon="phone-fill"/>-->
<!--                                            <a href="tel:0731160847"> 0731160847</a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </div>-->
<!--                                <section class="social-wrap">-->

<!--                                    <div class="footer-social text-center text-lg-left ">-->
<!--                                        <ul class="list-unstyled justify-content-center justify-content-lg-start">-->
<!--                                            <li>-->
<!--                                                <a href="javascript:void(0);" style="visibility: visible; animation-name: fadeInUp;">-->
<!--                                                    <b-icon icon="facebook"/>-->
<!--                                                </a>-->
<!--                                            </li>-->
<!--                                            <li>-->
<!--                                                <a href="javascript:void(0);" style="visibility: visible; animation-name: fadeInUp;">-->
<!--                                                    <b-icon icon="twitter"/>-->
<!--                                                </a>-->
<!--                                            </li>-->
<!--                                            <li>-->
<!--                                                <a href="javascript:void(0);" style="visibility: visible; animation-name: fadeInUp;">-->
<!--                                                    <b-icon icon="instagram"/>-->
<!--                                                </a>-->
<!--                                            </li>-->
<!--                                            <li>-->
<!--                                                <a href="javascript:void(0);" style="visibility: visible; animation-name: fadeInUp;">-->
<!--                                                    <b-icon icon="linkedin"/>-->
<!--                                                </a>-->
<!--                                            </li>-->
<!--                                        </ul>-->
<!--                                    </div>-->
<!--                                </section>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </section>-->

        <div class="container">
            <div class="row align-items-center lower-footer">
                <!--Text-->
                <div class="col-lg-12 text-center">
                    <p class="company-about fadeIn">© 2023 <a href="http://www.buyisasolutions.com">www.buyisakonnect.co.za</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "MainFooter"
}
</script>

<style lang="scss" scoped>
@import "@/styles/partials/variables";

.footer-style-1 {
    background-color: #202020;
    padding: 1rem;
    color: #FFFFFF;
    font-size: 1rem;


    a {
        font-size: 1rem;
        color: $color-yellow;
        &:hover {
            color: #FFFFFF;
        }
    }
}

.company-about {
    font-size: 14px;
  padding:1rem;
    color: #ffffff;
}

.footer-social {
    .list-unstyled {
        display: flex;
        gap: 1em;
        font-size: 1.3rem;
    }
}

.lower-footer {
    //border-top: 0.01em solid #646464;
    //padding-top: 2em;
}
</style>