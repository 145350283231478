import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import PrepaidMobileData from "@/views/Products/PrepaidMobileData.vue";
import SingleProduct from "@/views/Products/SingleProduct.vue";
import ShoppingCart from "@/views/Cart/ShoppingCart.vue";
import MyAccount from "@/views/Client/MyAccount.vue";
import PaymentSuccessful from "@/views/Cart/PaymentSuccessful.vue";
import PaymentFailed from "@/views/Cart/PaymentFailed.vue";
import ContactView from "@/views/ContactView.vue";
import AwaitingPayment from "@/views/Cart/AwaitingPayment.vue";
import GeneralTermsAndConditions from "@/views/GeneralTermsAndConditions.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import ClientProductMakePayment from "@/views/ClientProduct/ClientProductMakePayment.vue";
import TopUpMobileData from "@/views/ClientProduct/Mobile/TopUpMobileData.vue";
import UploadProofOfPayment from "@/views/ClientProduct/UploadProofOfPayment.vue";
import ClientProducts from "@/views/ClientProduct/ClientProducts.vue";
import SingleClientProduct from "@/views/ClientProduct/SingleClientProduct.vue";
import MyProfile from "@/views/Client/MyProfile.vue";
import RegisterLoginView from "@/views/RegisterLoginView.vue";
import BulkSignUpProductSelection from "@/views/BulkSignUp/BulkSignUpProductSelection.vue";
import authTokenHelper from "@/helpers/authToken.helper";
import ClientProductsPage from "@/v4/pages/ClientProductsPage.vue";
import SingleClientProductPage from "@/v4/pages/SingleClientProductPage.vue";
import ClientProductTopUpPage from "@/v4/pages/ClientProductTopUpPage.vue";
import PayTransactionPage from "@/v4/pages/PayTransactionPage.vue";
import HelpCenterPage from "@/v4/pages/HelpCenterPage.vue";
import CancelClientProductPage from "@/v4/pages/CancelClientProductPage.vue";
import HomePage from "@/v4/pages/HomePage.vue";
import UploadProofOfPaymentPage from "@/v4/pages/UploadProofOfPaymentPage.vue";
import MyProfilePage from "@/v4/pages/MyProfilePage.vue";
import ForgotPasswordPage from "@/v4/pages/ForgotPasswordPage.vue";
import ResetPasswordPage from "@/v4/pages/ResetPasswordPage.vue";
import SignUpComplete from "@/views/Cart/SignUpComplete.vue";

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: HomeView
  // },
  {
    path: '/product/mobile/prepaid-data',
    name: 'PrepaidMobileData',
    component: PrepaidMobileData
  },
  {
    path: '/product/:product_id',
    name: 'SingleProduct',
    component: SingleProduct
  },
  {
    path: '/cart',
    name: 'ShoppingCart',
    component: ShoppingCart
  },
  {
    path: '/sign-up-complete/:order_reference',
    name: 'SignUpComplete',
    component: SignUpComplete
  },
  {
    path: '/payment-success',
    name: 'PaymentSuccessful',
    component: PaymentSuccessful
  },
  {
    path: '/payment-failed',
    name: 'PaymentFailed',
    component: PaymentFailed
  },
  {
    path: '/awaiting-payment',
    name: 'AwaitingPayment',
    component: AwaitingPayment
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: MyProfile,
    meta: {requiresAuth: true}
  },
  {
    path: '/dashboard',
    name: 'MyAccount',
    component: MyAccount,
    meta: {requiresAuth: true}
  },
  {
    path: '/my-products',
    name: 'ClientProducts',
    component: ClientProducts,
    meta: {requiresAuth: true}
  },
  {
    path: '/my-products/:client_product_id',
    name: 'SingleClientProduct',
    component: SingleClientProduct,
    meta: {requiresAuth: true}
  },
  {
    path: '/upload-proof-of-payment',
    name: 'UploadProofOfPayment',
    component: UploadProofOfPayment,
    meta: {requiresAuth: true}
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView,
    meta: {requiresAuth: true}
  },
  {
    path: '/client-product/:client_product_id/make-payment',
    name: 'ClientProductMakePayment',
    component: ClientProductMakePayment,
    meta: {requiresAuth: true}
  },
  {
    path: '/client-product/:client_product_id/top-me-up',
    name: 'TopUpMobileData',
    component: TopUpMobileData,
    meta: {requiresAuth: true}
  },
  {
    path: '/terms-and-conditions',
    name: 'GeneralTermsAndConditions',
    component: GeneralTermsAndConditions
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/',
    name: 'RegisterLogin',
    component: RegisterLoginView
  },
  {
    path: '/bulk/sign-up/product-selection',
    name: 'BulkSignUpProductSelection',
    component: BulkSignUpProductSelection,
    meta: {requiresAuth: true}
  },
  {
    path: '/v4/client-products',
    name: 'ClientProductsPage',
    component: ClientProductsPage,
    meta: {requiresAuth: true}
  },
  {
    path: '/v4/client-products/:client_product_id',
    name: 'SingleClientProductPage',
    component: SingleClientProductPage,
    meta: {requiresAuth: true}
  },
  {
    path: '/v4/client-products/:client_product_id/top-up',
    name: 'ClientProductTopUpPage',
    component: ClientProductTopUpPage,
    meta: {requiresAuth: true}
  },
  {
    path: '/v4/client-products/:client_product_id/cancel',
    name: 'CancelClientProductPage',
    component: CancelClientProductPage,
    meta: {requiresAuth: true}
  },
  {
    path: '/v4/client-products/:client_product_id/pay-transaction',
    name: 'PayTransactionPage',
    component: PayTransactionPage,
    meta: {requiresAuth: true}
  },
  {
    path: '/v4/help-center',
    name: 'HelpCenterPage',
    component: HelpCenterPage,
    meta: {requiresAuth: true}
  },
  {
    path: '/v4',
    name: 'HomePage',
    component: HomePage,
    meta: {requiresAuth: true}
  },
  {
    path: '/v4/upload-proof-of-payment',
    name: 'UploadProofOfPaymentPage',
    component: UploadProofOfPaymentPage,
    meta: {requiresAuth: true}
  },
  {
    path: '/v4/my-profile',
    name: 'MyProfilePage',
    component: MyProfilePage,
    meta: {requiresAuth: true}
  },
  {
    path: '/v4/forgot-password',
    name: 'ForgotPasswordPage',
    component: ForgotPasswordPage,
    meta: {requiresAuth: false}
  },
  {
    path: '/v4/reset-password/:uid',
    name: 'ResetPasswordPage',
    component: ResetPasswordPage,
    meta: {requiresAuth: false}
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  let token = authTokenHelper.getToken().then((response) => {
    return response;
  });

  let loggedIn = await token;

  if (loggedIn) {

    // let loggedInLiveCheckPromise = buyissolutionsApiService.getLoggedInUser().then((response) => {
    //   return response;
    // }).catch(() => {
    //   authTokenHelper.clearToken().then(() => {
    //     return null;
    //   })
    // });

    // let loggedInLiveCheck = await loggedInLiveCheckPromise;
  }

  if (loggedIn && window.location.pathname === "/") {
    window.location = '/v4';
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    // this.$mixpanel.reset();
    next('/');
  }

  next();
})

export default router
