<template>
  <div class="single-client">
    <div class="client-product">

      <SubPageHeader :title="ClientProduct.msisdn"/>

      <div class="page-content">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <ClientProductItem :client-product-id="getClientProductId"/>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <ClientProductDataLinks :client-product-id="getClientProductId"/>
            </div>
          </div>
<!--          <div class="row">-->
<!--            <div class="col-lg-12">-->
<!--              <ClientProductTransactionList :client-product-id="getClientProductId"/>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import SubPageHeader from "@/components/General/SubPageHeader.vue";
// import buyissolutionsApiService from "@/services/buyissolutions.api.service";
// import moment from  "moment"
import ClientProductItem from "@/components/ClientProduct/ClientProductItem.vue";
import ClientProductDataLinks from "@/components/ClientProduct/ClientProductDataLinks.vue";
import buyissolutionsApiService from "@/services/buyissolutions.api.service";

export default {
  components: {
    ClientProductDataLinks,
    ClientProductItem, SubPageHeader},
  computed: {
    getClientProductId() {
      return this.$route.params.client_product_id;
    }
  },
  mounted() {
    console.log('clientProductId', this.getClientProductId)
    this.getClientProduct();
  },
  data() {
    return {
      isLoading: true,
      ClientProduct: [],
      ClientTopUps: [],
      ClientTransactions: [],
    }
  },
  methods: {
    getClientProduct() {
      buyissolutionsApiService.getClientProduct(this.getClientProductId).then(result => {
        this.ClientProduct = result.data.client_product
      })
    },
  }
}

</script>

<style scoped>

</style>