<template>
  <div class="client-product">

    <SubPageHeader title="My Products"/>

    <div class="page-content">

      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb-4">
            <div class="d-flex gap-1">
<!--              <router-link class="btn btn-secondary" :to="{name: 'MyAccount'}">Back</router-link>-->
<!--              <router-link class="btn btn-outline-success disabled" to="#">Bulk Signup</router-link>-->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <ClientProductList :client-products="clientProducts"/>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="manage_client_product"
             size="xl"
             title="Manage Client Product"
             hide-footer
             centered>
      <b-overlay :show="isLoading" rounded="sm" class="content">
        <div class="row">
          <div class="col-md-8">
            <b-tabs content-class="mt-3">
              <b-tab title="Product" active>
                <div class="client-product-information" v-if="selectedClientProduct">
                  <StatusBadge v-if="selectedClientProduct" :status_id="selectedClientProduct.status.id"/>
                  <!--                                    <pre>{{selectedClientProduct}}</pre>-->
                  <h3>{{selectedClientProduct.friendly_name}}</h3>
                  <p><strong>Sign Up Date:</strong> {{selectedClientProduct.created_at | moment("dddd, MMMM Do YYYY") }}</p>
                  <p><strong>Type:</strong> {{selectedClientProduct.product.product_type.name }}</p>

                  <p class="my-5">No data usage information</p>
                </div>

                <div class="d-flex gap-1" v-if="selectedClientProduct">
                  <b-button variant="success" v-if="selectedClientProduct.status.id === 1" @click="topMeUp(selectedClientProduct)">Top Up</b-button>
                  <b-button variant="secondary" v-if="hasPendingTransactions" @click="mayPayment(selectedClientProduct)">
                    Make Payment
                  </b-button>
                  <b-button :to="{name: 'UploadProofOfPayment'}" variant="secondary" v-if="selectedClientProduct.status.id === 3">Upload Proof Of Payment</b-button>
                  <b-button variant="danger" v-if="selectedClientProduct.status.id === 1" @click="cancelProduct(clientProduct)">Cancel</b-button>
                </div>
              </b-tab>
              <b-tab title="Billing">
                <b-table class="small" small striped hover :items="clientProductsTransactions"></b-table>
              </b-tab>
            </b-tabs>
          </div>

          <div class="col-md-4" v-if="selectedClientProduct">
            <ProductItemInformationDisplayOnly v-if="selectedClientProduct" :selected-product="selectedClientProduct.product"/>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import buyissolutionsApiService from "@/services/buyissolutions.api.service";
import ProductItemInformationDisplayOnly from "@/components/Products/ProductItemInformationDisplayOnly.vue";
import StatusBadge from "@/components/General/StatusBadge.vue";
import moment from  "moment"
import ClientProductList from "@/components/ClientProduct/ClientProductList.vue";
import SubPageHeader from "@/components/General/SubPageHeader.vue";

export default {
  name: "ClientProducts",
  components: {SubPageHeader, ClientProductList, StatusBadge, ProductItemInformationDisplayOnly},
  data() {
    return {
      isLoading: false,
      clientProducts: [],
      selectedClientProduct: null,
      clientProductsTransactions: [],
      hasPendingTransactions: null
    }
  },
  methods: {
    mayPayment(clientProduct) {
      this.isLoading = true;
      this.$bvModal.msgBoxConfirm('Please use this feature only if you where not able to pay make during sign up.', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Okay',
        cancelTitle: 'Nevermind',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(value => {
            if (value === true) {
              this.$router.push({
                'name': 'ClientProductMakePayment',
                params: {'client_product_id': clientProduct.id}
              });
              // buyissolutionsApiService.cancelClientProduct(clientProduct).then(response => {
              //     if (response.status === 200) {
              //         // this.getClientProducts();
              //
              //         this.$bvModal.msgBoxOk(response.message, {
              //             title: 'Oops',
              //             size: 'sm',
              //             buttonSize: 'sm',
              //             okVariant: 'success',
              //             headerClass: 'p-2 border-bottom-0',
              //             footerClass: 'p-2 border-top-0',
              //             centered: true
              //         });
              //     }
              // })
              //     .finally(() => {
              //         this.isLoading = false;
              //     });
            }
          })
          .catch()
          .finally(() => {
            this.isLoading = false;
          });
    },
    uploadProofOfPayment() {
    },
    cancelProduct() {
      this.isLoading = true;
      this.$bvModal.msgBoxConfirm('Are you sure you want cancel this product? ', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(value => {
            if (value === true) {
              // buyissolutionsApiService.cancelClientProduct(clientProduct).then(response => {
              //     if (response.status === 200) {
              //         this.getClientProducts();
              //
              //         this.$bvModal.msgBoxOk(response.message, {
              //             title: 'Oops',
              //             size: 'sm',
              //             buttonSize: 'sm',
              //             okVariant: 'success',
              //             headerClass: 'p-2 border-bottom-0',
              //             footerClass: 'p-2 border-top-0',
              //             centered: true
              //         });
              //     }
              // })
              // .finally(() => {
              //     this.isLoading = false;
              // });
            }
          })
          .catch()
          .finally(() => {
            this.isLoading = false;
          });
    },
    viewClientProduct(clientProduct) {
      this.isLoading = true;
      this.$bvModal.show('manage_client_product');
      this.selectedClientProduct = null;
      this.clientProductsTransactions = [];
      buyissolutionsApiService.getClientProduct(clientProduct.id).then(response => {
        if (response.status === 200) {
          this.selectedClientProduct = response.data.client_product
          buyissolutionsApiService.getTransactionsByClientProduct(clientProduct.id).then(response => {
            response.data.transactions.forEach((value) => {
              this.clientProductsTransactions.push({
                description: value.description,
                type: value.transaction_type.name,
                amount: value.amount,
                // payment_method: (value.payment_method !== null) ? value.payment_method.name : '',
                reference: value.reference,
                created_at: moment(value.created_at).format('YYYY-MM-DD'),

              });
            });
          });
        }
      }).finally(() => {
        this.isLoading = false;
      })

      buyissolutionsApiService.getUnprocessedTransactionsByClientProduct(clientProduct.id).then(response => {
        if (response.status === 200) {
          this.hasPendingTransactions = response.data.transactions.length > 0;
        }
      })
    },
    topMeUp(clientProduct) {
      this.$router.push({name: 'TopUpMobileData', params: {'client_product_id': clientProduct.id}});
    }
  }
}
</script>

<style scoped lang="scss">
.buttons {
  display: flex;
  gap: .3em;
}
</style>