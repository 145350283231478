<template>
  <div class="client-product-list">
    <b-overlay :show="isLoading" rounded="sm" class="content">
      <b-list-group>
        <b-list-group-item
            v-for="clientProduct in clientProducts" :key="clientProduct.id"
            :to="{name:'SingleClientProduct', params: {'client_product_id': clientProduct.id}}"
            class="flex-column align-items-start"
        >
          <div class="d-flex w-100 justify-content-between align-items-center">
            <div>
              <h5 class="mb-1">{{clientProduct.friendly_name}}</h5>
              <small class="text-muted">{{clientProduct.status.name}}</small>
            </div>
            <router-link :to="{name:'SingleClientProduct', params: {'client_product_id': clientProduct.id}}">View</router-link>
<!--            <small>{{clientProduct.product.sell_price | currency('R')}} {{clientProduct.product.bill_occurrence.name}}</small>-->
          </div>

          <!--                    <small v-for="cart_item_requirement in cartItem.cart_item_requirements"-->
          <!--                           :key="cart_item_requirement.value">-->
          <!--                        {{cart_item_requirement.detail_type.name}}: {{cart_item_requirement.value}}-->
          <!--                    </small>-->
        </b-list-group-item>
      </b-list-group>
      <b-alert variant="info" show v-if="clientProducts.length === 0"><em>No products linked to account found.</em></b-alert>
    </b-overlay>
  </div>
</template >

<script>

import buyissolutionsApiService from "@/services/buyissolutions.api.service";

export default {
  props: ['clientProductId'],
  mounted() {
    this.getClientProducts();
  },
  data() {
    return {
      isLoading: true,
      clientProducts: [],
    }
  },
  methods: {
    getClientProducts() {
      this.isLoading = true;
      buyissolutionsApiService.getClientProducts().then(response => {
        this.clientProducts = response.data.client_products;
      }).catch(({response}) => {
        this.$bvModal.msgBoxOk(response.data.message, {
          title: 'Oops something is wrong',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        });
      }).finally(() => {
        this.isLoading = false;
      });
    },
  }
}

</script>

<style scoped>

</style>