<template>
    <section class="section" id="contact-sec">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-7">
                    <h4 class="heading text-center text-lg-left">GET IN TOUCH</h4>
                    <form class="row contact-form wow fadeInLeft" id="contact-form-data" style="visibility: visible; animation-name: fadeInLeft;">
                        <div class="col-sm-12" id="result"></div>
                        <div class="col-12 col-md-5">
                            <input type="text" name="userName" placeholder="Your Name" class="form-control">
                            <input type="email" name="userEmail" placeholder="Email Address *" class="form-control">
                            <input type="text" name="userSubject" placeholder="Subject" class="form-control">
                        </div>
                        <div class="col-12 col-md-7">
                            <textarea class="form-control" name="userMessage" rows="6" placeholder="Your Message"></textarea>
                        </div>
                        <div class="col-12 text-lg-right text-center">
                            <a href="" class="btn button button-black rounded-pill">Send Message
                                <span></span>
                                <span></span>
                                <span></span>
                            </a>
                        </div>
                    </form>
                </div>
                <div class="col-12 col-lg-5 text-center text-lg-left position-relative">
                    <div class="contact-details wow fadeInRight">
                        <h4 class="heading">OUR LOCATION</h4>
                        <p class="text">
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered .
                        </p>
                        <ul class="text-left">
                            <li><i class="las la-map-marker addr"></i>Linbro Park, Johannesburg</li>
                            <li><i class="las la-phone-volume phone"></i>
                                <span><a href="tel:0731160847">0731160847</a></span>
                            </li>
                            <li><i class="las la-paper-plane email"></i><a href="mailto:info@buyisasolutions.com">info@buyisasolutions.com</a></li>
                            <li><i class="las la-paper-plane email"></i><a href="mailto:support@buyisasolutions.com">support@buyisasolutions.com</a></li>
                        </ul>
                    </div>
                    <img src="images/background-pattern.png" class="contact-background" alt="contact">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "ContactFormSection"
}
</script>

<style lang="scss" scoped>


.contact-background {
  position: absolute;
  right: 0;
  top: -35px;
  z-index: -1;
}

.contact-form input {
  color: #8799a3;
  font-size: 16px;
  height: 50px;
  padding: 25px;
}

.contact-form .form-control {
  border-radius: 0;
  border: solid 2px #eceef2;
}

.contact-form textarea {
  padding: 13px;
  padding-left: 25px;
  height: 202px;
  color: #8799a3;
  font-size: 16px;
}
</style>