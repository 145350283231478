<template>
    <div id="single-client-product-page" class="page">

        <b-overlay :show="isLoading" rounded="sm" class="content">

          <div class="sub-page-header" style="
              position: sticky;
              top: 99px;
              background: #6c757e;
              z-index: 99999999;
              padding-top: 15px;
              padding-bottom: 16px;
          ">

            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="top-header">
                    <b-button pill :to="{'name': 'ClientProductsPage'}" variant="link">
                      <b-icon icon="arrow-left-circle" class="text-white" scale="2"></b-icon>
                    </b-button>

                    <h4 class="text-white">Product</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="page-content">
            <div class="container">

              <div class="row">
                <div class="col-md-12">
                  <small v-if="client_product.product.name">{{client_product.product.name}}</small>
                  <h5 class="d-flex justify-content-between">
                    {{ client_product.msisdn }}

<!--                    {{client_product.status}}-->
                    <span class="badge badge-danger" v-if="client_product.status.id == 6">Cancelled</span>
                    <span class="badge badge-success" v-if="client_product.status.id == 1">Active</span>
                    <span class="badge badge-warning" v-if="client_product.status.id == 14">Capped</span>
                    <span class="badge badge-info" v-if="client_product.status.id == 4">Pending</span>
                    <span class="badge badge-info" v-if="[3, 2, ].includes(client_product.status.id)">{{client_product.status.name}}</span>
                  </h5>
                  <data-usage-information-card class="mb-5"
                                               v-if="data_usage_information"
                                               :allocated="data_usage_information.allocated"
                                               :used="data_usage_information.used"
                                               :used_percentage="data_usage_information.used_percentage"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <router-link :to="{name:'ClientProductTopUpPage', params: {'client_product_id': client_product.id}}">
                    <b-card text-variant="light">
                      <b-card-title class="text-center">
                        Top up my data
                      </b-card-title>
                    </b-card>
                  </router-link>
                </div>
                <div class="col-sm-12 col-md-6">
                  <router-link :to="{name:'PayTransactionPage', params: {'client_product_id': client_product.id}}">
                    <b-card text-variant="light">
                      <b-card-title class="text-center">
                        Pay Now
                      </b-card-title>
                    </b-card>
                  </router-link>
                </div>
                <div class="col-sm-12 col-md-6">
                  <router-link :to="{name:'CancelClientProductPage', params: {'client_product_id': client_product.id}}">
                    <b-card text-variant="light">
                      <b-card-title class="text-center">
                        Cancel Product
                      </b-card-title>
                    </b-card>
                  </router-link>
                </div>
                <div class="col-sm-12 col-md-6">
                  <router-link :to="{name:'HelpCenterPage', params: {'client_product_id': client_product.id}}">
                    <b-card text-variant="light">
                      <b-card-title class="text-center">
                        Help
                      </b-card-title>
                    </b-card>
                  </router-link>
                </div>
              </div>
            </div>
          </div>


        </b-overlay>
    </div>
</template>

<script>
import buyissolutionsApiService from "@/services/buyissolutions.api.service";
import DataUsageInformationCard from "@/v4/components/DataUsageInformationCard.vue";
import ClientProductTopUpPage from "@/v4/pages/ClientProductTopUpPage.vue";

export default {
  name: "SingleClientProductPage",
  components: {DataUsageInformationCard},
  computed: {
    ClientProductTopUpPage() {
      return ClientProductTopUpPage
    },
    getClientProductId() {
      return this.$route.params.client_product_id;
    }
  },
  created () {
    this.$mixpanel.track_pageview({"page": "Single Client Product Page"});
  },
  mounted() {
    console.log('clientProductId', this.getClientProductId)
    this.getClientProduct();
  },
  data() {
    return {
      isLoading: true,
      client_product: [],
      data_usage_information: null,
      ClientTopUps: [],
      ClientTransactions: [],
    }
  },
  methods: {
    getClientProduct() {
      this.isLoading = true;
      buyissolutionsApiService.getClientProduct(this.getClientProductId).then(result => {
        this.client_product = result.data.client_product
        this.data_usage_information = result.data.data_usage_information
      }).finally(() => {
        this.isLoading = false
      })
    },
  }
}
</script>

<style scoped>

</style>