<template>
  <div class="register-login-page">
<!--    <SubPageHeader title="Buyisa Konnect Helps Everyone Connect By Providing Affordable Data"/>-->
    <div class="container">
      <div class="row">
        <!--          <div class="col-sm-6">-->
        <!--            <h1>Buyisa Konnect</h1>-->
        <!--            <p>Experience data liberation with Buyisa Konnect!-->

        <!--              We bring you affordable data solutions that empower individuals in South Africa. Stay connected, productive, and inspired as you conquer your ambitions with our reliable and budget-friendly data plans.-->

        <!--              Join the Buyisa Konnect community and unlock a world of limitless possibilities at your fingertips</p>-->
        <!--          </div>-->
        <div class="col-sm-12">
          <LoginRegistrationForms/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SubPageHeader from "@/components/General/SubPageHeader.vue";
import LoginRegistrationForms from "@/components/Auth/LoginRegistrationForms.vue";

export default {
  name: "RegisterLoginView",
  components: {LoginRegistrationForms},
  mounted() {
    this.$store.state.signup.type = 'existing_user'
  },
  created () {
    this.$mixpanel.track_pageview({"page": "Registration Login Page"});

    this.$mixpanel.reset();
  },

}
</script>

<style scoped>

</style>