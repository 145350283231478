<template>
  <div class="page-content personal-details">
      <b-overlay :show="isLoading" rounded="sm" class="content">
          <b-alert variant="danger" show v-if="errorMessage">{{errorMessage}}</b-alert>

          <b-alert variant="warning" show  v-if="!$v.profileForm.contact_number.$model">Please provide us mobile number that we can send you notifications via WhatsApp and SMS</b-alert>
          <form id="personal-details-form">
              <b-form-group
                  id="first_name-input-group"
                  label="First Name"
                  label-for="first_name"
                  description=""
              >
                  <b-form-input
                      :class="{
                          'is-invalid': $v.profileForm.first_name.$dirty && $v.profileForm.first_name.$error,
                          'is-valid': $v.profileForm.first_name.$dirty && !$v.profileForm.first_name.$error
                      }"
                      id="first_name"
                      v-model.trim="$v.profileForm.first_name.$model"
                      type="text"
                      placeholder=""
                      required
                  ></b-form-input>
              </b-form-group>

              <b-form-group
                  id="first_name-input-group"
                  label="Last Name"
                  label-for="last_name"
                  description=""
              >
                  <b-form-input
                      :class="{
                          'is-invalid': $v.profileForm.last_name.$dirty && $v.profileForm.last_name.$error,
                          'is-valid': $v.profileForm.last_name.$dirty && !$v.profileForm.last_name.$error
                      }"
                      id="last_name"
                      v-model.trim="$v.profileForm.last_name.$model"
                      type="text"
                      placeholder=""
                      required
                  ></b-form-input>
              </b-form-group>

              <b-form-group
                  id="email-input-group"
                  label="Email Address"
                  label-for="email"
                  description=""
              >
                  <b-form-input
                      :class="{
                          'is-invalid': $v.profileForm.email.$dirty && $v.profileForm.email.$error,
                          'is-valid': $v.profileForm.email.$dirty && !$v.profileForm.email.$error
                      }"
                      id="email"
                      v-model.trim="$v.profileForm.email.$model"
                      type="text"
                      placeholder=""
                      required
                  ></b-form-input>
              </b-form-group>

            <b-form-group
                id="contact-input-group"
                label="Contact Number"
                label-for="contact"
                description="We use this number to send you notifications via WhatsApp and email."
            >
              <b-form-input
                  :class="{
                          'is-invalid': $v.profileForm.contact_number.$dirty && $v.profileForm.contact_number.$error,
                          'is-valid': $v.profileForm.contact_number.$dirty && !$v.profileForm.contact_number.$error
                      }"
                  id="contact"
                  v-model.trim="$v.profileForm.contact_number.$model"
                  type="text"
                  placeholder=""
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-checkbox class="mb-3" v-model="passwordChange" switch>Update Password</b-form-checkbox>

            <div v-if="passwordChange">
              <b-form-group
                  id="password-input-group"
                  label="Password"
                  label-for="password"
                  description=""
              >
                <b-form-input
                    :class="{
                          'is-invalid': $v.profileForm.password.$dirty && $v.profileForm.password.$error,
                          'is-valid': $v.profileForm.password.$dirty && !$v.profileForm.password.$error
                      }"
                    id="password"
                    v-model.trim="$v.profileForm.password.$model"
                    type="password"
                    placeholder=""
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                  id="retype-password-input-group"
                  label="Retype Password"
                  label-for="retype_password"
                  description=""
              >
                <b-form-input
                    :class="{
                          'is-invalid': $v.profileForm.retype_password.$dirty && $v.profileForm.retype_password.$error,
                          'is-valid': $v.profileForm.retype_password.$dirty && !$v.profileForm.retype_password.$error
                      }"
                    id="retype_password"
                    v-model.trim="$v.profileForm.retype_password.$model"
                    type="password"
                    placeholder=""
                    required
                ></b-form-input>
              </b-form-group>
            </div>

              <b-row class="mb-3">
                <b-col lg="6" class="pb-2">
                  <b-button block variant="secondary" @click="$router.go(-1)">Back</b-button>
                </b-col>
                <b-col lg="6" class="pb-2">
                  <b-button block variant="success" @click="updateProfile()" :disabled="$v.profileForm.$invalid" >
                    Save Profile
                  </b-button>
                </b-col>
              </b-row>
          </form>
      </b-overlay>
  </div>
</template>

<script>
import { required, sameAs, numeric, minLength, maxLength} from "vuelidate/lib/validators";
import buyissolutionsApiService from "@/services/buyissolutions.api.service";

export default {
    name: "PersonalDetails",
    mounted() {
        this.isLoading = true;
        buyissolutionsApiService.getLoggedInUser().then(response => {
            this.profileForm = response.data.user;
        }).catch(() => {
        }).finally(() => {
            this.isLoading = false;
        });
    },
    computed: {
    },
    created() {

    },
    data() {
        return {
            isLoading: false,
          passwordChange: false,
            errorMessage: null,
            profileForm: {}
        }
    },
    methods: {
        updateProfile() {
          this.$mixpanel.track('post_update_profile', this.profileForm);

            this.isLoading = true;
            this.errorMessage = null;
            buyissolutionsApiService.updateProfile(this.profileForm).then(response => {
                if (response.status === 200) {
                    this.$bvModal.msgBoxOk('Profile has been successfully updated', {
                        title: 'Success',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    });

                    this.profileForm.password = null;
                    this.profileForm.retype_password = null;
                }
                this.isLoading = false;
            })
            .catch(({response}) => {
                this.errorMessage = response.data.message
            })
            .finally(() => {
                this.isLoading = false;
            });
        }
    },
    validations: {
        profileForm: {
            first_name: {
                required,
            },
            last_name: {
                required,
            },
            contact_number: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(12),
                numeric
            },
            password: {
                // required: requiredUnless('isOptional'),
                // minlength: minLength(6),
                // containsUppercase: function(value) {
                //     return !/[A-Z]/.test(value)
                // },
                // containsLowercase: function(value) {
                //     return !/[a-z]/.test(value)
                // },
                // containsNumber: function(value) {
                //     return !/[0-9]/.test(value)
                // },
                // containsSpecial: function(value) {
                //     return !/[#?!@$%^&*-]/.test(value)
                // }
            },
            retype_password: {
                sameAsPassword: sameAs('password')
            },
            email: {
                msisdn:required
            }
        },
    }
}
</script>

<style scoped>

</style>