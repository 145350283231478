<template>
  <div class="bulk-sign-up-product-selection">
    <SubPageHeader title="Bulk Sign Up Products"/>

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <p>For the process of bulk document signing, please follow these steps:</p>
            <ul>
              <li>Download a CSV file, and populate it with the required fields, which include "first_name," "last_name," and "mobile_number."</li>

              <li>Proceed to upload the completed CSV file.</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <b-overlay :show="isLoading" rounded="sm" class="content">
              <b-alert variant="danger" show v-if="errorMessage">{{errorMessage}}</b-alert>
              <b-alert variant="success" show v-if="successMessage">{{successMessage}}</b-alert>
              <form id="bulk-signup-product-selection-form">
                <b-form-group
                    id="product-select-input-group"
                    label="Product Type"
                    label-for="product_type"
                    description=""
                >
                  <b-form-select
                      id="product_type"
                      type="text"
                      placeholder=""
                      required
                      v-model="selectedProductType"
                      :options="productTypes"
                      value-field="id"
                      text-field="name"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                    v-if="selectedProductType"
                    id="product-select-input-group"
                    label="Product"
                    label-for="product"
                    description=""
                >
                  <b-form-select
                      id="product"
                      type="text"
                      placeholder=""
                      required
                      @change="filterProductSelection(selectedProductType)"
                      v-model="selectedProduct"
                      :options="filteredProductOptions"
                      value-field="id"
                      text-field="name"
                  ></b-form-select>
                </b-form-group>

                <b-form-file
                    v-if="selectedProduct"
                    v-model="selectedFile"
                    :state="Boolean(selectedFile)"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept="text/csv"
                ></b-form-file>
<!--                <b-button variant="secondary" @click="$router.go(-1)">Back</b-button>-->

                <b-button variant="success" @click="uploadBulkSignUp()" v-if="selectedProduct && selectedFile">
                  Submit
                </b-button>
              </form>
            </b-overlay>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SubPageHeader from "@/components/General/SubPageHeader.vue";
import buyissolutionsApiService from "@/services/buyissolutions.api.service";

export default {
  name: "BulkSignUpProductSelection",
  components: {SubPageHeader},
  data() {
    return {
      isLoading: false,
      errorMessage: null,
      successMessage: null,
      profileForm: {},
      file1: null,
      productTypes: [
        {id: null, name: '-- Select a product type --'},
        {id: 1, name: 'Monthly Mobile Data'},
        {id: 2, name: 'Prepaid Mobile Data'}
      ],
      productOptions: [
        {id: null, product_type_id:  null, name: '-- Select a products --'},
        {id: 5, product_type_id: 1, name: '1GB Mobile Data'},
        {id: 13, product_type_id: 1, name: '2GB Mobile Data'},
        {id: 6, product_type_id: 1, name: '5GB Mobile Data'},
        {id: 7, product_type_id: 1, name: '10GB Mobile Data'},
        {id: 8, product_type_id: 1, name: '20GB Mobile Data'},
      ],
      filteredProductOptions: [],
      selectedProductType: null,
      selectedProduct: null,
      selectedFile: null,
    }
  },
  methods: {
    filterProductSelection(selectedProductType) {
      console.log('selectedProductType', selectedProductType)
      if (!selectedProductType) {
        return this.filteredProductOptions = [];
      }
      this.filteredProductOptions = this.productOptions.filter((value) => {
        console.log(value)
        return value.product_type_id === selectedProductType;
      })
    },
    uploadBulkSignUp() {
      this.isLoading = true
      const fd = new FormData();
      fd.append('bulk_signup_file', this.selectedFile, this.selectedFile.name);
      fd.append('product_type_id', this.selectedProductType);
      fd.append('product_id', this.selectedProduct);

      this.successMessage = null;
      this.errorMessage = null;
      buyissolutionsApiService.initiateBulkSignUp(fd).then((response) => {
        this.isLoading = false;
        if (response.status === 200) {
          this.successMessage = response.data.message;

          this.selectedFile = null;
          this.selectedProductType = null;
          this.selectedProduct = null;

        } else {
          this.errorMessage = 'Could not process this right, please contact support.'
        }
      })
    }
  },
  watch: {
    selectedProductType(newValue, oldValue) {
      this.selectedProduct = null;

      if (oldValue === newValue) {
        return;
      }

      this.filterProductSelection(newValue)
    }
  },
}
</script>

<style scoped>

</style>