<template>
  <div class="login-registration-forms">
      <LoginForm v-if="$store.state.signup.type === 'existing_user'"/>
      <RegistrationForm v-if="$store.state.signup.type === 'new_user'"/>
      <ResetPassword v-if="$store.state.signup.type === 'forgot_password'"/>
  </div>
</template>

<script>
import LoginForm from "@/components/Auth/LoginForm.vue";
import RegistrationForm from "@/components/Auth/RegistrationForm.vue";
import ResetPassword from "@/components/Auth/ResetPassword.vue";

export default {
    name: "LoginRegistrationForms",
    components: {ResetPassword, RegistrationForm, LoginForm},
    created() {
      this.$mixpanel.track("Sign Up Modal Opened");
    },
  data() {
        return {
        }
    },
    methods: {
    },
}
</script>

<style scoped>

</style>