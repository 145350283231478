<template>
  <div class="reset-forgot-password-page">
    <b-overlay :show="isLoading" rounded="sm" class="content">
      <div class="sub-page-header" style="
                position: sticky;
                top: 99px;
                background: #6c757e;
                z-index: 99999999;
                padding-top: 15px;
                padding-bottom: 16px;
            ">

        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="top-header">
                <h4 class="text-white">Change My Password</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <b-alert variant="danger" show v-if="errorMessage">{{errorMessage}}</b-alert>
              <b-alert variant="success" show v-if="successMessage">{{successMessage}}</b-alert>
              <form id="login-form" v-if="!resetComplete">
                <b-form-group
                    id="password-input-group"
                    label="New Password"
                    label-for="newPassword"
                    description=""
                >
                  <b-form-input
                      :class="{
                              'is-invalid': $v.newPassword.$dirty && $v.newPassword.$error,
                              'is-valid': $v.newPassword.$dirty && !$v.newPassword.$error
                          }"
                      id="emailAddress"
                      v-model.trim="$v.newPassword.$model"
                      type="password"
                      placeholder=""
                      required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="new-password-input-group"
                    label="Retype New Password"
                    label-for="retypeNewPassword"
                    description=""
                >
                  <b-form-input
                      :class="{
                              'is-invalid':$v.retypeNewPassword.$dirty && $v.retypeNewPassword.$error && retypeNewPassword !== newPassword,
                              'is-valid': $v.retypeNewPassword.$dirty && !$v.retypeNewPassword.$error && retypeNewPassword === newPassword
                          }"
                      id="retypeNewPassword"
                      v-model.trim="$v.retypeNewPassword.$model"
                      type="password"
                      placeholder=""
                      required
                  ></b-form-input>
                </b-form-group>

                <div class="mb-3 d-flex gap-1">
                  <b-button variant="success" @click="resetPassword()"
                            :disabled="$v.newPassword.$invalid || $v.retypeNewPassword.$invalid || retypeNewPassword !== newPassword">
                    Change Password
                  </b-button>
                  <b-button :to="{name: 'ForgotPasswordPage'}" variant="link">
                    Reset My Password
                  </b-button>
                </div>
              </form>

              <b-button v-if="resetComplete" variant="success" :to="{name: 'RegisterLogin'}" class="btn-block">Login</b-button>

            </div>
          </div>
        </div>
      </div>

    </b-overlay>
  </div>
</template>

<script>
import buyissolutionsApiService from "@/services/buyissolutions.api.service";
import {minLength, required} from "vuelidate/lib/validators";

export default {
  name: "ResetPasswordPage",
  computed: {
    getPasswordUid() {
      return this.$route.params.uid;
    }
  },
  created () {
    this.$mixpanel.track_pageview({"page": "Reset Password Page"});
  },
  mounted() {
    buyissolutionsApiService.getResetPassword(this.$route.params.uid).then((response) => {
      console.log(response);
    }).catch(error => {
      this.errorMessage = error.response.data.message;
    }).finally(() => {
      this.isLoading = false;
    });
  },
  data() {
    return {
      isLoading: false,
      resetComplete: false,
      errorMessage: null,
      successMessage: null,
      newPassword: null,
      retypeNewPassword: null
    }
  },
  methods: {
    resetPassword() {
      if (!this.newPassword) {
        return;
      }

      this.$mixpanel.track('post_update_password', {
        new_password: '<REDUCTED>',
        uid: this.$route.params.uid
      });

      this.isLoading = true;
      this.errorMessage = null;
      this.successMessage = null;
      buyissolutionsApiService.postUpdatePassword(this.newPassword, this.$route.params.uid).then((result) => {
        if (result.status === 200) {
          this.successMessage = result.data.message;
          this.newPassword = null;
          this.retypeNewPassword = null;
          this.resetComplete = true;
        }
      }).catch(error => {
        this.errorMessage = error.response.data.message;
      }).finally(() => {
        this.isLoading = false;
      });

    }
  },
  validations: {
    newPassword: {
      required,
      minLength: minLength(8)
    },
    retypeNewPassword: {
      required,
      minLength: minLength(8)
    },
  }
}
</script>

<style scoped>

</style>