<template>
  <div class="product-list">
      <div class="row">
          <div class="col-sm-12 col-md-4" v-for="product in products" :key="product.id">
              <ProductItem class="price-list" :product="product"/>
          </div>

          <SignUpModal :product="$store.state.selectedProduct"/>
      </div>
  </div>
</template>

<script>
import ProductItem from "@/components/Products/ProductItem.vue";
import SignUpModal from "@/components/SignUp/SignUpModal.vue";

export default {
    name: "ProductList",
    components: {SignUpModal, ProductItem},
    props: ['products'],
    data() {
        return {
            isLoading: false,
            selectedProduct: {},
            showHideModal: false
        }
    },
    methods: {
    }
}
</script>

<style scoped>
.product-list {
    min-height: 20em;
    background: transparent;
    font-size: 14px;
    padding: 25px 0;
    display: inline-block;
}
</style>