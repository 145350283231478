<template>
<div class="my-account">
<!--    <SubPageHeader title="My Profile"/>-->

    <div class="container">
        <div class="row">
          <div class="col-md-12">
            <PersonalDetails/>
          </div>
        </div>
    </div>
</div>
</template>

<script>
// import SubPageHeader from "@/components/General/SubPageHeader.vue";
import PersonalDetails from "@/components/Profile/PersonalDetails.vue";

export default {
    name: "MyAccount",
    components: {PersonalDetails}
}
</script>

<style scoped>

</style>