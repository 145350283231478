<template>
    <div class="buyisa-solutions-buyisa-solutions">
        <SubPageHeader title="Buyisa Solutions Terms And Conditions"/>
        <b-overlay :show="isLoading" rounded="sm" class="content">
            <section class="section">
                <div class="container">
                    <div class="row">

                        <div class="col-12">
                            <h3><strong>SCOPE OF AGREEMENT</strong></h3>
                            <p>Buyisa Solutions provides the service to the user on the terms and conditions set out below.</p>
                            <p>By entering the user’s name and associated details into the required space to create a profile on the Buyisa Solutions user portal and by logging into the Buyisa Solutions Mobile data/ Fixed LTE account the user (or his/her/its representative) indicates that the user irrevocably accepts these terms and conditions. Please pay special attention to the terms below which are in bold.</p>

                            <h3><strong>CONSENT</strong></h3>
                            <p>It is expressly recorded that by using the ‘service’ the ‘user’ consents to Buyisa Solutions using their personal data and associated information as outlined and defined in the Buyisa Solutions Privacy policy&nbsp;<a href="https://buyisasolutions.com/privacy/">https://buyisasolutions.com/privacy/</a>.</p>
                            <p>The ‘user’ consents to receiving marketing material by means of email from Buyisa Solutions. Should a ‘user’ wish to no longer receive said marketing material they may request to be removed from the email database by emailing:&nbsp;<a href="about:blank">admin@buyisasolutions.com</a> or by unsubscribing on the email itself.</p>
                            <p>The ‘user’ consents to Buyisa Solutions and/or its upstream bandwidth provider monitoring the protocols used in bandwidth consumption, this is to enable Buyisa Solutions to effectively manage ‘Packet shaping’ as detailed in 4.13.</p>
                            <p>(This page will not time out even if it takes some time to read and understand the terms and conditions).</p>

                            <h3><strong>INTERPRETATION</strong></h3>
                            <p>In these terms and conditions:</p>
                            <p>2.1 “Consumer Protection Act” means the Consumer Protection Act 68 of 2008.</p>
                            <p>2.2 “Logging on” means the user entering the Buyisa Solutions.&nbsp; 5G/Fixed LTE “logging on” is considered to be the action of inserting the SIM provided by Buyisa Solutions into the router or mobile device to initiate the service.</p>
                            <p>2.3 “Buyisa Solutions” means Buyisa Solutions (PTY) LTD, a registered company which trades as Buyisa Solutions;</p>
                            <p>2.4 “Buyisa Solutions network” means the electronic communication infrastructure used by Buyisa Solutions to provide Internet access and bandwidth to the user;</p>
                            <p>2.5 “parties” means Buyisa Solutions and the user;</p>
                            <p>2.6 “the service” means the provision of access to the Buyisa Solutions network and related infrastructure by Buyisa Solutions to the user in accordance with these terms and conditions;</p>
                            <p>2.7 “terms and conditions” means the terms and conditions set out above and below;</p>
                            <p>2.8 “the user” means any person/entity that makes use of and purchases “the service”;</p>
                            <p>2.9 “reseller” means a “user’ that has been given express permission to resell Buyisa Solutions services. Permission is given in the form of access to the Buyisa Solutions resellers portal;</p>
                            <p>2.10 “registered user” means the ‘user’ that has entered their credentials into the Buyisa Solutions user portal and who pays for the “the service”.</p>
                            <p>2.11 “downgrade” means substituting a “service” that costs less than the initial “service” purchased by the user;</p>
                            <p>2.12 “service agreement/agreement” means the agreement between Buyisa Solutions and the user for the provision of the service, which is subject to all terms acknowledged and agreed to by the user (or his/her/its representative) when creating a profile on the Buyisa Solutions user portal, including these terms and conditions;</p>
                            <p>2.13 “Shaping” or “packet shaping” is a computer network traffic management technique which delays some or all datagrams/protocols to bring them into compliance with a desired traffic profile. ‘Shaping is applied ‘dynamically’ no private ‘user’ data is retained in this process;</p>
                            <p>2.14 “a calendar month” is the period between the first and the last day of any given month (e.g. 01 – 31 January is a ‘calendar month”).</p>
                            <p>2.15 “Protocols” means Network protocols that are formal standards and policies comprised of rules, procedures and formats that define communication between two or more devices over a network. Network protocols govern the end-to-end processes of timely, secure and managed data or network communication. Examples of protocols can be defined as, but not limited to : HTTP, HTTPS, FTP, VOD, P2P etc.</p>
                            <p>2.16 “Debit order” is a payment mechanism and associated authority that allows Buyisa Solutions to debit the users’ bank account in order to facilitate payment for the service in accordance with the rules set out by PASA (Payment Association of South Africa). All Debit orders are governed by the prevailing debit order mandate which the user agrees to when purchasing the Buyisa Solutions service. The Date/Time and IP address are logged and as such these details constitute an ‘electronic signature’. The logging of Date/Time and IP further indicates the acceptance of these terms.</p>
                            <p>2.17 “Top up” Top-up means a pre-paid voucher for additional data that is purchased once a user has reached their data cap/limit;</p>
                            <p>2.18 “Add on Bundle/Value added service” means the addition of prioritised protocols, for example VOD or gaming.</p>
                            <p>2.19 “Night time Cap/Night-surfer (NTC)” is a free/bonus data allocation that is available between 24h00 – 05h00. This data allocation is provided as an addition to the primary data allocation and does not count against the primary data allocation. The use of the NTC is subject to the user still having data available on their primary/anytime data allocation package.</p>
                            <p>2.20 “Anytime data” This is the primary data allocation that a user has purchased as indicated under the respective product sales pages at : https://www.buyisasolutions.com.</p>

                            <h3><strong>3 PROVISION OF SERVICE AND AUTHORITY</strong></h3>
                            <p>3.1 The user acknowledges that he/she has the prerequisite infrastructure in place to make use of the service. This includes but is not limited to a working Fixed LTE router/ mobile device within the given coverage area running at the correct speed for the chosen Buyisa Solutions data package. The user will be solely responsible for obtaining and arranging, configuring and maintaining all of the user’s equipment, including (but not limited to) computer hardware equipment, telecommunication equipment, modems/router, smart phones, tablets and the like, used by the user to obtain and maintain access to the Buyisa Solutions network.</p>
                            <p>3.2 The user will be liable for all telephone call charges and other costs payable to third parties relating to obtaining and arranging, configuring and maintaining equipment as described in 3.1 above.</p>
                            <p>3.3 The user [or his/her/its representative] warrants (undertakes that the following is true and may be liable for damages if it is not) that the user [or his/her/its representative] has the authority and capacity to contract with Buyisa Solutions for itself/himself/herself or, where applicable, on behalf of the trust, close corporation, company or other person the representative represents.</p>

                            <h3><strong>4 NETWORK &amp; AVAILABILITY OF ACCESS AND ASSOCIATED ACCEPTABLE USAGE</strong></h3>
                            <p>4.1 The Buyisa Solutions network may be used to link into other networks in South Africa and universally and the user agrees to conform to the use policies of these networks. (This means that the user must make sure that it does not use other networks, for example MTN and Telkom in South Africa, in a way that breaches the rules of use and acceptable usage policies of those networks – the policies outlined in these terms fall within those broader policies.)</p>
                            <p>4.2 The user may not circumvent authentication or security of any website, domain, host, network, or account (referred to as “cracking” or “hacking”), nor interfere with data package to any user, host, or network (referred to as “denial of service attacks”) and accepts that it is illegal to do so. This constitutes a material breach of the service agreement, and affords Buyisa Solutions the right to suspend and/or terminate the service agreement immediately without a refund.</p>
                            <p>4.3 In addition and without prejudice to (without overriding or taking away from) what is set out above, the use of any application that overloads the Buyisa Solutions network (e.g DOS or DDOS attacks) by whatever means will be regarded as making reckless use of the Buyisa Solutions network and is NOT allowed.</p>
                            <p>4.4 Users who violate systems or network security may incur criminal or civil liability. Buyisa Solutions will fully co-operate with investigations of suspected criminal violations, violation of systems or network security under the leadership of law enforcement or relevant authorities.</p>
                            <p>4.5 Buyisa Solutions will make every effort to make the service available at all times and will use its utmost endeavours to strive for 100 % (one hundred percent) uptime and associated bandwidth speeds. However, since Buyisa Solutions relies on numerous suppliers in order to provide the service,&nbsp;<strong>BUYISA SOLUTIONS is unable to and does not guarantee or warrant 100 % (one hundred percent) uptime and associated bandwidth speeds.</strong>&nbsp;The bandwidth speeds advertised on the Buyisa Solutions website indicate speeds up to the maximum possible theoretical speed attainable on a given Buyisa Solutions 5G/ Fixed LTE package and Buyisa Solutions does not warrant (undertake that the following is true and be open to possible liability for damages if it is not) that the user will experience bandwidth speeds equal to the maximum possible theoretical speeds.&nbsp;<strong>All access to the electronic communications infrastructure is therefore rendered on a “best effort” basis and Buyisa Solutions accordingly does not offer any form of service level agreement whatsoever.</strong></p>
                            <p>4.6 Buyisa Solutions will strive at all times to provide users with prior notice, where possible, of scheduled maintenance which may result in users having to log-off for any period of time.</p>
                            <p>4.7 Buyisa Solutions will not be responsible for the performance of external communications networks and protocols to which the service is connected or which facilitates connection to the Buyisa Solutions network, including the networks of Internet data package providers other than Buyisa Solutions. For example: MTN and Telkom.</p>
                            <p>4.7.1 Buyisa Solutions purchases from various providers.&nbsp;From time to time, the providers may change package pricing, data allocations and terms of service.&nbsp; Buyisa Solutions will, where reasonable, notify clients of such changes.</p>

                            <h4><strong>Important Waiver and Indemnity</strong></h4>
                            <p>4.8 The user waives all and any claims that may arise as a result of Buyisa Solutions being unable to make the service available to the user under the circumstances contemplated in 4.7 above and indemnifies Buyisa Solutions and holds Buyisa Solutions harmless against all and any claims that may arise as a result of Buyisa Solutions being unable to make the service available to the user under the circumstances contemplated in 4.7 above.</p>
                            <p>More specifically:</p>
                            <p>4.8.1 The user cannot bring any such claim against Buyisa Solutions;</p>
                            <p>4.8.2 In relation to third party claims:</p>
                            <p>4.8.2.1 the user will pay all costs, damages, awards and judgments finally awarded against Buyisa Solutions arising from such claims;</p>
                            <p>4.8.2.2 the user will pay all legal fees incurred in connection with such claims on an attorney and own client scale (Buyisa Solutions will recover as much of its costs incurred as legally possible), including fees incurred prior to or during the institution of legal proceedings, and/or in connection with the satisfaction of any costs, damages, awards and/or judgments awarded against Buyisa Solutions;</p>
                            <p>4.8.2.3 the user will, at the user’s expense, notify Buyisa Solutions of any such claim which comes to the user’s attention; where applicable give Buyisa Solutions the authority to defend, compromise or settle any such claim; and give Buyisa Solutions all reasonable assistance necessary to defend such claims;</p>
                            <p>4.8.2.4 the user’s indemnity against third party claims will not apply to the extent that it is not permitted under the Consumer Protection Act.</p>
                            <p>Reference to Buyisa Solutions in this clause 4.8 includes reference to any employee, representative or other personnel of Buyisa Solutions.</p>
                            <p>4.9 Buyisa Solutions will provide the service strictly subject to the terms and conditions prescribed by its third-party bandwidth resellers and the national regulator (Independent Communications Authority of South Africa, ICASA) and prevailing legislation.</p>
                            <p>4.10 Buyisa Solutions will be entitled to suspend the service if, during the user’s use of the service, the Buyisa Solutions network is or potentially may be endangered, harmed and/ or impeded. Where possible, Buyisa Solutions will notify the user prior to such a suspension.</p>
                            <p>4.11 Capped services are usage based. This usage is calculated and managed by third party bandwidth wholesalers, like, but not limited to MTN and Telkom and such calculation is considered as being accurate and binding. Buyisa Solutions will not be responsible for any bandwidth loss or theft as it is the user’s responsibility to ensure proper management of their usage and associated credentials.</p>
                            <p>4.12 Local usage, where applicable (relating to the use of internet sites and email servers hosted in South Africa): In some cases, the local usage provided after the initial cap has been depleted, is capped at a set number of gigabytes (e.g. 1GB, 7GB or 50GB’s depending on the account purchased). Once the user’s account has reached the applicable local usage cap, the account will be hard-capped until the following month. (The user will not be able to make use of the service while the user’s account is hard-capped). Additional accounts can be purchased, at the user’s discretion.</p>
                            <p>4.13 Acceptable Usage and Packet Shaping Policy (AUP)</p>
                            <p>Buyisa Solutions makes use of ‘electronic measures’ in the form of bandwidth ‘packet shaping’ to ensure that the Buyisa Solutions network is not compromised or over burdened by abuse as defined in Sections 6.3 and 6.4 of these&nbsp;<em>terms</em>. Protocols that may be subject to ‘packet shaping’ include, but are not limited to P2P/bit-torrent, NNTP, HTTP file hosting and certain ‘video on demand’ (VOD) services. The application of ‘shaping’ is based on how busy the Buyisa Solutions network is at any given time as well as the priority of a given user’s service level. ‘Shaping ‘is thus applied dynamically and at no stage is any private ‘user’ information retained.</p>

                            <h3><strong>5A. Fixed LTE/Mobile SIMs</strong></h3>
                            <p>5A.1. All hardware supplied by Buyisa Solutions will include a 12-month manufacturer’s warranty. Faulty hardware will be swapped out for a once off courier cost of R199 per incident.</p>
                            <p>5A.2. All Buyisa Solutions LTE packages are “best-effort” services and Upload and Download speeds cannot be guaranteed. Performance/speed is reliant on many factors such as coverage/distance from base station, prevailing weather conditions and base station congestion) As detailed in clause 4.5.</p>
                            <p>5A.3. The early cancellation of any fixed term LTE contract is allowed (as defined by Section 14 of the CPA 2008) but the user will be responsible for an early cancellation penalty. [The reason this penalty exists is because Buyisa Solutions is obligated to conclude 24-month contract/s on uncapped LTE [e.g. Telkom] accounts].</p>
                            <p>5A.3.1 Early cancellation penalty fee – A user will be charged 3 x normal monthly subscription. In the event that Buyisa Solutions provides hardware the user shall be held liable for the full remainder of the hardware cost for the contract term. Alternatively, the hardware may be returned to Buyisa Solutions at the user’s expense via courier. The device must be in a good technical and cosmetic condition, and must be in the original packaging, and include all cables, power supply unit, documentation and the device itself.</p>
                            <p>5A.4. Capped Buyisa Solutions 5G/LTE packages will be available on month-to-month basis.</p>
                            <p>5A.5. Bandwidth caps included in monthly subscription will be replenished on the 1st of every month.</p>
                            <p>5A.5.1. Top-ups can be purchased at any time, payment is tendered via Credit Card or voucher.</p>
                            <p>5A.5.2. Users will be billed for the total volume of data both sent and received.</p>
                            <p>5A.6. Any unused monthly data may rollover to the next calendar month for up to 90 days.</p>
                            <p>5A.7. Should a user sign a contact during a month other than on the 1st, the data and the billing will be done&nbsp;<strong><em>pro-rata</em></strong>&nbsp;accordingly for the remaining days of the particular month and the User will be billed along with the first full calendar month billing;</p>
                            <p>5A.8. Add on bundles will be valid for 30 (thirty) days. (As an example, should a user purchase an Add on bundle on the 25th of July, the Add on bundle will expire on the 24th of August at 23:59).</p>
                            <p>5A.9. Any unused Add on bundle/Value added service shall be forfeited at 23:59 on the expiry date.</p>
                            <p>5A.10. Upon reaching the data limit/cap for the month, the User will be required to ‘top up’ in order to continue normal service.</p>
                            <p>5A.11. The Users location must be in the service coverage map for the selected 5G/Fixed LTE network. MTN Fixed LTE solutions are locked to the location that is specified when the service is ordered.</p>
                            <p>5A.12. Services which are provisioned outside of the coverage area at the Users insistence are provisioned entirely at the user’s risk.</p>
                            <p>5A.13. Failure to adhere to agreed delivery arrangements with the courier company may result in a shipping penalty of R199.00 been charged and further cancellation of the agreement.</p>
                            <p>5A.14. Failure of the delivery company to reach the user in five attempts to make delivery arrangements will result in cancellation of the agreement and a service fee of R999 be billed to the User.</p>
                            <p>5A.15.2 In the event that equipment has to be swapped out, the once off installation and/or decommission fee applicable (R400) to the required bandwidth may apply.</p>
                            <p>5A.17. It is the user’s responsibility to fully insure the hardware for theft and damage. The user is fully liable for the replacement cost of the router if stolen or damaged.</p>
                            <p>5A.18. Where Buyisa Solutions provides hardware, the hardware remains the property of Buyisa Solutions at all times. The user is deemed to be renting the hardware from Buyisa Solutions. Upon the cancellation of the service, after the initial 12-month period has lapsed, the hardware must be returned to Buyisa Solutions or may be purchased for a once off fee of R149.00.</p>
                            <p>5A.19. SIM cards will only be dispatched once payment is made for the 1<sup>st</sup>&nbsp;month; SIM cards may take 7 -10 working days to be delivered from when payment is received. In the event that the SIM takes longer than the 7 – 10 working day period to arrive a pro-rate refund will be passed calculated for every day after the 10<sup>th</sup>&nbsp;working day.</p>
                            <p>5A.20. The ‘Nightime Cap’ data allocation is a free (bonus) data allocation. The use of the ‘Nightime Cap’ free data is subject to and further requires that the user has ‘Anytime data’ available. The Nightime Cap cannot be used if the user has no ‘Anytime data’ available. (When you have depleted your Anytime cap, your Nightime Cap will not be available until you top-up).</p>
                            <p>5A.21. Top-up data is valid for 30 days from date of purchase. When a user’s usage resets in a new month, any remaining top-up data will be consumed first.&nbsp;&nbsp;<strong>In the event of an&nbsp;Unpaid&nbsp;debit order or credit order, top-up data will be forfeited if payment is not processed before the system suspends the service for non-payment.</strong></p>
                            <p>5A.22. Mobile numbers are leased from Buyisa Solutions and cannot be ported once a client cancels.</p>

                            <h3><strong>6 USER ETIQUETTE &amp; ILLEGAL USE</strong></h3>
                            <p>6.1 The user acknowledges and agrees that the user is expected to abide by generally accepted&nbsp;<em>‘Netiquette’</em>.</p>
                            <p>6.2 The user acknowledges that Buyisa Solutions is unable to exercise control over the content of the information passing over the Buyisa Solutions network and is accordingly not responsible or in any way liable for such content.</p>
                            <p>6.3 The Buyisa Solutions network may only be used for lawful purposes. The user will accordingly not use the service in any manner which:</p>
                            <p>6.3.1 constitutes a violation of any law, regulation and/or tariff that is in force in the Republic of South Africa;</p>
                            <p>6.3.2 constitutes an act or omission that is generally unacceptable or offensive to internet users in general or the public at large, specifically including but not limited to the hosting of pornographic or other obscene material, spamming, hacking, unsolicited mailing or use of material that violates export control laws;</p>
                            <p>6.3.3 is defamatory, fraudulent or deceptive;</p>
                            <p>6.3.4 is intended to threaten, harass or intimidate;</p>
                            <p>6.3.5 damages the name and/or reputation of Buyisa Solutions;</p>
                            <p>6.3.6 interferes with the use and enjoyment of internet related services by other users or Buyisa Solutions services;</p>
                            <p>6.3.7 forwards or propagates chain letters or malicious email; and/or</p>
                            <p>6.3.8 solicits mail for any address other than that of the user, except with full consent of the owner of such other address.</p>
                            <p>To assist the user to comply with the user’s obligations under this clause 6.3, Buyisa Solutions will forward or communicate to the user any notifications which Buyisa Solutions receives relating to the improper use of the service or the Buyisa Solutions network by the user.</p>
                            <p>6.4 The user undertakes to abide by all laws applicable to copyright and intellectual property, redistribution or re-sale of any data and/or publications, compositions, productions and software information retrieved from the service and/or the internet by the use of the service. Transmission, distribution or storage of any material protected by copyright, trademark, trade secret or other intellectual property right without proper authorisation, is strictly prohibited.</p>
                            <p>6.5 The user will not, without the express written permission of Buyisa Solutions, resell or otherwise make the service the user may receive from Buyisa Solutions, available to any third party.</p>
                            <p>6.5 The user acknowledges that third party product and service providers may advertise their products and services on the Buyisa Solutions website. Buyisa Solutions forms partnerships or alliances with some of these vendors from time to time in order to facilitate the provision of these products and services to the user. However, the user acknowledges and agrees that at no time is Buyisa Solutions making any representation or warranty regarding any third party’s products or services, nor will Buyisa Solutions be liable to the user or any third party for any claims arising from or in connection with such third-party products and/or services.</p>

                            <p><strong>Important Indemnity</strong></p>
                            <p>6.7 The user indemnifies and holds Buyisa Solutions harmless against all and any third-party claims that may arise from the user’s use of the Buyisa Solutions network and/or the user’s telecommunication line and/or the service. More specifically:</p>
                            <p>6.7.1 the user will pay all costs, damages, awards and judgments finally awarded against Buyisa Solutions arising from such claims;</p>
                            <p>6.7.2 the user will pay all legal fees incurred in connection with such claims on an attorney and own client scale (Buyisa Solutions will recover as much of its costs incurred as legally possible), including fees incurred prior to or during the institution of legal proceedings and in connection with the satisfaction of any costs, damages, awards and/or judgments awarded against Buyisa Solutions;</p>
                            <p>6.7.3 the user will, at the user’s expense, notify Buyisa Solutions of any such claim which comes to the user’s attention; where applicable, give Buyisa Solutions full authority to defend, compromise or settle such claims; and give Buyisa Solutions all reasonable assistance necessary to defend such claims;</p>
                            <p>6.7.4 the user’s indemnity against third party claims will not apply to the extent that it is not permitted under the Consumer Protection Act.</p>
                            <p>Reference to Buyisa Solutions in this clause 5.7 includes reference to any employee, representative or other personnel of Buyisa Solutions.</p>

                            <h3><strong>7 PAYMENT FOR THE SERVICE</strong></h3>
                            <p>7.1 The user will pay to Buyisa Solutions a monthly subscription as set out in the applicable tariff guide appearing at: https://www.buyisasolutions.com AND associated pages.</p>
                            <p>7.2 Subscriptions are due on or before the 27th of each month, paid in advance for the following month’s service if paid for by means of an EFT or via credit card. Subscriptions may be paid by way of electronic transfer, credit card payment or debit order.</p>
                            <p>7.3&nbsp;<strong>The user is responsible for ensuring that debit order and credit card payments are honoured and that the necessary payment mechanisms are in place. Monthly debit order payments are processed between the 30th and 4th day of each month (payments are collected by our payment gateway partners).&nbsp; &nbsp;Debit orders that are arranged during a ‘new’ user’s first month of subscription will be processed during the next 7-day debit order processing period. If the user pays by electronic transfer, the user is responsible for sending proof of payment to Buyisa Solutions by email to&nbsp;payments@buyisasolutions.com&nbsp;and for verifying that Buyisa Solutions has received the proof of payment and marked the user’s account as PAID on or before the 27th of each month.</strong></p>
                            <p>7.4 The service may be terminated without notice to the user, with effect from the first day of the following month, if:</p>
                            <p>7.4.1 a subscription which is due for the following month is not paid; or</p>
                            <p>7.4.2 in the case of electronic transfers, proof of payment of a subscription is not received by the 27th of the month preceding the month of usage and Buyisa Solutions does not subsequently receive the subscription in its bank account after the 27th but before the end of that month.</p>
                            <p>7.5 Subject to Buyisa Solutions discretion in clause 7.4, if the first and/or the second payment due by the user to Buyisa Solutions is not paid then the service will be terminated without notice to the user on the date when the third payment falls due, without prejudice to Buyisa Solutions or its nominated agent/s right to recover the first and/or second payments with interest and/or collection fees/costs which accrues by operation of law.</p>
                            <p>7.6 Buyisa Solutions will not be liable for any loss (including loss of business or revenue), damages, expenses, costs or claims arising due to the termination of the service in the circumstances contemplated in clauses 7.4 and 7.5.</p>
                            <p>7.7 Except to the extent the following limitations may be prohibited by the Consumer Protection Act in certain circumstances:</p>
                            <p>7.7.1 subscriptions are to be paid without deduction, set-off or demand; and</p>
                            <p>7.7.2 subscriptions paid are non-refundable.</p>
                            <p>7.8 Access to the service paid for within a month (i.e. after the first day of the month), will only last until the end of the month it was purchased in.</p>
                            <p>7.9 With the exception of ‘bundled’ LTE services that include line installation/rental&nbsp; data, hardware (and which is subject to a 12-month contract),&nbsp;where the service is rendered on a month-to-month basis the service agreement does not constitute a ‘fixed term contract’ as contemplated in Section 14 of the Consumer Protection Act.</p>
                            <p>7.10 Where the client makes an incorrect or additional payment/s and as such a refund is required, a penalty of 15% the payment value may be levied and deducted before the refund is processed.</p>
                            <p>7.11&nbsp;<strong>Clients will be billed irrespective of whether the 5G/ Fixed LTE data account is used or not – this is because Buyisa Solutions is required to purchase the prerequisite capacity on the client’s behalf in advance.</strong></p>

                            <h3><strong>8 TERMINATION OF USE AND DOWNGRADES OF THE SERVICE BY THE USER</strong></h3>
                            <p>Only the registered user may terminate the use of the service and the associated debit order (if applicable) by filling in the online form located at https://www.buyisasolutions.com/cancellations</p>
                            <p>8.1&nbsp;<strong>A mandatory one (1) calendar months’ notice is required for all users making use of debit/credit order payment facilities, given before the 25th day of the month before the last month for the provision of the service</strong>&nbsp;(e.g. if the user wishes the use of the service to terminate at the end of April, notice of termination must be given before 25 March). Users may not downgrade their Broadband account/s during/for the one (1) calendar months’ notice period. Where proper notice is given in time, Buyisa Solutions will issue, by email, a cancellation reference number. Only once a cancellation reference number has been issued will the user’s account be deemed to be cancelled.</p>
                            <p>8.2 The user is required to provide Buyisa Solutions with a mandatory calendar’s months’ notice should they wish to downgrade their service level. The downgrade request must be sent via email to&nbsp;downgrades@buyisasolutions.co.za&nbsp;before the 25th day of the month preceding the downgrade notice month (e.g. if the user wishes the downgrade to occur at the end of April, notice of the downgrade must be given before 25 March). Buyisa Solutions will issue, by email, a downgrade reference number. Only once a downgrade reference number has been issued will the user’s account be processed for the downgrade.</p>

                            <h3><strong>9 SUSPENSION/TERMINATION OF SERVICE BY BUYISA SOLUTIONS</strong></h3>
                            <p>9.1 The service may be suspended by Buyisa Solutions in the following circumstances:</p>
                            <p>9.1.1 During any technical failure, modification or maintenance of the service or the Buyisa Solutions network, including but not limited to:</p>
                            <p>9.1.1.1 a failure due to activity such as cracking, hacking or denial of service attacks described in 4.2 above; and</p>
                            <p>9.1.1.2 any maintenance contemplated in 4.6 above, provided that Buyisa Solutions will use its reasonable efforts to resume the service as soon as possible.</p>
                            <p>9.1.2 If and when the Buyisa Solutions network is or potentially may be endangered, harmed and/ or impeded as described in 4.10 above.</p>
                            <p>9.2 Recognising the constitutional right of our employees and/or agents to dignity, the user is required to refrain from abusing Buyisa Solutionss staff members and agents. If a user directly or indirectly abuses [including but not limited to the use of foul/obscene language and/or profanity and/or racial and/or gender abuse or innuendo and/or been generally discourteous or offensive] or harasses an Buyisa Solutions staff member or agent, this will be regarded as a material breach of the service agreement and the account of the user in question will immediately be deleted without any refund whatsoever. In these cases, Buyisa Solutions reserves its rights to pursue the matter further.</p>
                            <p>9.3 In addition to the right of termination set out in 7.4 above, the service may be terminated by Buyisa Solutions immediately and without notice to the user should the user fail to comply with any material term or condition set out in these terms and conditions.</p>
                            <p>9.4 Buyisa Solutions reserves the right to levy a re-connection charge [an amount determined and calculated at the discretion of Buyisa Solutions] for re-connection to the use of the service or Buyisa Solutions network after suspension or termination of the service for any reason. Any re-connection charge will be payable immediately when the user receives an invoice from Buyisa Solutions for the re-connection charge.</p>
                            <p>9.5 The user will remain liable for all charges due throughout a period of suspension of the service unless Buyisa Solutions in its sole discretion determines otherwise in writing.</p>

                            <h3><strong>10 DISPUTE RESOLUTION</strong></h3>
                            <p>10.1 Save and except relating to the provisions of clause 7.5 above, any dispute between the user and Buyisa Solutions in regard to any matter arising out of these terms and conditions or their interpretation, or the parties’ respective rights and liabilities under these terms and conditions or the cancellation of these terms and conditions or any matter relating to these terms and conditions will be submitted for&nbsp;<strong>mediation</strong>&nbsp;by the user or Buyisa Solutions on written notice to the other and, failing resolution at mediation within 20 business days of referral of the dispute to mediation, for&nbsp;<strong>arbitration</strong>.</p>
                            <p>10.2 The mediator/arbitrator will be appointed by agreement between the parties, and, failing agreement, by Aequitate Dispute Resolution Services (Pty) Ltd. Each party shall bear half of the costs of the respective process, unless in the case of arbitration, the arbitrator makes a costs award. The mediator/arbitrator will be, if the question in issue is:</p>
                            <p>10.2.1 primarily a legal matter, practising mediator of not less than 5 (five) years’ standing accredited by DISAC as a mediator based;</p>
                            <p>10.2.2 any other matter [including billing related matters], an independent person agreed on between the parties and failing agreement appointed by the chairperson of the Society of Advocates.</p>
                            <p>10.3 The arbitration will be conducted in accordance with the rules of the Arbitration Foundation of Southern Africa (“AFSA”) for Commercial Arbitrations but not through the offices or under the administration of AFSA, unless otherwise agreed between the user and Buyisa Solutions in writing.</p>
                            <p>10.4 Nothing in this clause 10 will prevent Buyisa Solutions from seeking interim and/or urgent relief from a Court of competent jurisdiction and to this end the Parties hereby consent to the jurisdiction of the High Court of South Africa.</p>
                            <p>10.5 This clause 10 is not intended to limit the user’s right to settle a dispute concerning the application of the Consumer Protection Act using the mechanisms provided in the Consumer Protection Act.</p>

                            <h3><strong>11 REGULATORY COMPLIANCE</strong></h3>
                            <p>11.1 The user undertakes to comply with all regulatory obligations that may now or in the future be imposed by the body under whose authority the service falls, currently the Independent Communications Authority of South Africa, ICASA.</p>
                            <p>(To help the user to comply with the user’s obligations under this clause 11.1, Buyisa Solutions will forward or communicate to the user any notifications which Buyisa Solutions receives relating to the user’s regulatory obligations as imposed by ICASA or its successor.)</p>
                            <p>11.2 The user acknowledges that the imposition of regulatory obligations by such body may result in the need to change these terms and conditions and the user undertakes to co-operate with Buyisa Solutions in this process.</p>

                            <h3><strong>12 USER SUPPORT</strong></h3>
                            <p>12.1 Buyisa Solutions has three (3) channels for ‘users’ to access service support, these channels are defined on the Buyisa Solutions website at :&nbsp;<a href="https://www.buyisasolutions.com/support/">https://www.buyisasolutions.com/support/</a></p>
                            <p>12.2 User support is offered strictly on a best effort basis (Clause 4.5). Buyisa Solutions undertakes no warrantees and offers no service level agreement in relation to support and associated turnaround times whatsoever.</p>

                            <h3><strong>13 GENERAL</strong></h3>
                            <p>13.1 The user acknowledges and agrees that these terms and conditions govern the user’s use of the service and that there are no other agreements, guarantees, warrantees or representations, either verbal or in writing, governing or relating to the user’s use of this service.</p>
                            <p>13.2 Any explanations in (brackets) are for explanatory purposes only, to aid understanding by the user, and will not govern the interpretation of these terms and conditions.</p>
                            <p>13.3 These terms and conditions may be clarified or modified periodically. Such changes are deemed effective as soon as they are published on the Buyisa Solutions website (https://www.buyisasolutions.com/terms).</p>
                            <p><strong>IMPORTANT : Any queries regarding these terms and conditions can be sent to&nbsp;legal@buyisasolutions.com</strong></p>

                        </div>
                    </div>
                </div>
            </section>
        </b-overlay>
    </div>
</template>

<script>
import SubPageHeader from "@/components/General/SubPageHeader.vue";

export default {
    name: "GeneralTermsAndConditions",
    components: {SubPageHeader},
    data() {
        return {
            isLoading: false
        }
    }
}
</script>

<style lang="scss" scoped>
h3 {
    margin-top: 1.8rem;
    margin-bottom: .8rem;
}
</style>