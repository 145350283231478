<template>
  <div id="app">
    <div>
      <b-navbar type="dark" class="bg-dark" style="position: fixed; width: 100%; top: 0; z-index: 1040;">
        <b-navbar-nav style="width: 100%; align-items: center; justify-content: space-between;">
          <b-nav-item href="#">
              <img src="https://www.buyisakonnect.co.za/wp-content/uploads/2023/06/Logo-Yellow-Without-BG_1.svg"
                   alt="Buyisa Solutions"
                   class="main-navigation-logo"
                   width="80px"
              >
          </b-nav-item>

          <b-navbar-nav class="ml-auto gap-1">
            <b-button href="https://www.buyisakonnect.co.za/" class="small rounded">
              <b-icon icon="globe2" aria-hidden="true"  class="rounded-circle" title="all products"></b-icon>
            </b-button>
            <b-button href="https://www.buyisakonnect.co.za/promotions/our-products/prepaid-mobile-data/" class="small">
              <b-icon icon="shop" aria-hidden="true"  class="rounded-circle" title="all products"></b-icon>
            </b-button>
            <b-button :to="{name: 'ShoppingCart'}" >
              <b-icon icon="cart4" aria-hidden="true" class="rounded-circle"></b-icon>
            </b-button>
            <b-button :to="{name: 'MyProfile'}" v-if="!$store.state.loggedUser">
              <b-icon icon="person-fill" aria-hidden="true" class="rounded-circle"></b-icon>
            </b-button>
          </b-navbar-nav>


          <!--          <b-nav-item-dropdown text="User" right>-->
<!--            <b-dropdown-item href="#">Account</b-dropdown-item>-->
<!--            <b-dropdown-item href="#">Settings</b-dropdown-item>-->
<!--          </b-nav-item-dropdown>-->
        </b-navbar-nav>
      </b-navbar>
    </div>
<!--      <b-navbar id="main-navigation" toggleable="lg" type="dark">-->
<!--          <div class="container">-->

<!--            <a href="https://www.buyisakonnect.co.za" class="navbar-brand m-3 router-link-exact-active router-link-active" target="_self" aria-current="page">-->
<!--              <img src="https://www.buyisakonnect.co.za/wp-content/uploads/2023/06/Logo-Yellow-Without-BG_1.svg" alt="Buyisa Solutions" class="main-navigation-logo">-->
<!--            </a>-->

<!--              <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>-->

<!--              <b-collapse id="nav-collapse" is-nav>-->
<!--                  <b-navbar-nav class="ml-auto">-->
<!--                      <b-navbar-nav class="m-3">-->
<!--&lt;!&ndash;                          <b-nav-item to="https://www.buyisakonnect.co.za">Home</b-nav-item>&ndash;&gt;-->

<!--                        <li class="nav-item"><a href="https://www.buyisakonnect.co.za" class="nav-link" target="_self">Home</a></li>-->
<!--&lt;!&ndash;                          <b-nav-item-dropdown text="Mobile">&ndash;&gt;-->
<!--&lt;!&ndash;                              <b-dropdown-item :to="{name: 'PrepaidMobileData'}">Prepaid Mobile Data</b-dropdown-item>&ndash;&gt;-->
<!--&lt;!&ndash;                              &lt;!&ndash;                              <b-dropdown-item to="#">Monthly Mobile Data</b-dropdown-item>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                              &lt;!&ndash;                              <b-dropdown-item to="#">Monthly Mobile Data & Airtime</b-dropdown-item>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                          </b-nav-item-dropdown>&ndash;&gt;-->
<!--&lt;!&ndash;                          <b-nav-item to="https://www.buyisakonnect.co.za/about">About</b-nav-item>&ndash;&gt;-->
<!--&lt;!&ndash;                          <b-nav-item to="https://www.buyisakonnect.co.za/contact">Contact</b-nav-item>&ndash;&gt;-->


<!--                        <b-nav-item-dropdown v-if="!$store.state.loggedUser"-->
<!--                                             text="Mobile" right>-->
<!--                          <b-dropdown-item href="https://www.buyisakonnect.co.za/mobile/monthly-mobile-data/">Monthly Mobile Data</b-dropdown-item>-->
<!--                          <b-dropdown-item href="https://www.buyisakonnect.co.za/mobile/prepaid-mobile-data/">Prepaid Mobile Data</b-dropdown-item>-->
<!--                        </b-nav-item-dropdown>-->


<!--                        <b-nav-item href="https://www.buyisakonnect.co.za/about-us/" v-if="!$store.state.loggedUser">-->
<!--                          <span>About Us</span>-->
<!--                        </b-nav-item>-->

<!--                        <b-nav-item href="https://www.buyisakonnect.co.za/connect/" v-if="!$store.state.loggedUser">-->
<!--                          <span>Contact Us</span>-->
<!--                        </b-nav-item>-->


<!--                        <b-nav-item :to="{name: 'MyAccount'}" v-if="$store.state.loggedUser">-->
<!--                          <span>My Account</span>-->
<!--                        </b-nav-item>-->

<!--                        <b-nav-item :to="{name: 'ClientProducts'}" v-if="$store.state.loggedUser">-->
<!--                          <span>My Products</span>-->
<!--                        </b-nav-item>-->

<!--                        <b-nav-item :to="{name: 'MyProfile'}" v-if="$store.state.loggedUser">-->
<!--                          <span>My Profile</span>-->
<!--                        </b-nav-item>-->

<!--                        <b-nav-item-dropdown v-if="$store.state.loggedUser"-->
<!--                                             :text="$store.state.loggedUser.first_name" right>-->
<!--                          <b-dropdown-item @click="logout()">Logout</b-dropdown-item>-->
<!--                        </b-nav-item-dropdown>-->

<!--                        <b-nav-item to="/cart"><b-icon icon="cart"></b-icon>-->
<!--                          <span v-if="!isLoading && $store.state.cart">({{$store.state.cart.products.length}})</span>-->
<!--                        </b-nav-item>-->
<!--                      </b-navbar-nav>-->
<!--                  </b-navbar-nav>-->
<!--              </b-collapse>-->
<!--          </div>-->
<!--      </b-navbar>-->

    <div class="content-wrapper">

      <router-view/>
    </div>
      <b-modal id="register-login-forms"
               size="xl"
               title="Your Buyisa Solution Profile"
               hide-footer
               centered>
          <LoginRegistrationForms/>
      </b-modal>

    <b-navbar id="mobile_footer" type="dark" class="bg-dark" style="position: fixed; bottom: 0; width: 100%; z-index: 99999999; overflow: hidden" v-if="$store.state.loggedUser">
      <b-navbar-nav style="overflow: scroll">
        <b-nav-item :to="{name:'HomePage'}">
          <div style="font-size: 1rem;">
            <b-icon icon="house-fill" aria-hidden="true"></b-icon>
          </div>
          <small>Home</small>
        </b-nav-item>

        <b-nav-item :to="{name: 'ClientProductsPage'}">
          <div style="font-size: 1rem;">
            <b-icon icon="bag-check" aria-hidden="true"></b-icon>
          </div>
          <small>My Products</small>
        </b-nav-item>

        <b-nav-item :to="{name: 'MyProfilePage'}">
          <div style="font-size: 1rem;">
            <b-icon icon="person-fill" aria-hidden="true"></b-icon>
          </div>

          <small>My Profile</small>
        </b-nav-item>

        <b-nav-item :to="{name: 'HelpCenterPage'}">
          <div style="font-size: 1rem;">
            <b-icon icon="question-circle-fill" aria-hidden="true"></b-icon>
          </div>
          <small>Help</small>
        </b-nav-item>

        <b-nav-item @click="logout" v-if="$store.state.loggedUser">
          <div style="font-size: 1rem;">
            <b-icon icon="box-arrow-right" aria-hidden="true"></b-icon>
          </div>
          <small>Logout</small>
        </b-nav-item>
      </b-navbar-nav>

    </b-navbar>

      <MainFooter style="display: none"/>
  </div>
</template>

<style scoped lang="scss">
.bg-yellow {
  background-color: #fdc51a !important;
  color: #343a40 !important;
}

#mobile_footer {
  .navbar-nav {
    width: 100%;
    gap: 20px;
    justify-content: space-evenly;
    .nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;

      text-align: center;
      text-wrap: nowrap;

      small {
        font-size: 12px;
      }
    }
  }

}
</style>
<script>
import LoginRegistrationForms from "@/components/Auth/LoginRegistrationForms.vue";
import cartHelper from "@/helpers/cart.helper";
// import buyissolutionsApiService from "@/services/buyissolutions.api.service";
import authTokenHelper from "@/helpers/authToken.helper";
import MainFooter from "@/components/General/MainFooter.vue";
import buyissolutionsApiService from "@/services/buyissolutions.api.service";

export default {
    components: {MainFooter, LoginRegistrationForms},
    mounted() {
      // this.$mixpanel.track_pageview();

      // Track any URL changes in the path, query string, or hash
      // mixpanel.init('YOUR_TOKEN', {track_pageview: "full-url"});

      // this.$mixpanel.track_links("a", "click nav link", {
      //   "referrer": document.referrer
      // });

        this.isLoading = true;

        cartHelper.getCart().then(cart => {
            this.$store.state.cart = cart

            this.isLoading = false;
        });

      this.isLoading = true;
      buyissolutionsApiService.getLoggedInUser().then(response => {
          if (response.status === 200) {
              this.$store.state.loggedUser = response.data.user
          } else {
            authTokenHelper.clearToken().then(() => {
              this.$store.state.loggedUser = null;
            });
          }
      }).catch(() => {
        authTokenHelper.clearToken().then(() => {
          this.$store.state.loggedUser = null;
        });
      });
    },
    data() {
        return {
            isLoading: false
        }
    },
    methods: {
        logout() {
            authTokenHelper.clearToken().then(() => {
                this.$store.state.loggedUser = null;
                this.$mixpanel.reset();
                clearInterval(this.$store.state.intervalID);
                this.$router.push('/')
                // location.href = 'https://www.buyisakonnect.co.za/'
            });
        }
    }
}
</script>