<template>
  <div id="client-product-page" class="page">

    <b-overlay :show="isLoading" rounded="sm" class="content">
      <div class="sub-page-header" style="
              position: sticky;
              top: 99px;
              background: #6c757e;
              z-index: 99999999;
              padding-top: 15px;
              padding-bottom: 16px;
          ">

        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="top-header">
                <b-button pill :to="{'name': 'HomePage'}" variant="link">
                  <b-icon icon="arrow-left-circle" class="text-white" scale="2"></b-icon>
                </b-button>

                <h4 class="text-white">My Profile</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content">

        <div class="container">
          <div class="row">
          <div class="col-md-12">
            <PersonalDetails/>
          </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import PersonalDetails from "@/components/Profile/PersonalDetails.vue";

export default {
  name: "MyProfilePage",
  components: {PersonalDetails},
  created () {
    this.$mixpanel.track_pageview({"page": "My Profile Page"});
  },
  data() {
    return {
      isLoading: false,
      clientProducts: [],
    }
  },
}
</script>

<style scoped>

</style>