var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2",attrs:{"id":"registration-component"}},[_c('h3',{staticClass:"mb-3"},[_vm._v("Register Your Account")]),_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"sm"}},[_c('form',{attrs:{"id":"registration-form"}},[(_vm.errorMessage)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('b-form-group',{attrs:{"id":"firstname-input-group","label":"First name","label-for":"firstname","description":""}},[_c('b-form-input',{class:{
                        'is-invalid': _vm.$v.registrationForm.firstName.$dirty && _vm.$v.registrationForm.firstName.$error,
                        'is-valid': _vm.$v.registrationForm.firstName.$dirty && !_vm.$v.registrationForm.firstName.$error
                    },attrs:{"id":"first_name","type":"text","placeholder":"","required":""},model:{value:(_vm.$v.registrationForm.firstName.$model),callback:function ($$v) {_vm.$set(_vm.$v.registrationForm.firstName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.registrationForm.firstName.$model"}})],1),_c('b-form-group',{attrs:{"id":"lastname-input-group","label":"Last name","label-for":"firstname","description":""}},[_c('b-form-input',{class:{
                        'is-invalid': _vm.$v.registrationForm.lastName.$dirty && _vm.$v.registrationForm.lastName.$error,
                        'is-valid': _vm.$v.registrationForm.lastName.$dirty && !_vm.$v.registrationForm.lastName.$error
                    },attrs:{"id":"last_name","type":"text","placeholder":"","required":""},model:{value:(_vm.$v.registrationForm.lastName.$model),callback:function ($$v) {_vm.$set(_vm.$v.registrationForm.lastName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.registrationForm.lastName.$model"}})],1),_c('b-form-group',{attrs:{"id":"username-input-group","label":"Email Address","label-for":"username","description":""}},[_c('b-form-input',{class:{
                        'is-invalid': _vm.$v.registrationForm.username.$dirty && _vm.$v.registrationForm.username.$error,
                        'is-valid': _vm.$v.registrationForm.username.$dirty && !_vm.$v.registrationForm.username.$error
                    },attrs:{"id":"username","type":"text","placeholder":"","required":""},model:{value:(_vm.$v.registrationForm.username.$model),callback:function ($$v) {_vm.$set(_vm.$v.registrationForm.username, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.registrationForm.username.$model"}})],1),_c('b-form-group',{attrs:{"id":"contact-input-group","label":"Contact Number","label-for":"contact","description":"We use this number to send you notifications via WhatsApp and email."}},[_c('b-form-input',{class:{
                        'is-invalid': _vm.$v.registrationForm.contact.$dirty && _vm.$v.registrationForm.contact.$error,
                        'is-valid': _vm.$v.registrationForm.contact.$dirty && !_vm.$v.registrationForm.contact.$error
                    },attrs:{"id":"contact","type":"text","placeholder":"","required":""},model:{value:(_vm.$v.registrationForm.contact.$model),callback:function ($$v) {_vm.$set(_vm.$v.registrationForm.contact, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.registrationForm.contact.$model"}})],1),_c('b-form-group',{attrs:{"id":"password-input-group","label":"Password","label-for":"password","description":""}},[_c('b-form-input',{class:{
                        'is-invalid': _vm.$v.registrationForm.password.$dirty && _vm.$v.registrationForm.password.$error,
                        'is-valid': _vm.$v.registrationForm.password.$dirty && !_vm.$v.registrationForm.password.$error
                    },attrs:{"id":"password","type":"password","placeholder":"","required":""},model:{value:(_vm.$v.registrationForm.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.registrationForm.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.registrationForm.password.$model"}})],1),_c('b-alert',{attrs:{"show":""}},[_vm._v(" By creating an account you accept our latest "),_c('a',{attrs:{"href":"/terms-and-conditions"}},[_vm._v("Terms and Conditions")])]),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"6"}},[_c('b-button',{staticClass:"btn-block",attrs:{"variant":"success","disabled":_vm.$v.registrationForm.$invalid},on:{"click":function($event){return _vm.createAccount()}}},[_vm._v(" Register ")])],1),_c('b-col',{staticClass:"pb-2",attrs:{"lg":"6"}},[(_vm.$store.state.signup.type === 'new_user')?_c('b-button',{staticClass:"btn-block",attrs:{"variant":"light"},on:{"click":function($event){$event.preventDefault();_vm.$store.state.signup.type='existing_user'}}},[_vm._v("Login")]):_vm._e()],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }