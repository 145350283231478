<template>
  <div id="help-center-page" class="page">
    <b-overlay :show="isLoading" rounded="sm" class="content">

      <div class="sub-page-header" style="
                position: sticky;
                top: 99px;
                background: #6c757e;
                z-index: 99999999;
                padding-top: 15px;
                padding-bottom: 16px;
            ">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="top-header">
                <b-button pill :to="{'name': 'ClientProductsPage'}" variant="link">
                  <b-icon icon="arrow-left-circle" class="text-white" scale="2"></b-icon>
                </b-button>

                <h4 class="text-white">Help Center</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <a href="https://wa.me/27635941680" target="_blank">
                <b-card text-variant="light">
                  <b-card-title>
                    WhatsApp
                  </b-card-title>
                </b-card>
              </a>
            </div>
            <div class="col-md-12">
              <a href="mailto:support@buyisakonnect.co.za" target="_blank">
                <b-card text-variant="light">
                  <b-card-title>
                    Email: support@buyisakonnect.co.za
                  </b-card-title>
                </b-card>
              </a>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>

  </div>
</template>

<script>
export default {
  name: "HelpCenterPage",
  created () {
    this.$mixpanel.track_pageview({"page": "Help Center Page"});
  },
  data() {
    return {
      isLoading: false,
    }
  }
}
</script>

<style scoped>

</style>