<template>
  <div id="client-product-page" class="page">

    <b-overlay :show="isLoading" rounded="sm" class="content">
      <div class="sub-page-header" style="
                position: sticky;
                top: 99px;
                background: #6c757e;
                z-index: 99999999;
                padding-top: 15px;
                padding-bottom: 16px;
            ">

        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="top-header">
                <b-button pill :to="{'name': 'HomePage'}" variant="link">
                  <b-icon icon="arrow-left-circle" class="text-white" scale="2"></b-icon>
                </b-button>

                <h4 class="text-white">My Products</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content">

        <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-6" v-for="clientProduct in clientProducts"  :key="clientProduct.id">

                <router-link :to="{name:'SingleClientProductPage', params: {'client_product_id': clientProduct.id}}">
                  <b-card text-variant="light">
                    <small>{{ clientProduct.product.name}}</small>
                    <b-card-title  class="d-flex justify-content-between">
                      {{ clientProduct.msisdn }}

                      <div class="badge badge-danger" v-if="clientProduct.status.id == 6">Cancelled</div>
                      <div class="badge badge-success" v-if="clientProduct.status.id == 1">Active</div>
                      <div class="badge badge-warning" v-if="clientProduct.status.id == 14">Capped</div>
                      <div class="badge badge-info" v-if="clientProduct.status.id == 4">Pending</div>
                      <div class="badge badge-info" v-if="[3, 2].includes(clientProduct.status.id)">{{clientProduct.status.name}}</div>
                    </b-card-title>
                  </b-card>
                </router-link>
              </div>
            </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import buyissolutionsApiService from "@/services/buyissolutions.api.service";

export default {
  name: "ClientProductsPage",
  components: {},
  created () {
    this.$mixpanel.track_pageview({"page": "Client Product Page"});
  },
  mounted() {
    this.getClientProducts();
  },
  data() {
    return {
      isLoading: true,
      clientProducts: [],
    }
  },
  methods: {
    getClientProducts() {
      this.isLoading = true;
      buyissolutionsApiService.getClientProducts().then(response => {
        this.clientProducts = response.data.client_products;
      }).catch(({response}) => {
        this.$bvModal.msgBoxOk(response.data.message, {
          title: 'Oops something is wrong',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        });
      }).finally(() => {
        this.isLoading = false;
      });
    },
  }
}
</script>

<style scoped lang="scss">
  #client-product-page {
    margin: 20px auto;

  }
</style>