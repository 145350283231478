<template>
  <div id="home-page" class="page">

    <b-overlay :show="isLoading" rounded="sm" class="content">
      <div class="sub-page-header" style="
              position: sticky;
              top: 99px;
              background: #6c757e;
              z-index: 99999999;
              padding-top: 15px;
              padding-bottom: 16px;
          ">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="top-header">
                <h4 class="text-white">Home</h4>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="page-content">
      <div class="container">
        <div class="updates-slider d-none">
          <div class="row">
            <div class="col-sm-12">

              <b-carousel
                  id="carousel-fade"
                  style="text-shadow: 0px 0px 2px #000"
                  fade
                  indicators
                  img-width="1024"
                  img-height="480"
              >
                <b-carousel-slide
                    caption=""
                    img-src="/images/promo_1.png"
                ></b-carousel-slide>
                <b-carousel-slide
                    caption=""
                    img-src="/images/promo_1.png"
                ></b-carousel-slide>
                <b-carousel-slide
                    caption=""
                    img-src="/images/promo_1.png"
                ></b-carousel-slide>
              </b-carousel>

            </div>
          </div>
        </div>

        <div class="menu">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <router-link :to="{name: 'ClientProductsPage'}">
                <b-card text-variant="light">
                  <b-card-title>
                    My Products
                  </b-card-title>
                </b-card>
              </router-link>
            </div>
            <div class="col-sm-12 col-md-6">
              <router-link :to="{name: 'MyProfilePage'}">
                <b-card text-variant="light">
                  <b-card-title>
                    My Profile
                  </b-card-title>
                </b-card>
              </router-link>
            </div>
            <div class="col-sm-12 col-md-6">
              <router-link :to="{name: 'HelpCenterPage'}">
                <b-card text-variant="light">
                  <b-card-title>
                    Support
                  </b-card-title>
                </b-card>
              </router-link>
            </div>
            <div class="col-sm-12 col-md-6">
              <router-link :to="{name: 'UploadProofOfPaymentPage'}">
                <b-card text-variant="light">
                  <b-card-title>
                    Upload Proof Of Payment
                  </b-card-title>
                </b-card>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  created () {
    this.$mixpanel.track_pageview({"page": "Dashboard Page"});
  },
  data() {
    return {
      isLoading: false,
      clientProducts: [],
    }
  },
}
</script>

<style scoped>

</style>