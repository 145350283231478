import { render, staticRenderFns } from "./BulkSignUpProductSelection.vue?vue&type=template&id=55ae7ca4&scoped=true"
import script from "./BulkSignUpProductSelection.vue?vue&type=script&lang=js"
export * from "./BulkSignUpProductSelection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55ae7ca4",
  null
  
)

export default component.exports