<template>
  <div id="registration-component" class="py-2">
    <h3 class="mb-3">Register Your Account</h3>
      <b-overlay :show="isLoading" rounded="sm">
      <form id="registration-form">
          <b-alert v-if="errorMessage" show variant="danger">
              {{errorMessage}}
          </b-alert>
          <b-form-group
                  id="firstname-input-group"
                  label="First name"
                  label-for="firstname"
                  description=""
          >
              <b-form-input
                      :class="{
                          'is-invalid': $v.registrationForm.firstName.$dirty && $v.registrationForm.firstName.$error,
                          'is-valid': $v.registrationForm.firstName.$dirty && !$v.registrationForm.firstName.$error
                      }"
                      id="first_name"
                      v-model.trim="$v.registrationForm.firstName.$model"
                      type="text"
                      placeholder=""
                      required
              ></b-form-input>
          </b-form-group>
          <b-form-group
                  id="lastname-input-group"
                  label="Last name"
                  label-for="firstname"
                  description=""
          >
              <b-form-input
                      :class="{
                          'is-invalid': $v.registrationForm.lastName.$dirty && $v.registrationForm.lastName.$error,
                          'is-valid': $v.registrationForm.lastName.$dirty && !$v.registrationForm.lastName.$error
                      }"
                      id="last_name"
                      v-model.trim="$v.registrationForm.lastName.$model"
                      type="text"
                      placeholder=""
                      required
              ></b-form-input>
          </b-form-group>
          <b-form-group
                  id="username-input-group"
                  label="Email Address"
                  label-for="username"
                  description=""
          >
              <b-form-input
                      :class="{
                          'is-invalid': $v.registrationForm.username.$dirty && $v.registrationForm.username.$error,
                          'is-valid': $v.registrationForm.username.$dirty && !$v.registrationForm.username.$error
                      }"
                      id="username"
                      v-model.trim="$v.registrationForm.username.$model"
                      type="text"
                      placeholder=""
                      required
              ></b-form-input>
          </b-form-group>


        <b-form-group
            id="contact-input-group"
            label="Contact Number"
            label-for="contact"
            description="We use this number to send you notifications via WhatsApp and email."
        >
          <b-form-input
              :class="{
                          'is-invalid': $v.registrationForm.contact.$dirty && $v.registrationForm.contact.$error,
                          'is-valid': $v.registrationForm.contact.$dirty && !$v.registrationForm.contact.$error
                      }"
              id="contact"
              v-model.trim="$v.registrationForm.contact.$model"
              type="text"
              placeholder=""
              required
          ></b-form-input>
        </b-form-group>

          <b-form-group
                  id="password-input-group"
                  label="Password"
                  label-for="password"
                  description=""
          >
              <b-form-input
                      :class="{
                          'is-invalid': $v.registrationForm.password.$dirty && $v.registrationForm.password.$error,
                          'is-valid': $v.registrationForm.password.$dirty && !$v.registrationForm.password.$error
                      }"
                      id="password"
                      v-model.trim="$v.registrationForm.password.$model"
                      type="password"
                      placeholder=""
                      required
              ></b-form-input>
          </b-form-group>


          <b-alert show>
              By creating an account you accept our latest <a href="/terms-and-conditions">Terms and Conditions</a>
          </b-alert>

        <b-row class="mb-3">
          <b-col lg="6" class="pb-2">
            <b-button class="btn-block" variant="success" :disabled="$v.registrationForm.$invalid" @click="createAccount()">
                Register
            </b-button>
          </b-col>
          <b-col lg="6" class="pb-2">
            <b-button class="btn-block" variant="light" v-if="$store.state.signup.type === 'new_user'" @click.prevent="$store.state.signup.type='existing_user'">Login</b-button>
          </b-col>
        </b-row>
      </form>
      </b-overlay>
  </div>
</template>

<script>
import {required, email} from "vuelidate/lib/validators";
import buyissolutionsApiService from "@/services/buyissolutions.api.service";

export default {
    name: "RegistrationForm",
    created () {
      this.$mixpanel.track_pageview({"page": "Registration Page"});
    },
    data() {
      return {
          isLoading: false,
          errorMessage: '',
          registrationForm: {
              firstName: '',
              lastName: '',
              username: '',
              contact: '',
              password: '',
          }
      }
    },
    methods: {
        createAccount() {
            this.isLoading = true;

            this.$mixpanel.track('post_create_account', this.registrationForm);

            buyissolutionsApiService.createAccount(this.registrationForm).then(response => {

                if (response.status === 200) {

                    // this.$bvModal.hide('register-login-forms')

                  this.$mixpanel.register(this.registrationForm);

                    this.$bvModal.msgBoxConfirm('Hi, ' + response.data.user.first_name + ' you are most welcome, Please login to continue.', {
                        title: 'Welcome Aboard',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'YES',
                        cancelTitle: 'NO',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                        if (value) {
                            this.$store.state.signup.type = 'existing_user';
                        }
                    });
                    this.isLoading = false
                }

                console.log('>', response)
            }).catch(({response}) => {

              console.log('>', response)
                if (response.status === 409) {
                    this.errorMessage = response.data.message
                    this.isLoading = false
                }

              if (response.status === 400) {
                this.errorMessage = response.data.message
              }
            });
        }
    },
    validations: {
        registrationForm: {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            password: {
                required,
            },
            contact: {
                required,
            },
            username: {
                required,
                email
            }
        },
    }
}
</script>

<style scoped>

</style>