<template>
  <div class="client-product-list">
    <b-overlay :show="isLoading" rounded="sm" class="content">
      <b-card title="Product Information" v-if="clientProduct">
        <b-card-sub-title>{{clientProduct.friendly_name}}</b-card-sub-title>
        <p><strong>Type: </strong>{{clientProduct.product.product_type.name}}</p>
        <p><strong>Price: </strong>{{clientProduct.product.sell_price | currency('R') }}</p>
        <p><strong>Price: </strong>{{clientProduct.product.bill_occurrence.name}}</p>
        <p><strong>Bill Occurrence: </strong>{{clientProduct.status.name}}</p>
        <p><strong>Status: </strong>{{clientProduct.status.name}}</p>
        <p><strong>Sign Up Date: </strong>{{ moment()(clientProduct.created_at).format('YYYY-MM-DD') }}</p>
<!--        <pre>{{clientProduct}}</pre>-->

        <!--        <b-card-text class="small text-muted">Last updated 3 mins ago</b-card-text>-->

        <div class="d-flex flex-wrap gap-1" v-if="clientProduct">

          <b-button variant="secondary" @click="$router.go(-1)">Back</b-button>
          <b-button variant="success" v-if="clientProduct.status.id === 1" @click="topMeUp(clientProduct)">Top Up</b-button>
          <b-button variant="primary" v-if="hasPendingTransactions" @click="mayPayment(clientProduct)">
            Make Payment
          </b-button>
          <b-button :to="{name: 'UploadProofOfPayment'}" variant="secondary" v-if="clientProduct.status.id === 3">Upload Proof Of Payment</b-button>
          <b-button variant="danger" v-if="clientProduct.status.id === 1" @click="cancelProduct(clientProduct)">Cancel</b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import buyissolutionsApiService from "@/services/buyissolutions.api.service";
import moment from  "moment"


export default {
  props: ['clientProductId'],
  components: {},
  computed: {
    getClientProductId() {
      return this.$route.params.client_product_id;
    }
  },
  mounted() {
    this.getClientProduct(this.getClientProductId);

    this.getUnpaidTransactions(this.getClientProductId);

  },
  data() {
    return {
      isLoading: true,
      clientProduct: null,
      hasPendingTransactions: false,
    }
  },
  methods: {
    moment() {
      return moment
    },
    getClientProduct(clientProductId) {
      if (!clientProductId) {
        return;
      }
      this.isLoading = true
      buyissolutionsApiService.getClientProduct(clientProductId).then(response => {
        this.clientProduct = response.data.client_product;
      }).finally(() => {
        this.isLoading = false
      });
    },
    getUnpaidTransactions(clientProductId) {
      buyissolutionsApiService.getUnprocessedTransactionsByClientProduct(clientProductId)
          .then(response => {
            if (response.status === 200) {
              this.hasPendingTransactions = response.data.transactions.length > 0;
            }
          })
    },
    mayPayment(clientProduct) {
      this.isLoading = true;
      this.$bvModal.msgBoxConfirm('Please use this feature only if you where not able to pay make during sign up.', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Okay',
        cancelTitle: 'Nevermind',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(value => {
            if (value === true) {
              this.$router.push({
                'name': 'ClientProductMakePayment',
                params: {'client_product_id': clientProduct.id}
              });
              // buyissolutionsApiService.cancelClientProduct(clientProduct).then(response => {
              //     if (response.status === 200) {
              //         // this.getClientProducts();
              //
              //         this.$bvModal.msgBoxOk(response.message, {
              //             title: 'Oops',
              //             size: 'sm',
              //             buttonSize: 'sm',
              //             okVariant: 'success',
              //             headerClass: 'p-2 border-bottom-0',
              //             footerClass: 'p-2 border-top-0',
              //             centered: true
              //         });
              //     }
              // })
              //     .finally(() => {
              //         this.isLoading = false;
              //     });
            }
          })
          .catch()
          .finally(() => {
            this.isLoading = false;
          });
    },
    uploadProofOfPayment() {
    },
    cancelProduct() {
      this.isLoading = true;
      this.$bvModal.msgBoxConfirm('Are you sure you want cancel this product? ', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(value => {
            if (value === true) {
              // buyissolutionsApiService.cancelClientProduct(clientProduct).then(response => {
              //     if (response.status === 200) {
              //         this.getClientProducts();
              //
              //         this.$bvModal.msgBoxOk(response.message, {
              //             title: 'Oops',
              //             size: 'sm',
              //             buttonSize: 'sm',
              //             okVariant: 'success',
              //             headerClass: 'p-2 border-bottom-0',
              //             footerClass: 'p-2 border-top-0',
              //             centered: true
              //         });
              //     }
              // })
              // .finally(() => {
              //     this.isLoading = false;
              // });
            }
          })
          .catch()
          .finally(() => {
            this.isLoading = false;
          });
    },
    topMeUp(clientProduct) {
      this.$router.push({name: 'TopUpMobileData', params: {'client_product_id': clientProduct.id}});
    },
  }
}
</script>

<style scoped>

</style>