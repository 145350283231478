import axios from "axios";
import authTokenHelper from "@/helpers/authToken.helper";

// axios.interceptors.request.use((config) => {
//     const token = localStorage.getItem('token');
//     console.log('00000')
//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
// }, error => {
//     return Promise.reject(error)
// });

const BuyisaSolutionApi = axios.create({
    baseURL: process.env.VUE_APP_BUYISA_SOLUTIONS_API_URL,
    timeout: 300000,
});

// BuyisaSolutionApi.interceptors.request.use( config => {
//     // add authorization header to request config
//
//
//     return config;
// });

// BuyisaSolutionApi.interceptors.request.use((config) => {
//     const token = localStorage.getItem('token');
//     console.log('00000')
//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
// }, error => {
//     return Promise.reject(error)
// });

// BuyisaSolutionApi.interceptors.response.use((config) => {
//     console.log('config ', config);
//     if (config.status  === 401) {
//         console.log('interceptors: Mmmh seems your session has expired. Please login again to continue.');
//     }
//     return config;
// }, error => {
//     console.log(error)
//     console.log('its all just a mastery')
//     if (error.response.status === 401) {
//         console.log('interceptors: Mmmh seems your session has expired. Please login again to continue.');
//     }
//     // console.log('config ', error);
//     return Promise.reject(error)
// });

async function getHeaders(auth = false, fileUpload = false) {
    let headers = {
        "headers": {
            "Accept": "*/*"
        }
    };

    if (auth) {
        const token = await authTokenHelper.getToken().then(token => {
            return token;
        });
        if (token) {
            headers['headers']['Authorization'] = 'Bearer ' + token;
        }
    } else {
        console.log('no token needed for this path');
    }

    if (fileUpload) {
        headers['headers']['Content-Type'] = 'multipart/form-data';
    } else {
        headers['headers']['Content-Type'] = 'application/json';
    }

    return headers;
}

async function getProducts(productTypeSlug) {
    return BuyisaSolutionApi.get("/public/products?product_type_slug=" + productTypeSlug, await getHeaders()).then(response => {
        return response;
    }).catch(error => {
        console.log('getProducts error: ', error.response.data.message)
    });
}

async function getProductById(ProductId) {
    return BuyisaSolutionApi.get("/public/product/" + ProductId, await getHeaders()).then(response => {
        return response;
    }).catch(error => {
        alert(error.response.data.message)
    });
}

async function loginToAccount(username, password) {
    const data = {
        'username': username,
        'password': password,
    }

    return BuyisaSolutionApi.post("/login", data, await getHeaders());
}

async function checkMsisdnAvailability(msisdn = null) {
    if (msisdn == null) {
        return;
    }
    return BuyisaSolutionApi.get("/public/mobile/check-availability/" + msisdn, await getHeaders()).then(response => {
        return response;
    });
}

async function getLoggedInUser() {
    const headers = await getHeaders(true);
    return BuyisaSolutionApi.get("/user", headers);
}

async function postResetPassword(email) {
    return BuyisaSolutionApi.post("/public/user/password-reset", {'email': email}, await getHeaders());
}

async function getResetPassword(uid) {
    return BuyisaSolutionApi.get("/public/user/password-reset/" + uid, await getHeaders());
}

async function postUpdatePassword(newPassword, uid) {
    return BuyisaSolutionApi.post("/public/user/update-password", {'password': newPassword, 'uid': uid}, await getHeaders());
}

async function cartResync(cart) {
    const data = {
        'cart': cart
    }
    return BuyisaSolutionApi.post('/public/cart/sync', data, await getHeaders());
}

async function validateCoupon(couponCode, cart) {
    return BuyisaSolutionApi.post('/coupon', {'coupon' : couponCode, 'cart': cart}, await getHeaders(true));
}

async function getOrderByReference(orderReference) {
    return BuyisaSolutionApi.get('/order/' + orderReference, await getHeaders(true));
}

async function checkout(cart) {
    return BuyisaSolutionApi.post('/cart', cart, await getHeaders(true));
}

async function updateProfile(profile) {
    return BuyisaSolutionApi.put('/client', profile, await getHeaders(true));
}

async function getClientProducts() {
    return BuyisaSolutionApi.get('/client-products', await getHeaders(true));
}

async function getClientProductsMobileDataLinks(clientProductId) {
    return BuyisaSolutionApi.get('/client-product/' + clientProductId + '/data-links', await getHeaders(true));
}

async function getClientProduct(clientProductId) {
    return BuyisaSolutionApi.get('/client-product/' + clientProductId, await getHeaders(true));
}

async function getClientProductTopUpOptions(clientProductId) {
    return BuyisaSolutionApi.get('/client-product/' + clientProductId + '/top-up-options', await getHeaders(true));
}

async function initialTopUp(clientProductId, topUpProductId) {
    const data = {
        'top_up_product_id': topUpProductId
    };
    return BuyisaSolutionApi.post('/client-product/' + clientProductId + '/top-up', data, await getHeaders(true));
}

async function getTransactionsByClientProduct(clientProductId) {
    return BuyisaSolutionApi.get('/client-product/' + clientProductId + '/transactions', await getHeaders(true));
}

async function getUnprocessedTransactionsByClientProduct(clientProductId) {
    return BuyisaSolutionApi.get('/client-product/' + clientProductId + '/unprocessed-transactions', await getHeaders(true));
}

async function getUnprocessedTransactionsByUser() {
    return BuyisaSolutionApi.get('/client/unprocessed-transactions', await getHeaders(true));
}

/**
 * A function to kick off client product pay now action
 * @param clientProductId
 * @param transactionRef
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
async function createClientProductPayNowAction(clientProductId, transactionRef) {
    const data = {
        'client_product_id': clientProductId,
        'transaction_ref':  transactionRef
    };
    return BuyisaSolutionApi.post('/client-product/' + clientProductId + '/make-payment', data, await getHeaders(true));
}

async function cancelClientProduct(clientProduct) {
    return BuyisaSolutionApi.delete('/client-products/' + clientProduct.id + '/cancel', await getHeaders(true));
}

async function createAccount(data) {
    const userData = {
        'first_name': data.firstName,
        'last_name': data.lastName,
        'email': data.username,
        'contact_number': data.contact,
        'plain_password': data.password,
    }
    return BuyisaSolutionApi.post('/public/client/register', userData, await getHeaders());
}

async function uploadProofOfPayment(formData) {
    return BuyisaSolutionApi.post('/payment/upload-proof-of-payment', formData, await getHeaders(true, true));
}

async function initiateBulkSignUp(formData) {
    return BuyisaSolutionApi.post('/bulk-signup', formData, await getHeaders(true, true));
}

export default {
    loginToAccount: loginToAccount,
    getProducts: getProducts,
    getProductById: getProductById,
    checkMsisdnAvailability: checkMsisdnAvailability,

    getLoggedInUser: getLoggedInUser,
    postResetPassword: postResetPassword,
    getResetPassword: getResetPassword,
    postUpdatePassword: postUpdatePassword,

    cartResync: cartResync,
    checkout: checkout,
    validateCoupon: validateCoupon,
    getOrderByReference: getOrderByReference,

    updateProfile: updateProfile,
    createAccount: createAccount,

    getClientProducts: getClientProducts,
    getClientProduct: getClientProduct,
    getClientProductsMobileDataLinks: getClientProductsMobileDataLinks,
    getClientProductTopUpOptions: getClientProductTopUpOptions,
    initialTopUp: initialTopUp,
    uploadProofOfPayment:uploadProofOfPayment,
    getTransactionsByClientProduct: getTransactionsByClientProduct,
    getUnprocessedTransactionsByClientProduct: getUnprocessedTransactionsByClientProduct,
    getUnprocessedTransactionsByUser: getUnprocessedTransactionsByUser,
    createClientProductPayNowAction: createClientProductPayNowAction,
    cancelClientProduct: cancelClientProduct,
    initiateBulkSignUp: initiateBulkSignUp
};
