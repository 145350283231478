<template>
  <div class="client-product-data-links">
    <b-overlay :show="isLoading" rounded="sm" class="content">
      <b-card  title="Data Balances" sub-title="Balance Data Usages">

        <b-table small striped hover responsive :items="dataLinks">

        </b-table>

        <!--        <b-card-text class="small text-muted">Last updated 3 mins ago</b-card-text>-->
      </b-card>
    </b-overlay>
  </div>
</template >

<script>

import buyissolutionsApiService from "@/services/buyissolutions.api.service";
import dataConvertorHelper from "@/helpers/dataConvertorHelper"

export default {
  name: "ClientProductDataLinks",
  props: ['clientProductId'],
  mounted() {
    this.getClientProductDataLinks(this.getClientProductId);
  },
  computed: {
    getClientProductId() {
      return this.clientProductId;
    }
  },
  data() {
    return {
      isLoading: true,
      dataLinks: [],
    }
  },
  methods: {
    getClientProductDataLinks(clientProductId) {
      this.isLoading = true;
      buyissolutionsApiService.getClientProductsMobileDataLinks(clientProductId).then(response => {
        this.dataLinks = [];

        response.data.data_links.forEach(value => {
          this.dataLinks.push({'id': value.id, 'type': value.detail_type.name, 'allocated_data': dataConvertorHelper.bytesToGB(value.allocated_data), 'used_data': dataConvertorHelper.bytesToGB(value.used_data), 'created_at':value.created_at, 'last_check': value.updated_at})
        })
      }).catch(() => {
        // this.$bvModal.msgBoxOk(response.data.message, {
        //   title: 'Oops something is wrong',
        //   size: 'sm',
        //   buttonSize: 'sm',
        //   okVariant: 'success',
        //   headerClass: 'p-2 border-bottom-0',
        //   footerClass: 'p-2 border-top-0',
        //   centered: true
        // });
      }).finally(() => {
        this.isLoading = false;
      });
    },
  }
}

</script>

<style scoped>

</style>