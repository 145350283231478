<template>
  <div id="">
    <div><small><strong>Usage:</strong></small></div>
    <div id="data-usage">
      <div class="usage-info">
        <div class="usage-info-bar" :style="{'width': used_percentage}"></div>
      </div>
      <small>{{used}} used of {{allocated}}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataUsageInformationCard",
  props: [
    'allocated',
    'used',
    'used_percentage',
  ]
}
</script>

<style scoped lang="scss">
#data-usage {
  .usage-info {
    height: 15px;
    width: 100%;
    border: 1px solid rgba(253,207,24,1);
    border-radius: 10px;
    overflow: hidden;

    .usage-info-bar {
      height: 15px;
      //width: 10%;
      background: linear-gradient(0deg, rgba(247,148,29,1) 0%, rgba(253,207,24,1) 93%);
    }
  }
}
</style>